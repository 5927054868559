import { createSlice } from '@reduxjs/toolkit';
import { PreviousExperiencesState } from './prevExperience.type';

const initialState: PreviousExperiencesState = {
  prevExperiences: [],
  rol: '',
  company: '',
  type: '',
  industry: '',
  description: '',
  seniority: '',
  startDate: null,
  finishDate: null,
  showForm: false,
  showAddBtn: true,
  message: '',
  showSnackbar: false,
  snackBarError: false,
  entityIsLoading: false,
  entitiesAreFetching: false,
  deleteModal: false,
};

export default createSlice({
  name: 'previousExperience',
  initialState,
  reducers: {
    getPrevExperiences: (state, { payload }) => {
      return {
        ...state,
        prevExperieces: payload,
      };
    },
    deletePrevExperiences: (state, { payload }) => {
      return {
        ...state,
        prevExperiece: payload,
      };
    },
    setRol: (state, { payload }) => {
      return {
        ...state,
        rol: payload,
      };
    },
    setCompany: (state, { payload }) => {
      return {
        ...state,
        company: payload,
      };
    },
    setIndustry: (state, { payload }) => {
      return {
        ...state,
        industry: payload,
      };
    },
    setType: (state, { payload }) => {
      return {
        ...state,
        type: payload,
      };
    },
    setDescription: (state, { payload }) => {
      return {
        ...state,
        description: payload,
      };
    },
    setSeniority: (state, { payload }) => {
      return {
        ...state,
        seniority: payload,
      };
    },
    setStartDate: (state, { payload }) => {
      return {
        ...state,
        startDate: payload,
      };
    },
    setFinishDate: (state, { payload }) => {
      return {
        ...state,
        finishDate: payload,
      };
    },
    setMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setShowSnackBar: (state, { payload }) => ({
      ...state,
      showSnackbar: payload,
    }),
    setSnackBarError: (state, { payload }) => {
      return {
        ...state,
        snackBarError: payload,
      };
    },
    setShowForm: (state, { payload }) => {
      return {
        ...state,
        showForm: payload,
      };
    },
    setShowAddBtn: (state, { payload }) => {
      return {
        ...state,
        showAddBtn: payload,
      };
    },
    setDeleteModal: (state, { payload }) => {
      return {
        ...state,
        deleteModal: payload,
      };
    },
    setPrevExperience: (state, { payload }) => {
      return {
        ...state,
        prevExperience: payload,
      };
    },
    setPrevExperienceToEdit: (state, { payload }) => {
      return {
        ...state,
        prevExperienceToEdit: payload,
      };
    },

    clearInputs: (state) => ({
      ...state,
      rol: '',
      company: '',
      type: '',
      seniority: '',
      industry: '',
      startDate: null,
      finishDate: null,
      description: '',
    }),
    setLoading: (state, { payload }) => ({
      ...state,
      entityIsLoading: payload,
    }),
  },
});
