import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import SearchBar from './components/searchBar/SearchBar';
import './Collaborators.scss';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../../theme/index';
import KnowledgeGrid from './components/KnowledgeGrid/KnowledgeGrid';
import {
  getCollaborators,
  setCollaboratorFullname,
  setPosition,
  setArea,
  resetSkillsSelected,
  resetAreaSelected,
  resetPositionSelected,
  resetEnglishLevelsSelected,
  setEnglishLevelsSelected,
  setConfirmFilter,
  setFiltersActive,
  getSeniorities,
  getAreas,
  getTeams,
  getConditions,
  getRoles,
} from '../../../store/Collaborators/collaborators.actions';
import { RootState } from '../../../store';
import KnowledgeBackdrop from '../../../commons/KnowledgeBackdrop/KnowledgeBackdrop';
import {
  areaTypeLabels,
  Collaborator,
  Collaborator1,
  jobTypeLabels,
} from '../../../store/Collaborators/collaborators.type';
import { useSEO } from '../../../hooks/useSeo';
import KnowledgeContainer from '../../../commons/KnowledgeContainer/KnowledgeContainer';
import KnowledgePagination from '../../../commons/KnowledgePagination/KnowledgePagination';
import {
  setCurrentPath,
  setDefaultItems,
  setPathsToShow,
} from '../../../store/routes/routes.actions';
import defaultItems from '../../../utils/defaultItems';
import { useLocation } from 'react-router-dom';
import {
  INITIAL_COLLABORATORS_PAGE,
  INITIAL_COLLABORATORS_COUNT,
} from '../../../helpers/constants';
import { getEnglishLevels } from '../../../store/EnglishLevels/englishLevels.actions';
import { getAllSkills } from '../../../store/Skills/skills.actions';
import KnowledgeAutocomplete from '../../../commons/KnowledgeAutocomplete/KnowledgeAutocomplete';

interface LinkDispatchProps {
  getCollaborators: (filters: any[], page?: number, count?: number) => void;
  setCollaboratorFullname: (name: string) => void;
  setPosition: (position: { label: string; type: string }) => void;
  setArea: (area: { label: string; type: string }) => void;
  getAllSkills: () => void;
  setCurrentPath: (path: string) => void;
  setDefaultItems: (items: any[]) => void;
  setPathsToShow: (location: any, defItems?: any[]) => void;
  setEnglishLevelsSelected: (skillsSelected: string[]) => void;
  resetSkillsSelected: () => void;
  resetAreaSelected: () => void;
  resetPositionSelected: () => void;
  resetEnglishLevelsSelected: () => void;
  setConfirmFilter: (confirmFilter: boolean) => void;
  setFiltersActive: (filtersActive: boolean) => void;
  getEnglishLevels: () => void;
  getSeniorities: () => void;
  getAreas: () => void;
  getTeams: () => void;
  getConditions: () => void;
  getRoles: () => void;
}

interface LinkStateProps {
  collaborators1: Collaborator1[];
  filters: any[];
  hasError: boolean;
  isFetching: boolean;
  errorMessage: string;
  englishLevelsSelected: string[];
  collaboratorFullnameSelected?: string;
  area?: { label: string; type: string };
  position?: { label: string; type: string };
  skills: any[];
  skillsSelected: any[];
  page: number;
  pageCount: number;
  confirmFilter: boolean;
  filtersActive: boolean;
  appTranslation?: any;
}

type CollaboratorsPageProps = LinkDispatchProps & LinkStateProps;

const Collaborators: FC<CollaboratorsPageProps> = (props: CollaboratorsPageProps) => {
  useSEO(
    '',
    props.appTranslation
      ? props.appTranslation.pages?.COLLABORATORS.collaboratorsGrid.windowTitle
      : 'Knowledge Catalog | Colaboradores'
  );

  const location: any = useLocation();

  useEffect(() => {
    let currentPathAux: any = location.pathname.split('/')[1];
    props.setCurrentPath(currentPathAux);
    props.setDefaultItems(
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.setPathsToShow(
      location,
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.getCollaborators([], 1, 9);
    props.getAllSkills();
    props.setConfirmFilter(false);
    props.setFiltersActive(false);
    props.getEnglishLevels();
    props.getSeniorities();
    props.getAreas();
    props.getTeams();
    props.getConditions();
    props.getRoles();
  }, []);

  //UPDATE MENU ITEMS WHEN CHANGING APP IDIOM
  useEffect(() => {
    let currentPathAux: any = location.pathname.split('/')[1];
    props.setCurrentPath(currentPathAux);
    props.setDefaultItems(
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.setPathsToShow(
      location,
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
  }, [props.appTranslation]);

  return (
    <KnowledgeContainer>
      <div className="knowledgeContainer__collaboratorsGrid--container">
        <div className="knowledgeContainer__collaboratorsGrid--content">
          <KnowledgeBackdrop loading={props.isFetching} />
          {/* <SearchBar
            getSkills={props.getAllSkills}
            resetProps={props.resetSkillsSelected}
            skills={props.skills}
            setPosition={props.setPosition}
            setArea={props.setArea}
            area={props.area}
            position={props.position}
            positions={jobTypeLabels}
            areas={areaTypeLabels}
            getCollaborators={props.getCollaborators}
            collaborators={props.collaborators}
            setCollaboratorFullname={props.setCollaboratorFullname}
            collaboratorFullnameSelected={props.collaboratorFullnameSelected}
            filters={props.filters && props.filters.length > 0 ? props.filters : []}
            skillsSelected={props.skillsSelected}
            resetSkillsSelected={props.resetSkillsSelected}
            resetAreaSelected={props.resetAreaSelected}
            englishLevelsSelected={props.englishLevelsSelected}
            setEnglishLevelsSelected={props.setEnglishLevelsSelected}
            resetEnglishLevelsSelected={props.resetEnglishLevelsSelected}
            resetPositionSelected={props.resetPositionSelected}
            initialCollaboratorsPage={INITIAL_COLLABORATORS_PAGE}
            initialCollaboratorsCount={INITIAL_COLLABORATORS_COUNT}
            confirmFilter={props.confirmFilter}
            setConfirmFilter={props.setConfirmFilter}
            filtersActive={props.filtersActive}
            setFiltersActive={props.setFiltersActive}
            appTranslation={
              props.appTranslation &&
              props.appTranslation.pages?.COLLABORATORS.collaboratorsGrid
            }
          /> */}

          <div style={{ width: '280px', marginTop: '15px' }}>
            <KnowledgeAutocomplete
              items={props.collaborators1.map((collaborator) => collaborator.firstName)}
              label={'Collaborator'}
              filters={[]}
              filterColumnName={'firstName'}
              selectedValue={props.collaboratorFullnameSelected}
              initialPage={INITIAL_COLLABORATORS_PAGE}
              initialCount={INITIAL_COLLABORATORS_COUNT}
              setSelectedValue={props.setCollaboratorFullname}
              getFn={props.getCollaborators}
              filtersActive={props.filtersActive}
              setFiltersActive={props.setFiltersActive}
            />
          </div>
          <ThemeProvider theme={theme}>
            {
              <>
                <KnowledgeGrid
                  collaborators={props.collaborators1}
                  filtersActive={props.filtersActive}
                  emptyList={
                    props.appTranslation
                      ? props.appTranslation.pages?.COLLABORATORS.collaboratorsGrid
                          .noMessage.withoutCollaborators + '.'
                      : 'Todavía no se ha agregado ningún colaborador.'
                  }
                />
                <div
                  style={{
                    marginTop: 20,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  {!!props.collaborators1.length && (
                    <KnowledgePagination
                      getFn={props.getCollaborators}
                      filters={props.filters}
                      getPage={props.page}
                      getPageCount={props.pageCount}
                      getCount={INITIAL_COLLABORATORS_COUNT}
                    />
                  )}
                </div>
              </>
            }
          </ThemeProvider>
        </div>
      </div>
    </KnowledgeContainer>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const {
    collaborators1,
    hasError,
    entityIsLoading,
    errorMessage,
    filters,
    collaboratorFullnameSelected,
    areaSelected,
    positionSelected,
    skills,
    skillsSelected,
    englishLevelsSelected,
    page,
    pageCount,
    confirmFilter,
    filtersActive,
  } = state.collaborators;
  const { appTranslation } = state.translations;
  return {
    collaborators1,
    hasError,
    isFetching: entityIsLoading,
    errorMessage,
    filters: filters?.length ? filters : [],
    collaboratorFullnameSelected,
    area: areaSelected,
    englishLevelsSelected,
    position: positionSelected,
    skills,
    skillsSelected: skillsSelected ? skillsSelected : [],
    page: page ? page : 1,
    pageCount,
    confirmFilter,
    filtersActive,
    appTranslation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  getCollaborators: bindActionCreators(getCollaborators, dispatch),
  setCollaboratorFullname: bindActionCreators(setCollaboratorFullname, dispatch),
  setPosition: bindActionCreators(setPosition, dispatch),
  setArea: bindActionCreators(setArea, dispatch),
  setEnglishLevelsSelected: bindActionCreators(setEnglishLevelsSelected, dispatch),
  getAllSkills: bindActionCreators(getAllSkills, dispatch),
  resetSkillsSelected: bindActionCreators(resetSkillsSelected, dispatch),
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setDefaultItems: bindActionCreators(setDefaultItems, dispatch),
  setPathsToShow: bindActionCreators(setPathsToShow, dispatch),
  resetAreaSelected: bindActionCreators(resetAreaSelected, dispatch),
  resetPositionSelected: bindActionCreators(resetPositionSelected, dispatch),
  resetEnglishLevelsSelected: bindActionCreators(resetEnglishLevelsSelected, dispatch),
  setConfirmFilter: bindActionCreators(setConfirmFilter, dispatch),
  setFiltersActive: bindActionCreators(setFiltersActive, dispatch),
  getEnglishLevels: bindActionCreators(getEnglishLevels, dispatch),
  getSeniorities: bindActionCreators(getSeniorities, dispatch),
  getAreas: bindActionCreators(getAreas, dispatch),
  getTeams: bindActionCreators(getTeams, dispatch),
  getConditions: bindActionCreators(getConditions, dispatch),
  getRoles: bindActionCreators(getRoles, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Collaborators);
