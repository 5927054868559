import { createSlice } from '@reduxjs/toolkit';
import { CollaboratorState } from './collaborators.type';

const initialState: CollaboratorState = {
  collaborators: [],
  collaborators1: [],
  seniorities: [],
  areas: [],
  teams: [],
  conditions: [],
  roles: [],
  showEditLvlModal: false,

  //TODO CHECK WHAT IS NOT USED ANYMORE
  hasError: false,
  entityIsLoading: false,
  errorMessage: 'string',
  skills: [],
  pageCount: 1,
  techSkillsCollab: [],
  softSkillsCollab: [],
  awsSkillsCollab: [],
  message: '',
  showSnackbar: false,
  snackBarError: false,
  fullname: '',
  dateOfBirth: '',
  country: '',
  city: '',
  state: '',
  address: '',
  phone: '',
  email: '',
  job: '',
  area: '',
  seniority: '',
  showEditCollabForm: false,
  showAddBtn: true,
  showEnglishLevelForm: false,
  showEnglishLevelAddBtn: true,
  deleteModalEnglishLevel: false,
  showHistoryModal: false,
  openDeleteModal: false,
  englishLevelsSelected: [],
  confirmFilter: false,
  filtersActive: false,
  curriculums: [],
  loading: false,
};

export default createSlice({
  name: 'collaborators',
  initialState,
  reducers: {
    getAllCollaborators: (state: any, { payload }: any) => {
      const { data, filters, page, count, pageCount } = payload;
      return {
        ...state,
        collaborators1: data,
        // filters: filters.filters,
        page: page,
        pageCount: pageCount,
        count: count,
      };
    },
    setShowEditLvlModal: (state, { payload }) => {
      return {
        ...state,
        showEditLvlModal: payload,
      };
    },

    //TODO REVIEW OLD REDUCERS
    getCollaborators: (state: any, { payload }: any) => {
      const { data, filters, page, count, pageCount } = payload;
      return {
        ...state,
        collaborators: data,
        filters: filters.filters,
        page: page,
        pageCount: pageCount,
        count: count,
      };
    },

    requestCollaborators: (state: any, { payload }: any) => {
      return {
        ...state,
        entityIsLoading: payload,
      };
    },
    setCollaborator: (state: any, { payload }: any) => {
      return {
        ...state,
        collaborator1: payload,
      };
    },
    setSeniorities: (state: any, { payload }: any) => {
      return {
        ...state,
        seniorities: payload,
      };
    },
    setAreas: (state: any, { payload }: any) => {
      return {
        ...state,
        areas: payload,
      };
    },
    setTeams: (state: any, { payload }: any) => {
      return {
        ...state,
        teams: payload,
      };
    },
    setConditions: (state: any, { payload }: any) => {
      return {
        ...state,
        conditions: payload,
      };
    },
    setRoles: (state: any, { payload }: any) => {
      return {
        ...state,
        roles: payload,
      };
    },

    //TODO REVIEW OLD METHODS
    setCollaboratorError: (state: any, { payload }: any) => {
      return {
        ...state,
        entityIsLoading: false,
        hasError: true,
        errorMessage: payload.errorMessage,
      };
    },

    setCollaboratorFullname: (state: any, { payload }: any) => {
      return {
        ...state,
        collaboratorFullnameSelected: payload,
      };
    },
    setArea: (state: any, { payload }: any) => {
      return {
        ...state,
        areaSelected: payload,
      };
    },
    setPosition: (state: any, { payload }: any) => {
      return {
        ...state,
        positionSelected: payload,
      };
    },

    setSkills: (state: any, { payload }: any) => {
      return {
        ...state,
        skills: payload,
      };
    },
    setSkillsSelected: (state: any, { payload }: any) => {
      return {
        ...state,
        skillsSelected: payload,
      };
    },
    setTechSkillsCollab: (state: any, { payload }: any) => {
      return {
        ...state,
        techSkillsCollab: payload,
      };
    },
    setSoftSkillsCollab: (state: any, { payload }: any) => {
      return {
        ...state,
        softSkillsCollab: payload,
      };
    },
    setAwsSkillsCollab: (state: any, { payload }: any) => {
      return {
        ...state,
        awsSkillsCollab: payload,
      };
    },
    setToolsCollab: (state: any, { payload }: any) => {
      return {
        ...state,
        toolsCollab: payload,
      };
    },
    resetSkillsSelected: (state: any) => {
      return {
        ...state,
        skillsSelected: [],
      };
    },
    setEnglishLevelsSelected: (state: any, { payload }: any) => {
      return {
        ...state,
        englishLevelsSelected: payload,
      };
    },
    resetEnglishLevelsSelected: (state: any) => {
      return {
        ...state,
        englishLevelsSelected: [],
      };
    },
    resetAreaSelected: (state: any) => {
      return {
        ...state,
        areaSelected: undefined,
      };
    },
    resetPositionSelected: (state: any) => {
      return {
        ...state,
        positionSelected: undefined,
      };
    },
    setMessage: (state: any, { payload }: any) => {
      return {
        ...state,
        message: payload,
      };
    },
    setShowSnackBar: (state: any, { payload }: any) => ({
      ...state,
      showSnackbar: payload,
    }),
    setSnackBarError: (state: any, { payload }: any) => {
      return {
        ...state,
        snackBarError: payload,
      };
    },

    clearSkillsAutocompletes: (state: any) => {
      return {
        ...state,
        techSkillsCollab: [],
        softSkillsCollab: [],
        awsSkillsCollab: [],
      };
    },
    setFullname: (state: any, { payload }: any) => ({
      ...state,
      fullname: payload,
    }),
    setDateOfBirth: (state: any, { payload }: any) => ({
      ...state,
      dateOfBirth: payload,
    }),
    setCountry: (state: any, { payload }: any) => ({
      ...state,
      country: payload,
    }),
    setState: (state: any, { payload }: any) => ({
      ...state,
      state: payload,
    }),
    setCity: (state: any, { payload }: any) => ({
      ...state,
      city: payload,
    }),
    setAddress: (state: any, { payload }: any) => ({
      ...state,
      address: payload,
    }),
    setPhone: (state: any, { payload }: any) => ({
      ...state,
      phone: payload,
    }),
    setEmail: (state: any, { payload }: any) => ({
      ...state,
      email: payload,
    }),
    setCollabPosition: (state: any, { payload }: any) => ({
      ...state,
      job: payload,
    }),
    setCollabArea: (state: any, { payload }: any) => ({
      ...state,
      area: payload,
    }),
    setSeniority: (state: any, { payload }: any) => ({
      ...state,
      seniority: payload,
    }),
    setEnglishLevel: (state: any, { payload }: any) => ({
      ...state,
      englishLevel: payload,
    }),
    setShowEditCollabForm: (state: any, { payload }: any) => ({
      ...state,
      showEditCollabForm: payload,
    }),
    setShowAddBtn: (state: any, { payload }: any) => ({
      ...state,
      showAddBtn: payload,
    }),
    setShowEnglishLevelForm: (state: any, { payload }: any) => ({
      ...state,
      showEnglishLevelForm: payload,
    }),
    setShowEnglishLevelAddBtn: (state: any, { payload }: any) => ({
      ...state,
      showEnglishLevelAddBtn: payload,
    }),
    clearInputs: (state: any) => ({
      ...state,
      fullname: '',
      dateOfBirth: null,
      country: '',
      state: '',
      city: '',
      address: '',
      phone: '',
      email: '',
      job: '',
      area: '',
      seniority: '',
    }),
    setDeleteModalEnglishLevel: (state: any, { payload }: any) => {
      return {
        ...state,
        deleteModalEnglishLevel: payload,
      };
    },
    setShowHistoryModal: (state: any, { payload }: any) => {
      return {
        ...state,
        showHistoryModal: payload,
      };
    },
    setOpenDeleteModal: (state: any, { payload }: any) => {
      return {
        ...state,
        openDeleteModal: payload,
      };
    },
    setConfirmFilter: (state: any, { payload }: any) => {
      return {
        ...state,
        confirmFilter: payload,
      };
    },
    setFiltersActive: (state: any, { payload }: any) => {
      return {
        ...state,
        filtersActive: payload,
      };
    },
    setCollaboratorCurriculums: (state: any, { payload }: any) => {
      return {
        ...state,
        curriculums: payload,
      };
    },
    clearCollaboratorCurriculums: (state: any) => {
      return {
        ...state,
        curriculums: [],
      };
    },
    setLoading: (state: any, { payload }: any) => {
      return {
        ...state,
        loading: payload,
      };
    },
  },
});
