import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from './auth.type';

const initialState: AuthState = {
  showSnackBar: false,
  loading: false,
  authorizedUsers: [],
};

export default createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveSession: (state, { payload }) => ({
      ...state,
      user: payload,
    }),
    setMessage: (state, { payload }) => ({
      ...state,
      message: payload,
    }),
    setShowSnackBar: (state, { payload }) => ({
      ...state,
      showSnackBar: payload,
    }),
    setLoading: (state, { payload }) => ({
      ...state,
      loading: payload,
    }),
    getAuthorizedUsers: (state, { payload }) => ({
      ...state,
      authorizedUsers: payload,
    }),
  },
});
