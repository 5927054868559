import { createSlice } from '@reduxjs/toolkit';
import { GeneratePDFState } from './generatePDF.type';

const initialState: GeneratePDFState = {
  generatePDFs: [],
  showGeneratePdfPage: false,
  dinoExperiencesCv: [],
  generatePDF: undefined,
  message: '',
  showSnackBar: false,
  entityIsLoading: false,
  dinoExperiencesToShow: [],
  certificationsCv: [],
  certificationsToShow: [],
  skillsCv: [],
  skillsToShow: [],
  englishLevelCv: undefined,
  prevExperiencesCv: [],
  prevExperiencesToShow: [],
  toolsCv: [],
  toolsToShow: [],
  cvLanguage: '',
};

export default createSlice({
  name: 'generatePDF',
  initialState,
  reducers: {
    setShowGeneratePdfPage: (state, { payload }) => ({
      ...state,
      showGeneratePdfPage: payload,
    }),
    setDinoExperiencesCV: (state, { payload }) => {
      return {
        ...state,
        dinoExperiencesCv: payload,
      };
    },
    setDinoExperiencesToShow: (state, { payload }) => {
      return {
        ...state,
        dinoExperiencesToShow: payload,
      };
    },
    setLoading: (state, { payload }) => ({
      ...state,
      entityIsLoading: payload,
    }),
    requestInfo: (state, { payload }) => {
      return {
        ...state,
        entityIsLoading: payload,
      };
    },
    setCertificationsCV: (state, { payload }) => ({
      ...state,
      certificationsCv: payload,
    }),
    clearCV: (state) => ({
      ...state,
      dinoExperiencesCv: [],
      certificationsCv: [],
      certificationsToShow: [],
      skillsCv: [],
      skillsToShow: [],
      englishLevelCv: undefined,
      prevExperiencesCv: [],
      prevExperiencesToShow: [],
      toolsCv: [],
      toolsToShow: [],
    }),
    setMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setShowSnackBar: (state, { payload }) => ({
      ...state,
      showSnackBar: payload,
    }),
    setSkillsCV: (state, { payload }) => ({
      ...state,
      skillsCv: payload,
    }),
    setSkillsToShow: (state, { payload }) => ({
      ...state,
      skillsToShow: payload,
    }),
    setCertificationsToShow: (state, { payload }) => {
      return {
        ...state,
        certificationsToShow: payload,
      };
    },
    setEnglishLevelCV: (state, { payload }) => ({
      ...state,
      englishLevelCv: payload,
    }),
    setCvLanguage: (state, { payload }) => ({
      ...state,
      cvLanguage: payload,
    }),
    setPrevExperiencesToShow: (state, { payload }) => {
      return {
        ...state,
        prevExperiencesToShow: payload,
      };
    },
    setPrevExperiencesCV: (state, { payload }) => ({
      ...state,
      prevExperiencesCv: payload,
    }),
    setToolsCV: (state, { payload }) => ({
      ...state,
      toolsCv: payload,
    }),
    setToolsToShow: (state, { payload }) => ({
      ...state,
      toolsToShow: payload,
    }),
    setResumeS3Link: (state, { payload }) => ({
      ...state,
      resumeS3Link: payload,
    }),
    clearResumeS3Link: (state) => ({
      ...state,
      resumeS3Link: undefined,
    }),
  },
});
