import {
  CollabSkillHistory,
  CollabTopSkill,
} from '../store/Collaborators/collaborators.type';
import { Skill1 } from '../store/Skills/skills.type';

export const type = (skill: CollabSkillHistory, skills: Skill1[]) => {
  let skillToShow: Skill1 = skills.filter((s) => s.id === skill.skillId)[0];

  if (skill.topSkillHistory.length > 0) {
    let orderedTopSkill: CollabTopSkill[] = [...skill.topSkillHistory].sort(function (
      a,
      b
    ) {
      return new Date(b.endDate!).getTime() - new Date(a.endDate!).getTime();
    });
    const nowDate = new Date();
    const startDate = new Date(orderedTopSkill[0].startDate);
    const endDate = new Date(orderedTopSkill[0].endDate!);
    const comparison = nowDate <= endDate && nowDate >= startDate;

    if (comparison) {
      const type = orderedTopSkill[0].isCoreSkill ? 'CORE' : 'TOP';
      return type;
    }
  } else {
    if (skillToShow.skillKindId === 6) {
      return 'SOFT';
    } else {
      return 'OTHER';
    }
  }
};
