import { getDefaultHeaders } from './utils.service';

class ResumeLinksService {
  async findResumeByCollaboratorId(collaboratorId: number): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/resumes/collaboratorId/${collaboratorId}`,
      {
        method: 'GET',
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  async deleteResumeHistoryById(id: number): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/resumes/${id}`, {
      method: 'DELETE',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }
}

const resumeLinksService = new ResumeLinksService();
export default resumeLinksService;
