import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import KnowledgeButton from '../KnowledgeButton/KnowledgeButton';
import { SkillType } from '../../store/Collaborators/collaborators.type';

interface KnowledgeDeleteModalProps {
  open: boolean;
  handleOpen: (open: boolean) => void;
  infoToDelete?: any;
  deleteInfo: (intoToDelete: any, filters: any[], page?: number, count?: number) => void;
  title: string;
  description: string;
  skillType?: SkillType;
  appTranslation?: any;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '354px',
  height: '150px',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '20px',
};

const KnowledgeDeleteModal = (props: KnowledgeDeleteModalProps) => {
  const { open, handleOpen, infoToDelete, deleteInfo, title, description, skillType } =
    props;
  return (
    <Modal
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="center">
          <Typography
            sx={{
              color: 'var(--DinoError02)',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography
            id="modal-modal-description"
            sx={{
              mt: '15px',
              textAlign: 'center',
              width: '250px',
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '300px',
            display: 'flex',
            justifyContent: 'space-around',
            mt: '23px',
            mx: '28px',
          }}
        >
          <KnowledgeButton
            type="error"
            variant="outlined"
            onClick={() => handleOpen(false)}
          >
            {props.appTranslation ? props.appTranslation.cancel : 'CANCELAR'}
          </KnowledgeButton>
          <KnowledgeButton
            type="error"
            variant="contained"
            onClick={() => {
              infoToDelete && deleteInfo(skillType, []);
            }}
          >
            {props.appTranslation ? props.appTranslation.delete : 'ELIMINAR'}
          </KnowledgeButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default KnowledgeDeleteModal;
