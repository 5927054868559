import { FC } from 'react';
import { CollabSkillHistory } from '../../../../../../../../store/Collaborators/collaborators.type';
import { Skill1 } from '../../../../../../../../store/Skills/skills.type';
import { Box, ListItem, styled, Typography } from '@mui/material';

interface props {
  skills: Skill1[];
  skill: CollabSkillHistory;
  idx: number;
  type?: string;
}

const SkillsSliderCard: FC<props> = ({ skills, skill, idx, type }) => {
  let skillToShow: Skill1 = skills.filter((s) => s.id === skill.skillId)[0];

  const typeName = () => {
    switch (type!) {
      case 'CORE':
        return 'Core skill';
      case 'TOP':
        return 'Top skill';
      case 'OTHER':
        return 'Other skill';
      case 'SOFT':
        return 'Soft skill';
    }
  };

  const sortedLevels = [...skill.collaboratorSkillLevelsHistory].sort(
    (a, b) => b.level - a.level
  );

  return (
    <Item key={idx}>
      <Box sx={skill__container}>
        <Typography sx={{ fontSize: '18px', lineHeight: '23px' }}>
          {skillToShow.name}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', mt: '10px ' }}>
          <img
            src={require(`../../../../../../../../assets/type${type!}.svg`)}
            alt="skillType"
            style={{ height: '24px', width: '24px' }}
          />
          <Typography sx={{ fontSize: '18px', lineHeight: '23px' }}>
            {typeName()}
          </Typography>
        </Box>
      </Box>
      <img
        src={require(`../../../../../../../../assets/${
          type! === 'SOFT' ? 'SOFT' : 'TECH'
        }0${sortedLevels[0].level}.svg`)}
        alt="levelOrder"
      />
    </Item>
  );
};

const Item = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
  margin: 15px;
  font-size: 18px;
  background-color: #fff;
  border: 1px solid var(--DinoNeutral04);
  padding: 8px 18px;
  border-radius: 8px;
  color: var(--DinoNeutral01);
  min-height: 120px;
  gap: 30px;
`;

const skill__container = {
  display: 'flex',
  flexDirection: 'column',
};

export default SkillsSliderCard;
