import { createSlice } from '@reduxjs/toolkit';
import { DinoExperienceState } from './dinoExperience.type';

const initialState: DinoExperienceState = {
  dinoExperiences: [],
  description: '',
  rol: '',
  project: undefined,
  skills: undefined,
  startDate: null,
  finishDate: null,
  showForm: false,
  showAddBtn: true,
  message: '',
  showSnackbar: false,
  snackBarError: false,
  entityIsLoading: false,
  entitiesAreFetching: false,
  deleteModal: false,
  checkedFinishDate: false,
};

export default createSlice({
  name: 'dinoExperience',
  initialState,
  reducers: {
    getDinoExperiences: (state, { payload }) => {
      return {
        ...state,
        dinoExperieces: payload,
      };
    },
    deleteDinoExperiences: (state, { payload }) => {
      return {
        ...state,
        dinoExperiece: payload,
      };
    },
    setRol: (state, { payload }) => {
      return {
        ...state,
        rol: payload,
      };
    },
    setDescription: (state, { payload }) => {
      return {
        ...state,
        description: payload,
      };
    },
    setProject: (state, { payload }) => {
      return {
        ...state,
        project: payload,
      };
    },
    setSkills: (state, { payload }) => {
      return {
        ...state,
        skills: payload,
      };
    },
    setStartDate: (state, { payload }) => {
      return {
        ...state,
        startDate: payload,
      };
    },
    setFinishDate: (state, { payload }) => {
      return {
        ...state,
        finishDate: payload,
      };
    },
    setMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setShowSnackBar: (state, { payload }) => ({
      ...state,
      showSnackbar: payload,
    }),
    setSnackBarError: (state, { payload }) => {
      return {
        ...state,
        snackBarError: payload,
      };
    },
    setShowForm: (state, { payload }) => {
      return {
        ...state,
        showForm: payload,
      };
    },
    setShowAddBtn: (state, { payload }) => {
      return {
        ...state,
        showAddBtn: payload,
      };
    },
    setDeleteModal: (state, { payload }) => {
      return {
        ...state,
        deleteModal: payload,
      };
    },
    setDinoExperience: (state, { payload }) => {
      return {
        ...state,
        dinoExperience: payload,
      };
    },
    setDinoExperienceToEdit: (state, { payload }) => {
      return {
        ...state,
        dinoExperienceToEdit: payload,
      };
    },

    clearInputs: (state) => ({
      ...state,
      rol: '',
      description: '',
      project: undefined,
      skills: undefined,
      startDate: null,
      finishDate: null,
    }),
    setLoading: (state, { payload }) => ({
      ...state,
      entityIsLoading: payload,
    }),
    setCheckedFinishDate: (state, { payload }) => {
      return {
        ...state,
        checkedFinishDate: payload,
      };
    },
  },
});
