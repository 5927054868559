import { FC } from 'react';
import { Grid, Typography, Box, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import KnowledgeButton from '../../../commons/KnowledgeButton/KnowledgeButton';
import { Client } from '../../../store/Clients/clients.type';
import ClientsForm from './ClientsForm';
import ClientListItem from './ClientListItem';
import '../styles.scss';
import NotFoundFilters from '../../../commons/NotFoundFilters/NotFoundFilters';

interface LinkStateProps {
  clients: Client[];
  client: Client;
  clientToEdit?: Client;
  name: string;
  industry: string;
  snackBar: boolean;
  snackBarError: boolean;
  message?: any;
  showForm: boolean;
  deleteModal: boolean;
  filters: any[];
  showEditingForm: boolean;
  filtersActive: boolean;
  appTranslation?: any;
}

interface LinkDispatchProps {
  addClient: () => void;
  getClients: (filters: any[], page: number, count: number) => void;
  editClient: () => void;
  deleteClient: (client: any) => void;
  setSnackBar: (open: boolean) => void;
  setShowForm: (showForm: boolean) => void;
  setClient: (client: Client) => void;
  setIndustry: (industry: string) => void;
  cancelAddClient: () => void;
  handleDeleteModal: (open: boolean) => void;
  setName: (name: string) => void;
  setShowPagination: (showPagination: boolean) => void;
  setShowEditingForm: (showEditingForm: boolean) => void;
  setClientToEdit: (client?: Client) => void;
  clearInputs: () => void;
}

type ClientsContainerProps = LinkDispatchProps & LinkStateProps;

const ClientsContainer: FC<ClientsContainerProps> = (props: ClientsContainerProps) => {
  const editFormToShow = (
    <ClientsForm
      editClient={props.editClient}
      setShowForm={props.setShowEditingForm}
      setIndustry={props.setIndustry}
      clear={() => {
        props.setClientToEdit(undefined);
      }}
      setName={props.setName}
      name={props.name}
      industry={props.industry}
      buttonText={
        props.appTranslation ? props.appTranslation.actionButtons.edit : 'Editar'
      }
      positionLeft={true}
      clientToEdit={props.clientToEdit}
      addClient={props.addClient}
      cancelAddClient={props.cancelAddClient}
      isEdit={true}
      appTranslation={props.appTranslation && props.appTranslation}
    />
  );

  return (
    <>
      <div className="clients__container ">
        <Grid container sx={{ mb: '40px' }}>
          <Grid
            item
            xs={11.5}
            sx={{ display: 'flex', justifyContent: 'center', pl: '45px' }}
          >
            <Typography sx={{ fontSize: '30px' }}>
              {props.appTranslation ? props.appTranslation.title : 'Clientes'}
            </Typography>
          </Grid>
          <Tooltip title="Agregar" placement="top-end">
            <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!props.showForm && (
                <KnowledgeButton
                  type="primary"
                  variant={props.showForm ? 'disabled' : 'contained'}
                  onClick={() => {
                    props.setShowForm(true);
                    props.setShowPagination(false);
                    props.clearInputs();
                  }}
                >
                  <AddRoundedIcon sx={{ width: '20px' }} />
                </KnowledgeButton>
              )}
            </Grid>
          </Tooltip>
        </Grid>
        {!props.showForm && props.clients && props.clients.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                color: 'var(--DinoNeutral03)',
              }}
            >
              {!props.filtersActive ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '182px',
                  }}
                >
                  {props.appTranslation
                    ? props.appTranslation.noMessage.withoutClients + '.'
                    : 'Aún no se han agregado clientes.'}
                </Box>
              ) : (
                <NotFoundFilters />
              )}
            </Typography>
          </Box>
        ) : props.showForm ? (
          <Box>
            <ClientsForm
              addClient={props.addClient}
              setIndustry={props.setIndustry}
              clear={() => {
                props.setClientToEdit(undefined);
              }}
              setName={props.setName}
              name={props.name}
              industry={props.industry}
              setShowForm={props.setShowForm}
              buttonText={
                props.appTranslation
                  ? props.appTranslation.actionButtons.confirm
                  : 'Aceptar'
              }
              editClient={props.editClient}
              cancelAddClient={props.cancelAddClient}
              appTranslation={props.appTranslation && props.appTranslation}
            />
          </Box>
        ) : (
          props.clients &&
          props.clients.length > 0 &&
          !props.showForm &&
          props.clients.map((client, idx) => (
            <>
              {!!props.clientToEdit &&
              client.id === props.clientToEdit.id &&
              props.showEditingForm ? (
                <>
                  {editFormToShow}
                  <Grid item xs={12} sx={{ mt: '15px' }}>
                    <Divider sx={{ color: 'var(--DinoNeutral01)' }} />
                  </Grid>
                </>
              ) : (
                <>
                  <ClientListItem
                    setClient={props.setClient}
                    client={client}
                    handleOpen={props.handleDeleteModal}
                    deleteModal={props.deleteModal}
                    key={`${idx}-${client.id}`}
                    setShowEditingForm={props.setShowEditingForm}
                    setClientToEdit={props.setClientToEdit}
                    appTranslation={props.appTranslation && props.appTranslation}
                  />
                  <Grid item xs={12} sx={{ mt: '15px' }}>
                    <Divider sx={{ color: 'var(--DinoNeutral01)' }} />
                  </Grid>
                </>
              )}
            </>
          ))
        )}
      </div>
    </>
  );
};

export default ClientsContainer;
