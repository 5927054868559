import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { Button, styled, TableFooter, TablePagination } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'var(--DinoPrimary04)',
    fontSize: 18,
    fontWeight: 500,
  },
}));

interface TableProps {
  columns: any[];
  data: any[];
  collapsibleColumns?: any[];
  setShowEditLvlModal: (showEditLvlModal: boolean) => void;
  setLevelSelected: (value: number) => void;
  setSkillIdEdit: (value: any) => void;
}

const TechLevelsTable: React.FC<TableProps> = ({
  columns,
  collapsibleColumns,
  data,
  setShowEditLvlModal,
  setLevelSelected,
  setSkillIdEdit,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: '1400px',
        marginX: 'auto',
        my: '50px',
        boxShadow: '0px 0px 2px rgba(38, 43, 47, 0.4)',
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {columns.map((column: any, idx) => (
              <StyledTableCell key={idx}>{column.title}</StyledTableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row) => {
            return (
              <Row
                key={row.name}
                row={row}
                collapsibleColumns={collapsibleColumns}
                setShowEditLvlModal={setShowEditLvlModal}
                setLevelSelected={setLevelSelected}
                setSkillIdEdit={setSkillIdEdit}
              />
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{ display: 'flex', justifyContent: 'flex-end', border: 'none' }}
            />
          </TableCell>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

function Row(props: {
  row: any;
  collapsibleColumns?: any[];
  setShowEditLvlModal: (showEditLvlModal: boolean) => void;
  setLevelSelected: (value: number) => void;
  setSkillIdEdit: (value: any) => void;
}) {
  const {
    row,
    collapsibleColumns,
    setShowEditLvlModal,
    setLevelSelected,
    setSkillIdEdit,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>
          {row.level}{' '}
          <Button
            sx={{ px: '0px', minWidth: '40px', ml: '20px', borderRadius: '8px' }}
            onClick={() => {
              setShowEditLvlModal(true);
              setLevelSelected(row.level);
              setSkillIdEdit(row.skillId);
            }}
          >
            <EditIcon sx={{ height: '20px', mb: '3px' }} />
          </Button>
        </TableCell>
        <TableCell>{row.category}</TableCell>
        <TableCell>{row.kind}</TableCell>
        <TableCell>{row.type}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: 'var(--DinoNeutral07)' }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '25px' }}>
              <Typography variant="h6" gutterBottom component="div">
                Skill level history
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {collapsibleColumns?.map((cc, idx) => (
                      <TableCell
                        key={idx}
                        align={cc.title === 'Skill level' ? 'center' : 'left'}
                      >
                        {cc.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.levelsChange.map((levelsChangeRow: any) => (
                    <TableRow key={levelsChangeRow.date}>
                      <TableCell component="th" scope="row">
                        {levelsChangeRow.date}
                      </TableCell>
                      <TableCell align="center">{levelsChangeRow.skillLevel}</TableCell>
                      <TableCell>{levelsChangeRow.comment}</TableCell>
                      <TableCell>{levelsChangeRow.by}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default TechLevelsTable;
