import { WeightedSkill } from '../store/Skills/skills.type';
import { getDefaultHeaders } from './utils.service';

class WeightedSkillsService {
  async addWeightedSkills(weightedSkill: WeightedSkill[]): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/weighted-skills`, {
      method: 'POST',
      body: JSON.stringify(weightedSkill),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async deleteWeightedSkill(weightedSkill: WeightedSkill): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/weighted-skills/${weightedSkill.id}`,
      {
        method: 'DELETE',
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  async getWeightedSkillById(weightedSkillId: number): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/weighted-skills/${weightedSkillId}`,
      {
        method: 'GET',
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  async updateWskillLevel(weightedSkillId: number, newLevel: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/weighted-skills/`, {
      method: 'PATCH',
      body: JSON.stringify({
        id: weightedSkillId,
        level: newLevel,
      }),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }
}

const weightedSkillsService = new WeightedSkillsService();
export default weightedSkillsService;
