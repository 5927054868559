import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import KnowledgeAlert from '../../commons/KnowledgeAlert/KnowledgeAlert';
import KnowledgeBackdrop from '../../commons/KnowledgeBackdrop/KnowledgeBackdrop';
import KnowledgeDeleteModal from '../../commons/KnowledgeDeleteModal/KnowledgeDeleteModal';
import KnowledgePageContainer from '../../commons/KnowledgePageContainer/KnowledgePageContainer';
import { useSEO } from '../../hooks/useSeo';
import { RootState } from '../../store';
import { Client } from '../../store/Clients/clients.type';
import {
  addClient,
  cancelAddClient,
  clearInputs,
  deleteClient,
  editClient,
  getClients,
  handleDeleteModal,
  setClient,
  setClientToEdit,
  setFiltersActive,
  setIndustry,
  setName,
  setShowEditingForm,
  setShowForm,
  setShowPagination,
  setSnackBar,
} from '../../store/Clients/clients.actions';
import { useLocation } from 'react-router-dom';
import defaultItems from '../../utils/defaultItems';
import {
  setCurrentPath,
  setDefaultItems,
  setPathsToShow,
} from '../../store/routes/routes.actions';
import KnowledgePagination from '../../commons/KnowledgePagination/KnowledgePagination';
import { INITIAL_CLIENTS_COUNT, INITIAL_CLIENTS_PAGE } from '../../helpers/constants';
import { Grid } from '@mui/material';
import ClientsContainer from './Components/ClientsContainer';
import KnowledgeAutocomplete from '../../commons/KnowledgeAutocomplete/KnowledgeAutocomplete';

interface LinkDispatchProps {
  addClient: () => void;
  clearInputs: () => void;
  setSnackBar: (open: boolean) => void;
  setShowForm: (showForm: boolean) => void;
  setName: (name: string) => void;
  setClient: (client: Client) => void;
  setIndustry: (industry: string) => void;
  cancelAddClient: () => void;
  handleDeleteModal: (open: boolean) => void;
  getClients: (filters: any[], page?: number, count?: number) => void;
  setCurrentPath: (path: string) => void;
  setDefaultItems: (items: any[]) => void;
  setPathsToShow: (location: any, defItems?: any[]) => void;
  setShowPagination: (showPagination: boolean) => void;
  setShowEditingForm: (showEditingForm: boolean) => void;
  setClientToEdit: (client?: Client) => void;
  deleteClient: (client: Client) => void;
  editClient: () => void;
  setFiltersActive: (filtersActive: boolean) => void;
}
interface LinkStateProps {
  clients: Client[];
  client?: Client;
  clientToEdit?: Client;
  industry: string;
  name: string;
  showForm: boolean;
  message: any;
  snackBar: boolean;
  snackBarError: boolean;
  entitiesAreFetching: boolean;
  deleteModal: boolean;
  page: number;
  pageCount: number;
  filters: any[];
  showPagination: boolean;
  showEditingForm: boolean;
  filtersActive: boolean;
  appTranslation?: any;
}

type ClientsPageProps = LinkDispatchProps & LinkStateProps;

const ClientsPage: FC<ClientsPageProps> = (props: ClientsPageProps) => {
  let clientsPageTranslation =
    props.appTranslation && props.appTranslation.pages.PROJECTS.clients;

  useSEO(
    '',
    props.appTranslation
      ? clientsPageTranslation.windowTitle
      : 'Knowledge Catalog | Clientes'
  );

  const location: any = useLocation();
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    let currentPathAux: any = location.pathname.split('/')[1];
    props.setCurrentPath(currentPathAux);
    props.setDefaultItems(
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.setPathsToShow(
      location,
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.getClients([], INITIAL_CLIENTS_PAGE, INITIAL_CLIENTS_COUNT);
    props.setShowForm(false);
    props.setClientToEdit();
  }, []);

  //UPDATE MENU ITEMS WHEN CHANGING APP IDIOM
  useEffect(() => {
    let currentPathAux: any = location.pathname.split('/')[1];
    props.setCurrentPath(currentPathAux);
    props.setDefaultItems(
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.setPathsToShow(
      location,
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
  }, [props.appTranslation]);

  return (
    <>
      <KnowledgePageContainer>
        {props.message && (
          <KnowledgeAlert
            header={props.message.header}
            severity={props.message.type}
            openSnackBar={props.snackBar}
            closeSnackBar={() => props.setSnackBar(false)}
            message={props.message.text}
          />
        )}
        <div style={{ width: '280px', marginBottom: '20px' }}>
          <KnowledgeAutocomplete
            items={props.clients ? props.clients.map((client) => client.name) : []}
            label={
              props.appTranslation ? clientsPageTranslation.searchComponent : 'Cliente'
            }
            filters={props.filters}
            filterColumnName={'name'}
            selectedValue={selectedValue}
            initialPage={INITIAL_CLIENTS_PAGE}
            initialCount={INITIAL_CLIENTS_COUNT}
            setSelectedValue={setSelectedValue}
            getFn={props.getClients}
            filtersActive={props.filtersActive}
            setFiltersActive={props.setFiltersActive}
          />
        </div>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          sx={{ minHeight: '79vh', background: '#FFF', pb: '20px' }}
        >
          <Grid item xs={12}>
            <ClientsContainer
              getClients={props.getClients}
              deleteClient={props.deleteClient}
              addClient={props.addClient}
              clientToEdit={props.clientToEdit}
              setSnackBar={props.setSnackBar}
              setShowForm={props.setShowForm}
              setClient={props.setClient}
              setIndustry={props.setIndustry}
              handleDeleteModal={props.handleDeleteModal}
              setName={props.setName}
              setShowPagination={props.setShowPagination}
              setShowEditingForm={props.setShowEditingForm}
              setClientToEdit={props.setClientToEdit}
              clearInputs={props.clearInputs}
              clients={props.clients}
              client={props.client!}
              name={props.name}
              industry={props.industry}
              snackBar={props.snackBar}
              snackBarError={props.snackBarError}
              showForm={props.showForm}
              deleteModal={props.deleteModal}
              filters={props.filters}
              showEditingForm={props.showEditingForm}
              cancelAddClient={props.cancelAddClient!}
              editClient={props.editClient}
              filtersActive={props.filtersActive}
              appTranslation={props.appTranslation && clientsPageTranslation}
            />
          </Grid>
          <Grid item xs={12}>
            {props.clients.length !== 0 && props.showPagination && (
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <KnowledgePagination
                  getFn={props.getClients}
                  getPage={props.page}
                  getPageCount={props.pageCount}
                  getCount={5}
                  filters={props.filters}
                />
              </div>
            )}
          </Grid>
        </Grid>

        <KnowledgeDeleteModal
          open={props.deleteModal}
          handleOpen={props.handleDeleteModal}
          infoToDelete={props.client}
          title={
            props.appTranslation
              ? clientsPageTranslation.deleteModal.title
              : 'Eliminar cliente'
          }
          description={
            props.appTranslation
              ? clientsPageTranslation.deleteModal.description
              : '¿Está seguro de que desea eliminar el cliente?'
          }
          deleteInfo={props.deleteClient}
          appTranslation={
            props.appTranslation && clientsPageTranslation.deleteModal.actionButtons
          }
        />

        <KnowledgeBackdrop loading={props.entitiesAreFetching} />
      </KnowledgePageContainer>
    </>
  );
};
const mapStateToProps = (state: RootState): LinkStateProps => {
  const {
    clients,
    client,
    name,
    industry,
    showForm,
    message,
    page,
    pageCount,
    showPagination,
    snackBar,
    snackBarError,
    entitiesAreFetching,
    deleteModal,
    filters,
    showEditingForm,
    clientToEdit,
    filtersActive,
  } = state.clients;
  const { appTranslation } = state.translations;
  return {
    page: page ? page : INITIAL_CLIENTS_PAGE,
    pageCount,
    showPagination,
    clients,
    client,
    showForm,
    filters: filters?.length ? filters : [],
    message,
    snackBar,
    snackBarError,
    entitiesAreFetching,
    deleteModal,
    industry,
    name,
    showEditingForm,
    clientToEdit,
    filtersActive,
    appTranslation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  addClient: bindActionCreators(addClient, dispatch),
  deleteClient: bindActionCreators(deleteClient, dispatch),
  setSnackBar: bindActionCreators(setSnackBar, dispatch),
  setShowForm: bindActionCreators(setShowForm, dispatch),
  handleDeleteModal: bindActionCreators(handleDeleteModal, dispatch),
  setClient: bindActionCreators(setClient, dispatch),
  setIndustry: bindActionCreators(setIndustry, dispatch),
  cancelAddClient: bindActionCreators(cancelAddClient, dispatch),
  setName: bindActionCreators(setName, dispatch),
  getClients: bindActionCreators(getClients, dispatch),
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setDefaultItems: bindActionCreators(setDefaultItems, dispatch),
  setPathsToShow: bindActionCreators(setPathsToShow, dispatch),
  setShowPagination: bindActionCreators(setShowPagination, dispatch),
  setShowEditingForm: bindActionCreators(setShowEditingForm, dispatch),
  setClientToEdit: bindActionCreators(setClientToEdit, dispatch),
  clearInputs: bindActionCreators(clearInputs, dispatch),
  editClient: bindActionCreators(editClient, dispatch),
  setFiltersActive: bindActionCreators(setFiltersActive, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
