import { Dispatch } from 'redux';
import authService from '../../services/auth.service';
import { AlertType } from '../Message/message.type';
import authSlice from './authSlice';

export const saveSession = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(authSlice.actions.setLoading(true));
    const response = await authService.settingSession(data.credential).catch(() => {
      handleErrors(500, dispatch, true);
    });
    switch (response.statusCode) {
      case 200:
        dispatch(authSlice.actions.saveSession(response.data.userData));
        localStorage.setItem('user', JSON.stringify(response.data.userData));
        localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
        break;
      case 404:
        dispatch(authSlice.actions.setShowSnackBar(true));
        dispatch(
          authSlice.actions.setMessage({
            header: 'Usuario no autorizado',
            text: `El usuario que intenta ingresar no tiene los permisos necesarios. 
              Comuníquese con los administradores para remediar la situación.`,
            type: AlertType.ERROR,
          })
        );
        break;
    }
    dispatch(authSlice.actions.setLoading(false));
  };
};

export const setUser = (user: any) => {
  return (dispatch: Dispatch) => {
    dispatch(authSlice.actions.saveSession(user));
  };
};

export const cleanSession = () => {
  return (dispatch: Dispatch) => {
    clearStorage();
    dispatch(authSlice.actions.saveSession(undefined));
  };
};

export const setShowSnackBar = (show: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(authSlice.actions.setShowSnackBar(show));
  };
};

export function clearStorage() {
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
}

export function getAuthorizedUsers() {
  return async (dispatch: Dispatch) => {
    let authUsers = await authService.getAllUsers();
    dispatch(authSlice.actions.getAuthorizedUsers(authUsers));
  };
}

export const createNewUser = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(authSlice.actions.setLoading(true));
    const response = await authService.createNewUser(data);
    switch (response.statusCode) {
      case 201:
        dispatch(authSlice.actions.setShowSnackBar(true));
        dispatch(
          authSlice.actions.setMessage({
            header: 'Usuario registrado exitosamente',
            text: `el usuario fue dado de alta exitosamente`,
            type: AlertType.SUCCESS,
          })
        );
        break;
      case 400:
        dispatch(authSlice.actions.setShowSnackBar(true));
        dispatch(
          authSlice.actions.setMessage({
            header: 'Error de Google ID',
            text: `El Google ID que esta está intentando registrar ya se encuentra en uso`,
            type: AlertType.ERROR,
          })
        );
        break;
    }
    let authUsers = await authService.getAllUsers();
    dispatch(authSlice.actions.getAuthorizedUsers(authUsers));
    dispatch(authSlice.actions.setLoading(false));

    return response;
  };
};

export const deleteOneUser = (googleId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(authSlice.actions.setLoading(true));
    const response = await authService.deleteOneUser(googleId);
    let authUsers = await authService.getAllUsers();
    dispatch(authSlice.actions.getAuthorizedUsers(authUsers));
    dispatch(authSlice.actions.setLoading(false));

    return response;
  };
};

export function handleErrors(error: any, dispatch: any, fromLogin: boolean = false) {
  switch (error) {
    case 500:
      dispatch(authSlice.actions.setShowSnackBar(true));
      dispatch(
        authSlice.actions.setMessage({
          header: 'Ha ocurrido un error',
          text: `En este momento no puede iniciar sesión. Por favor, intente más tarde.`,
          type: AlertType.ERROR,
        })
      );
      clearStorage();
      fromLogin && dispatch(authSlice.actions.saveSession(undefined));
      break;
    case 401:
      dispatch(authSlice.actions.setShowSnackBar(true));
      dispatch(
        authSlice.actions.setMessage({
          header: `La sesión ha expirado`,
          text: `Por favor, inicie sesión nuevamente.`,
          type: AlertType.ERROR,
        })
      );
      clearStorage();
      dispatch(authSlice.actions.saveSession(undefined));
      break;
  }
}
