import { INITIAL_SKILLS_COUNT, INITIAL_SKILLS_PAGE } from '../helpers/constants';
import { Skill, LevelDescription } from '../store/Skills/skills.type';
import { getDefaultHeaders } from './utils.service';

class SkillsService {
  //NEW METHODS

  async getAllSkills(): Promise<any> {
    let url: any = `${process.env.REACT_APP_API_HOST}/skills`;

    const response = await fetch(`${url}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async getCategories(): Promise<any> {
    let url: any = `${process.env.REACT_APP_API_HOST}/skillCategories`;

    const response = await fetch(`${url}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async getKinds(): Promise<any> {
    let url: any = `${process.env.REACT_APP_API_HOST}/skillKinds`;

    const response = await fetch(`${url}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async getTopSkills(): Promise<any> {
    let url: any = `${process.env.REACT_APP_API_HOST}/topSkills`;

    const response = await fetch(`${url}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  //TODO REVIEW OLD METHODS

  private createParamFilter(filters: any[] = []) {
    let paramFilter: string = '';
    if (filters.length) {
      paramFilter = '';
      filters.forEach((filterData) => {
        let value = filterData.value;
        if (filterData.value !== -1) {
          let filterName = filterData.columnName;
          let condition = '$eq';
          if (filterName === 'id') {
            value = isNaN(parseInt(filterData.value, 10)) ? -1 : filterData.value;
          } else if (filterName === 'name') {
            condition = '$contL';
          } else {
            condition = '$eq';
          }
          paramFilter = `${paramFilter}&filter=${filterName}||${condition}||${value}`;
        }
      });
    }
    return paramFilter;
  }

  async addSkill(skill: Skill): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/skills`, {
      method: 'POST',
      body: JSON.stringify(skill),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async getSkills(
    filters?: any[],
    page: number = INITIAL_SKILLS_PAGE,
    count: number = INITIAL_SKILLS_COUNT
  ): Promise<any> {
    let queryParams: any = `${process.env.REACT_APP_API_HOST}/skills`;
    if (filters) {
      queryParams = `${
        process.env.REACT_APP_API_HOST
      }/skills?page=${page}&limit=${count}${this.createParamFilter(filters)}`;
    }
    const response = await fetch(`${queryParams}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async deleteSkill(skill: Skill): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/skills/${skill.id}`, {
      method: 'DELETE',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async editSkill(skill: Skill): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/skills/${skill.id}`, {
      method: 'PUT',
      body: JSON.stringify(skill),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async getLevelDescriptions(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/levels-description`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async addLevelDescriptions(levelDescriptions: LevelDescription[]): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/levels-description`, {
      method: 'POST',
      body: JSON.stringify(levelDescriptions),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async editLevelDescriptions(levelDescriptions: LevelDescription[]): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/levels-description`, {
      method: 'PATCH',
      body: JSON.stringify(levelDescriptions),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async deleteLevelDescriptions(skill: Skill): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/levels-description/${skill.id}`,
      {
        method: 'DELETE',
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }
}

const skillsService = new SkillsService();
export default skillsService;
