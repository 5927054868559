import { FC } from 'react';
import LoginPage from '../../pages/Login/LoginPage';

const PrivateRoute: FC<any> = ({ children }) => {
  const localStore: any = localStorage.getItem('user');
  const loggedUser: any = JSON.parse(localStore);
  return <>{loggedUser ? children : <LoginPage />}</>;
};

export default PrivateRoute;
