import { Dispatch } from 'redux';
import { DinoExperience } from '../DinoExperience/dinoExperience.type';
import { Certification } from '../Certifications/certifications.type';
import { Collaborator, Skill } from '../Collaborators/collaborators.type';
import generatePDFSlice from './generatePDFSlice';
import { RootState } from '..';
import { EnglishLevel } from '../EnglishLevels/englishLevels.type';
import { PreviousExperience } from '../PreviousExperience/prevExperience.type';
import generatePDFService from '../../services/generatePDF.service';
import { formatDBText, splitTheFirstWord } from '../../helpers/formattingFunctions';
import { handleErrors } from '../Auth/auth.actions';

export const generatePDF = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(generatePDFSlice.actions.setLoading(true));

    const collaboratorCv: Collaborator = getState().collaborators.collaborator!;
    const img: string =
      'https://cdn1.iconfinder.com/data/icons/avatars-1-5/136/60-512.png';
    const user: string = getState().auth.user.email;
    const certificationsCv: Certification[] = getState().generatePDF.certificationsCv!;
    const englishLevelCv: EnglishLevel = getState().generatePDF.englishLevelCv!;
    const toolsCv: Skill[] = getState().generatePDF.toolsCv!;
    const dinoExperiencesCv: DinoExperience[] = getState().generatePDF.dinoExperiencesCv!;
    const skillsCv: Skill[] = getState().generatePDF.skillsCv!;
    const prevExperiencesCv: PreviousExperience[] =
      getState().generatePDF.prevExperiencesCv!;
    const appTranslation: any = getState().translations.appTranslation.generatePDF;
    const cvLanguage: string = getState().generatePDF.cvLanguage;
    const createdBy: {} = getState().auth.user.name;
    const collaborator = {
      id: collaboratorCv.id!,
    };
    const certifications = certificationsCv.map((c: Certification) => {
      return {
        name: c.name,
        obtainedDate: c.obtainedDate,
        expirationDate: c.expirationDate,
        description: c.description,
        company: c.company,
      };
    });
    const englishLevel = {
      id: englishLevelCv.id!,
      name:
        formatDBText(englishLevelCv.name.split('_')[0]) +
        ' - ' +
        formatDBText(splitTheFirstWord(englishLevelCv.name)),
    };

    const tools = toolsCv.map((t: Skill) => {
      return {
        title: t.name,
        description: t.description,
      };
    });

    const dinoExperiences = dinoExperiencesCv.map((de: DinoExperience) => {
      return {
        project: {
          projectName: de?.project?.projectName,
        },
        startDate: de?.startDate,
        finishDate: de?.finishDate,
        client: de?.project?.client!.name + ' - ' + de?.project?.client!.industry,
        role: formatDBText(de?.rol),
        description: de?.description,
      };
    });

    const skills = skillsCv.map((s: Skill) => {
      return {
        title: s.name,
        description: s.description,
      };
    });

    const previousExperiences = prevExperiencesCv.map((pe: PreviousExperience) => {
      return {
        role: pe.rol,
        seniority: pe.seniority,
        startDate: pe.startDate,
        finishDate: pe.finishDate,
        industry: pe.industry,
        description: pe.description,
      };
    });

    const responseURL = await generatePDFService
      .generatePDF({
        collaborator,
        createdBy,
        user,
        fullName: collaboratorCv.fullname,
        role: formatDBText(collaboratorCv.job),
        seniority: formatDBText(collaboratorCv.seniority),
        img,
        certifications,
        englishLevel,
        tools,
        dinoExperiences,
        skills,
        previousExperiences,
        cvLanguage,
      })
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!responseURL) return;
    handleErrors(responseURL.statusCode, dispatch);
    if (responseURL && responseURL.link && responseURL.link !== 'Link corrupto') {
      dispatch(generatePDFSlice.actions.setResumeS3Link(responseURL));
    } else {
      dispatch(generatePDFSlice.actions.setShowSnackBar(true));
      dispatch(
        generatePDFSlice.actions.setMessage({
          type: 'error',
          text: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.alertMessages.error.downloadError + '.'
            : 'En estos momentos no se puede generar el CV, inténtelo más tarde.',
          header: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.alertMessages.headers.ACTION_DENIED
            : 'Acción denegada',
        })
      );
    }
    dispatch(generatePDFSlice.actions.setLoading(false));
  };
};

export const setShowGeneratePdfPage = (payload: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setShowGeneratePdfPage(payload));
  };
};

export const setDinoExperiencesToShow = (dinoExperiencesToShow: DinoExperience[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setDinoExperiencesToShow(dinoExperiencesToShow));
  };
};

export const setShowSnackBar = (showSnackBar: boolean) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(generatePDFSlice.actions.setShowSnackBar(showSnackBar));
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.COLLABORATORS.generatePDF;
    if (showSnackBar === true) {
      dispatch(
        generatePDFSlice.actions.setMessage({
          type: 'error',
          text: !!appTranslation
            ? appTranslation.alertMessages.error.maxOptions + '.'
            : 'Sehhhh ha seleccionado el máximo de opciones permitidas. Pero puedes cambiar tus elecciones.',
          header: !!appTranslation
            ? appTranslation.alertMessages.headers.ACTION_DENIED
            : 'Acción denegada',
        })
      );
    }
  };
};

export const showErrorDownloadPDF = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.COLLABORATORS.generatePDF;

    dispatch(generatePDFSlice.actions.setShowSnackBar(true));
    dispatch(
      generatePDFSlice.actions.setMessage({
        type: 'error',
        text: !!appTranslation
          ? appTranslation.alertMessages.error.downloadError + '.'
          : 'En estos momentos no se puede generar el CV, inténtelo más tarde.',
        header: !!appTranslation
          ? appTranslation.alertMessages.headers.ACTION_DENIED
          : 'Acción denegada',
      })
    );
  };
};

export const setAlertToContinue = (activeStep: number) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.COLLABORATORS.generatePDF;

    let step = '';
    dispatch(generatePDFSlice.actions.setShowSnackBar(true));
    if (activeStep === 0) {
      if (appTranslation) {
        step = appTranslation.steps[0].title + 's';
      } else {
        step = 'Experiencias en Dino';
      }
    } else if (activeStep === 1) {
      if (appTranslation) {
        step = appTranslation.steps[1].title;
      } else {
        step = 'Certificaciones';
      }
    } else if (activeStep === 3) {
      if (appTranslation) {
        step = appTranslation.steps[3].title;
      } else {
        step = 'Herramientas';
      }
    } else if (activeStep === 4) {
      if (appTranslation) {
        step = appTranslation.steps[4].title + 's';
      } else {
        step = 'Experiencias previa';
      }
    }
    dispatch(
      generatePDFSlice.actions.setMessage({
        type: 'info',
        text: !!appTranslation
          ? appTranslation.alertMessages.information.partOne +
            ' ' +
            step.toLowerCase() +
            '. ' +
            appTranslation.alertMessages.information.partTwo +
            '.'
          : `puedes continuar al siguiente paso sin seleccionar ${step} del colaborador. No se mostrarán en el CV.`,
        header: !!appTranslation
          ? appTranslation.alertMessages.headers.INFORMATION
          : 'Information',
      })
    );
  };
};

export const setCertificationsToShow = (payload: any[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setCertificationsToShow(payload));
  };
};

export const setCertificationsCV = (certification: Certification) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let certificationsCV: any[] = getState().generatePDF.certificationsCv;
    if (!certificationsCV.includes(certification)) {
      let updatedArray = [...certificationsCV, certification];
      dispatch(generatePDFSlice.actions.setCertificationsCV(updatedArray));
    } else {
      const updatedArray = certificationsCV.filter((c) => c.id !== certification.id);
      dispatch(generatePDFSlice.actions.setCertificationsCV(updatedArray));
    }
  };
};

export const setEntityIsLoading = () => {
  return (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setLoading(true));
  };
};

export const clearCV = () => {
  return (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.clearCV());
  };
};

export const setDinoExperiencesCV = (dinoExperience: DinoExperience) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let dinoExperiencesCV: any[] = getState().generatePDF.dinoExperiencesCv!;
    if (!dinoExperiencesCV.includes(dinoExperience)) {
      let updatedArray = [...dinoExperiencesCV, dinoExperience];
      dispatch(generatePDFSlice.actions.setDinoExperiencesCV(updatedArray));
    } else {
      let updatedArray = dinoExperiencesCV.filter((d) => d.id !== dinoExperience.id);
      dispatch(generatePDFSlice.actions.setDinoExperiencesCV(updatedArray));
    }
  };
};

export const setSkillsCV = (skill: Skill) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let skillsCv: any[] = getState().generatePDF.skillsCv;
    if (!skillsCv.includes(skill)) {
      let updatedArray = [...skillsCv, skill];
      dispatch(generatePDFSlice.actions.setSkillsCV(updatedArray));
    } else {
      const updatedArray = skillsCv.filter((c) => c.id !== skill.id);
      dispatch(generatePDFSlice.actions.setSkillsCV(updatedArray));
    }
  };
};

export const setSkillsToShow = (payload: any[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setSkillsToShow(payload));
  };
};

export const setEnglishLevelCV = (payload: EnglishLevel) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setEnglishLevelCV(payload));
  };
};

export const setCvLanguage = (payload: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setCvLanguage(payload));
  };
};

export const setPrevExperiencesToShow = (payload: any[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setPrevExperiencesToShow(payload));
  };
};

export const setPrevExperiencesCV = (prevExperience: PreviousExperience) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let prevExperiencesCv: any[] = getState().generatePDF.prevExperiencesCv;
    if (!prevExperiencesCv.includes(prevExperience)) {
      let updatedArray = [...prevExperiencesCv, prevExperience];
      dispatch(generatePDFSlice.actions.setPrevExperiencesCV(updatedArray));
    } else {
      const updatedArray = prevExperiencesCv.filter((p) => p.id !== prevExperience.id);
      dispatch(generatePDFSlice.actions.setPrevExperiencesCV(updatedArray));
    }
  };
};
export const setToolsCV = (tool: Skill) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let toolsCv: any[] = getState().generatePDF.toolsCv;
    if (!toolsCv.includes(tool)) {
      let updatedArray = [...toolsCv, tool];
      dispatch(generatePDFSlice.actions.setToolsCV(updatedArray));
    } else {
      const updatedArray = toolsCv.filter((t) => t.id !== tool.id);
      dispatch(generatePDFSlice.actions.setToolsCV(updatedArray));
    }
  };
};

export const setToolsToShow = (payload: any[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setToolsToShow(payload));
  };
};

export const setResumeS3Link = (payload: string) => {
  return (dispatch: Dispatch) => {
    dispatch(generatePDFSlice.actions.setResumeS3Link(payload));
  };
};

export const clearResumeS3Link = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(generatePDFSlice.actions.clearResumeS3Link());
  };
};
