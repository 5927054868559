import { createSlice } from '@reduxjs/toolkit';
import { EnglishLevelState } from './englishLevels.type';

const initialState: EnglishLevelState = {
  englishLevels: [],
  name: '',
  entityIsLoading: false,
};

export default createSlice({
  name: 'englishLevel',
  initialState,
  reducers: {
    getEnglishLevel: (state, { payload }) => {
      return {
        ...state,
        englishLevels: payload,
      };
    },
    requestEnglishLevel: (state, { payload }) => {
      return {
        ...state,
        entityIsLoading: payload,
      };
    },
  },
});
