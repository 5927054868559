import React, { FC } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

interface KnowledgeAlertProps {
  severity: any;
  openSnackBar: boolean;
  closeSnackBar: () => void;
  message?: string;
  header?: string;
}

const KnowledgeAlert: FC<any> = (props: KnowledgeAlertProps) => {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const { openSnackBar, closeSnackBar, message, severity, header } = props;
  return (
    <Snackbar open={openSnackBar} autoHideDuration={5000} onClose={closeSnackBar}>
      <Alert
        onClose={closeSnackBar}
        severity={severity}
        sx={{
          width: '500px',
          backgroundColor:
            severity === 'error'
              ? 'var(--DinoError05)'
              : severity === 'warning'
              ? 'var(--DinoWarning04)'
              : 'var(--DinoSuccess05)',
          color: 'var(--DinoNeutral01)',
          position: 'fixed',
          top: '2px',
          right: '2px',
        }}
      >
        <b>{`${header}: `}</b>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default KnowledgeAlert;
