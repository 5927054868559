import { createSlice } from '@reduxjs/toolkit';
import { SkillsState } from './skills.type';

const initialState: SkillsState = {
  skills1: [],
  categories: [],
  kinds: [],
  topSkills: [],

  //TODO review old initial state
  skills: [],
  showForm: false,
  snackBar: false,
  snackBarError: false,
  entitiesAreFetching: false,
  deleteModal: false,
  pageCount: 1,
  showPagination: true,
  showAddBtn: true,
  showFormSoftPS: false,
  showFormTechPS: false,
  showEditFormTechPS: false,
  showFormAwsPS: false,
  showFormToolsPS: false,
  showButtonsForm: false,
  filtersActive: false,
  takenSkillName: false,
  createLevels: false,
  wSkillToEditId: null,
};

export default createSlice({
  name: 'skills',
  initialState,
  reducers: {
    getAllSkills: (state, { payload }) => {
      return {
        ...state,
        skills1: payload,
      };
    },
    setCategories: (state, { payload }) => {
      return {
        ...state,
        categories: payload,
      };
    },
    setKinds: (state, { payload }) => {
      return {
        ...state,
        kinds: payload,
      };
    },
    setTopSkills: (state, { payload }) => {
      return {
        ...state,
        topSkills: payload,
      };
    },

    //TODO REVIEW OLD REDUCERS
    getSkills: (state, { payload }) => {
      const { data, page, count, pageCount, filters } = payload;
      return {
        ...state,
        skills: data,
        filters,
        page,
        pageCount,
        count,
      };
    },
    requestSkills: (state, { payload }) => {
      return {
        ...state,
        entitiesAreFetching: payload,
      };
    },
    setSkillName: (state, { payload }) => {
      return {
        ...state,
        name: payload,
      };
    },
    setSkillDescription: (state, { payload }) => {
      return {
        ...state,
        description: payload,
      };
    },
    setMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setSnackBar: (state, { payload }) => {
      return {
        ...state,
        snackBar: payload,
      };
    },
    setSnackBarError: (state, { payload }) => {
      return {
        ...state,
        snackBarError: payload,
      };
    },
    setShowForm: (state, { payload }) => {
      return {
        ...state,
        showForm: payload,
      };
    },
    setShowButtonsForm: (state, { payload }) => {
      return {
        ...state,
        showButtonsForm: payload,
      };
    },
    setName: (state, { payload }) => {
      return {
        ...state,
        name: payload,
      };
    },
    setDescription: (state, { payload }) => {
      return {
        ...state,
        description: payload,
      };
    },
    setDeleteModal: (state, { payload }) => {
      return {
        ...state,
        deleteModal: payload,
      };
    },
    setSkill: (state, { payload }) => {
      return {
        ...state,
        skill: payload,
      };
    },
    setSkillToEdit: (state, { payload }) => {
      return {
        ...state,
        skillToEdit: payload,
      };
    },
    clearTranslateInputs: (state) => ({
      ...state,
      name: '',
      description: '',
      skillToEdit: undefined,
    }),
    setFilters: (state, { payload }) => {
      return {
        ...state,
        filters: payload,
      };
    },
    setShowPagination: (state, { payload }) => {
      return {
        ...state,
        showPagination: payload,
      };
    },
    setShowAddBtn: (state, { payload }) => {
      return {
        ...state,
        showAddBtn: payload,
      };
    },
    setShowFormTechPS: (state, { payload }) => {
      return {
        ...state,
        showFormTechPS: payload,
      };
    },
    setShowEditFormTechPS: (state, { payload }) => {
      return {
        ...state,
        showEditFormTechPS: payload,
      };
    },
    setwSkillToEditId: (state, { payload }) => {
      return {
        ...state,
        wSkillToEditId: payload,
      };
    },
    setShowFormSoftPS: (state, { payload }) => {
      return {
        ...state,
        showFormSoftPS: payload,
      };
    },
    setShowFormAwsPS: (state, { payload }) => {
      return {
        ...state,
        showFormAwsPS: payload,
      };
    },
    setShowFormToolsPS: (state, { payload }) => {
      return {
        ...state,
        showFormToolsPS: payload,
      };
    },
    setFiltersActive: (state: any, { payload }: any) => {
      return {
        ...state,
        filtersActive: payload,
      };
    },
    setTakenSkillName: (state, { payload }) => {
      return {
        ...state,
        takenSkillName: payload,
      };
    },
    setCreateLevels: (state, { payload }) => {
      return {
        ...state,
        createLevels: payload,
      };
    },
  },
});
