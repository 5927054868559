import { Dispatch } from 'redux';
import { RootState } from '..';
import translationsService from '../../services/translations.service';
import { handleErrors } from '../Auth/auth.actions';
import translationsSlice from './translationsSlice';

export const translateEntity = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const entityToTranslate: Object = getState().translations.entityToTranslate;
    dispatch(translationsSlice.actions.requestTranslation(true));
    const getResponse = await translationsService
      .translateEntity(entityToTranslate)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(translationsSlice.actions.saveTranslatedEntity(getResponse));
    dispatch(translationsSlice.actions.saveEntityToTranslate({}));
    dispatch(translationsSlice.actions.requestTranslation(false));
  };
};

export const saveEntityToTranslate = (entityToTranslate: Object) => {
  return (dispatch: Dispatch) => {
    dispatch(translationsSlice.actions.saveEntityToTranslate(entityToTranslate));
  };
};

export const setAppTranslation = (lang?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(translationsSlice.actions.requestTranslation(true));

    if (lang) {
      const response = await translationsService.getTranslationByLang(lang).catch(() => {
        handleErrors(500, dispatch);
      });
      if (!response) return;
      handleErrors(response.statusCode, dispatch);
      dispatch(translationsSlice.actions.setAppTranslation(response));
    }

    dispatch(translationsSlice.actions.requestTranslation(false));
  };
};
