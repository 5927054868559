import React from 'react';
//MUI
import Box from '@mui/system/Box';
import { TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
//Styles
import '../styles.scss';
//Components
import KnowledgeAlert from '../../../commons/KnowledgeAlert/KnowledgeAlert';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { setShowSnackBar } from '../../../store/Auth/auth.actions';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import KnowledgeButton from '../../../commons/KnowledgeButton/KnowledgeButton';

interface LinkStateProps {}
interface LinkDispatchProps {}

const NewUser: React.FC<any> = (props) => {
  const { show, email, createUser, showSnackBar, message, setShowSnackBar } = props;

  const [gId, setGId] = React.useState<string>('');
  const FALSE_PROP: boolean = false;

  const handleCreateUser = async () => {
    await createUser({
      createdBy: email,
      googleId: gId,
    });
  };

  const canSubmit = () => {
    return gId.length !== 21;
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!!message && (
        <KnowledgeAlert
          header={message.header}
          severity={message.type}
          openSnackBar={showSnackBar}
          closeSnackBar={() => setShowSnackBar(FALSE_PROP)}
          message={message.text}
        />
      )}
      <Typography
        sx={{
          fontFamily: 'Source Sans Pro',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '20px',
          textAlign: 'center',
          letterSpacing: '0.02em',
          marginTop: '40px',
        }}
      >
        Escribe el Google ID del usuario que deseas agregar al sistema.
      </Typography>

      <TextField
        label="Google ID"
        defaultValue=""
        sx={{ width: '410px', alignSelf: 'center', height: '52px', mt: '24px' }}
        onChange={(e) => {
          setGId(e.target.value);
        }}
        type="number"
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '260px',
          alignSelf: 'center',
          mt: '40px',
          height: '42px',
        }}
      >
        <KnowledgeButton type="primary" variant="outlined" onClick={() => show(false)}>
          CANCELAR{' '}
        </KnowledgeButton>

        {!canSubmit() ? (
          <Button
            sx={{
              borderRadius: 2,
              height: '44px',
              fontWeight: '600',
              minWidth: '85px',
              lineHeight: '2px',
            }}
            variant="contained"
            onClick={() => {
              handleCreateUser();
            }}
          >
            AGREGAR
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled
            sx={{
              borderRadius: 2,
              height: '44px',
              fontWeight: '600',
              minWidth: '85px',
              lineHeight: '2px',
            }}
          >
            AGREGAR
          </Button>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { showSnackBar, message } = state.auth;

  return { showSnackBar, message };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  setShowSnackBar: bindActionCreators(setShowSnackBar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
