import { Dispatch } from 'redux';
import { RootState } from '..';
import skillsService from '../../services/skills.service';
import { Skill, SkillType, LevelDescription } from './skills.type';
import skillsSlice from './skillsSlice';
import { INITIAL_SKILLS_COUNT, INITIAL_SKILLS_PAGE } from '../../helpers/constants';
import { handleErrors } from '../Auth/auth.actions';
import weightedSkillsService from '../../services/weightedSkills.service';

//NEW METHODS

export const getAllSkills = () => {
  return async (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.requestSkills(true));

    const getResponse = await skillsService.getAllSkills().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(skillsSlice.actions.getAllSkills(getResponse.data));
    dispatch(skillsSlice.actions.requestSkills(false));
  };
};

export const getCategories = () => {
  return async (dispatch: Dispatch) => {
    const getResponse = await skillsService.getCategories().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    dispatch(skillsSlice.actions.setCategories(getResponse.data));
  };
};

export const getKinds = () => {
  return async (dispatch: Dispatch) => {
    const getResponse = await skillsService.getKinds().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    dispatch(skillsSlice.actions.setKinds(getResponse.data));
  };
};

export const getTopSkills = () => {
  return async (dispatch: Dispatch) => {
    const getResponse = await skillsService.getTopSkills().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    dispatch(skillsSlice.actions.setTopSkills(getResponse.data));
  };
};

//TODO REVIEW OLD METHODS

const setupArgs = (lastObject?: any, newObject?: any) => {
  return {
    page:
      (newObject && newObject.page) ||
      (lastObject && lastObject.page) ||
      INITIAL_SKILLS_PAGE,
    count:
      (newObject && newObject.count) ||
      (lastObject && lastObject.count) ||
      INITIAL_SKILLS_COUNT,
    filters: (newObject && newObject.filters) || (lastObject && lastObject.filters) || [],
  };
};

export const getSkills = (
  filters?: any[],
  page: number = INITIAL_SKILLS_PAGE,
  count: number = INITIAL_SKILLS_COUNT
) => {
  return async (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.requestSkills(true));
    const filtersObj = setupArgs({ page, count, filters });
    const getResponse = await skillsService
      .getSkills(filtersObj.filters, filtersObj.page, filtersObj.count)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(
      skillsSlice.actions.getSkills({
        data: getResponse.data,
        filters: filtersObj.filters,
        page: getResponse.page,
        count: getResponse.count,
        pageCount: getResponse.pageCount,
      })
    );
    dispatch(skillsSlice.actions.requestSkills(false));
    dispatch(skillsSlice.actions.setName(''));
    dispatch(skillsSlice.actions.setDescription(''));
  };
};

export const setTakenSkillName = (status: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setTakenSkillName(status));
  };
};

export const addSkill = (
  levelDescriptions: any[],
  skillType: SkillType,
  skillLevels: number = 0
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(skillsSlice.actions.requestSkills(true));
    dispatch(skillsSlice.actions.setShowPagination(false));
    const name: string | undefined = getState().skills.name;
    const description: string | undefined = getState().skills.description;
    const page = INITIAL_SKILLS_PAGE;
    const count = INITIAL_SKILLS_COUNT;
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.SKILLS.createSkill;

    const response = await skillsService
      .addSkill({
        name: name ? name : '',
        description: description ? description : '',
        type: skillType,
        levelsModel: skillLevels ? skillLevels : undefined,
      })
      .catch((e) => {
        handleErrors(500, dispatch);
      });
    if (!response) return;
    handleErrors(response.statusCode, dispatch);
    if (response.statusCode === 400) {
      dispatch(skillsSlice.actions.setTakenSkillName(true));
      dispatch(skillsSlice.actions.requestSkills(false));
    } else {
      let descriptions: LevelDescription[] | undefined = levelDescriptions.filter(
        (lvl, i) => i < skillLevels && lvl
      );
      descriptions = descriptions.map((lvl) => ({ ...lvl, skill: { id: response.id } }));
      const levelsResponse = await skillsService
        .addLevelDescriptions(descriptions)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!levelsResponse) return;
      handleErrors(levelsResponse.statusCode, dispatch);
      const filtersObj = setupArgs({ filters: getState().skills.filters, page, count });
      const getResponse = await skillsService
        .getSkills(getState().skills.filters, filtersObj.page, filtersObj.count)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(skillsSlice.actions.setSnackBar(true));
      dispatch(
        skillsSlice.actions.setMessage({
          type: 'success',
          text:
            skillType === 'TOOLS'
              ? !!appTranslation
                ? appTranslation.tools.alertMessages.successfulAdd + '.'
                : 'Se ha cargado una nueva herramienta.'
              : !!appTranslation
              ? appTranslation.skills.alertMessages.successfulAdd + '.'
              : 'Se ha cargado una nueva habilidad.',
          header: !!appTranslation
            ? appTranslation.tools.alertMessages.headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      dispatch(
        skillsSlice.actions.getSkills({
          data: getResponse.data,
          page: getResponse.page,
          count: getResponse.count,
          pageCount: getResponse.pageCount,
          filters: filtersObj.filters,
        })
      );

      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(skillsSlice.actions.setShowForm(false));
      dispatch(skillsSlice.actions.setShowPagination(true));
      dispatch(skillsSlice.actions.setName(''));
      dispatch(skillsSlice.actions.setDescription(''));
      dispatch(skillsSlice.actions.requestSkills(false));
    }
    dispatch(skillsSlice.actions.requestSkills(false));
  };
};

export const editSkill = (
  skillType: SkillType,
  levelDescriptions: any[],
  skillLevels: number = 0
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(skillsSlice.actions.requestSkills(true));
    dispatch(skillsSlice.actions.setShowPagination(false));
    const page = INITIAL_SKILLS_PAGE;
    const count = INITIAL_SKILLS_COUNT;
    const id: number | undefined = getState().skills.skillToEdit?.id;
    const name: string | undefined = getState().skills.name;
    const description: string | undefined = getState().skills.description;
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.SKILLS.createSkill;
    const response = await skillsService
      .editSkill({
        id,
        name: name ? name : '',
        description: description ? description : '',
        type: skillType,
      })
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!response) return;
    handleErrors(response.statusCode, dispatch);
    if (response.statusCode === 400) {
      dispatch(skillsSlice.actions.setTakenSkillName(true));
    } else {
      const filtersObj = setupArgs({ page, count, filters: getState().skills.filters });
      const getResponse = await skillsService
        .getSkills(filtersObj.filters, filtersObj.page, filtersObj.count)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(skillsSlice.actions.setSnackBar(true));
      dispatch(
        skillsSlice.actions.setMessage({
          type: 'success',
          text:
            skillType === 'TOOLS'
              ? !!appTranslation
                ? appTranslation.tools.alertMessages.successfulEdit + '.'
                : 'Se ha actualizado la herramienta.'
              : !!appTranslation
              ? appTranslation.skills.alertMessages.successfulEdit + '.'
              : 'Se ha actualizado la habilidad.',
          header: !!appTranslation
            ? appTranslation.tools.alertMessages.headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      dispatch(
        skillsSlice.actions.getSkills({
          data: getResponse.data,
          page: getResponse.page,
          count: getResponse.count,
          pageCount: getResponse.pageCount,
          filters: filtersObj.filters,
        })
      );
      let descriptions: any[] = levelDescriptions.filter(
        (lvl, i) => i < skillLevels && lvl
      );
      descriptions = descriptions.map((lvl) => ({ ...lvl, skill: { id } }));
      const levelsResponse = await skillsService
        .editLevelDescriptions(descriptions)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!levelsResponse) return;
      handleErrors(levelsResponse.statusCode, dispatch);
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(skillsSlice.actions.setShowForm(false));
      dispatch(skillsSlice.actions.clearTranslateInputs());
      dispatch(skillsSlice.actions.setShowPagination(true));
    }
    dispatch(skillsSlice.actions.requestSkills(false));
  };
};

export const deleteSkill = (skillType: SkillType) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const skill: Skill | undefined = getState().skills.skill;
    const page = INITIAL_SKILLS_PAGE;
    const count = INITIAL_SKILLS_COUNT;
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.SKILLS.createSkill;
    dispatch(skillsSlice.actions.requestSkills(true));
    if (skill) {
      const levelDescriptionsDeleted = await skillsService
        .deleteLevelDescriptions(skill)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!levelDescriptionsDeleted) return;
      handleErrors(levelDescriptionsDeleted.statusCode, dispatch);
      const getLevelDescriptions = await skillsService
        .getLevelDescriptions()
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getLevelDescriptions) return;
      handleErrors(getLevelDescriptions.statusCode, dispatch);

      const skillsResponse = await skillsService.deleteSkill(skill).catch(() => {
        handleErrors(500, dispatch);
      });
      if (!skillsResponse) return;
      handleErrors(skillsResponse.statusCode, dispatch);
      dispatch(skillsSlice.actions.setSnackBar(true));
      dispatch(
        skillsSlice.actions.setMessage({
          type: 'success',
          text:
            skillType === 'TOOLS'
              ? !!appTranslation
                ? appTranslation.tools.alertMessages.successfulDelete + '.'
                : 'Se ha eliminado la herramienta.'
              : !!appTranslation
              ? appTranslation.skills.alertMessages.successfulDelete + '.'
              : 'Se ha eliminado la habilidad.',
          header: !!appTranslation
            ? appTranslation.tools.alertMessages.headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      const filtersObj = setupArgs({ page, count, filters: getState().skills.filters });
      const getResponse = await skillsService
        .getSkills(filtersObj.filters, filtersObj.page, filtersObj.count)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(
        skillsSlice.actions.getSkills({
          data: getResponse.data,
          page: getResponse.page,
          count: getResponse.count,
          pageCount: getResponse.pageCount,
          filters: filtersObj.filters,
        })
      );
      dispatch(skillsSlice.actions.setDeleteModal(false));
    }
    dispatch(skillsSlice.actions.requestSkills(false));
  };
};

export const setSnackBar = (open: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setSnackBar(open));
  };
};

export const setShowForm = (showForm: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowForm(showForm));
  };
};

export const setFiltersActive = (filtersActive: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setFiltersActive(filtersActive));
  };
};

export const setName = (name: string) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setName(name));
  };
};

export const setDescription = (description: string) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setDescription(description));
  };
};

export const handleDeleteModal = (open: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setDeleteModal(open));
  };
};

export const setSkill = (skill: Skill) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setSkill(skill));
  };
};

export const clearTranslateInputs = () => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.clearTranslateInputs());
  };
};

export const setSkillToEdit = (skill?: Skill) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setSkillToEdit(skill));
  };
};

export const setFilters = (filters: any[]) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setFilters(filters));
  };
};

export const setShowPagination = (showPagination: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowPagination(showPagination));
  };
};

export const setShowAddBtn = (showAddBtn: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowAddBtn(showAddBtn));
  };
};
export const setShowFormSoftPS = (showFormSoftPS: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowFormSoftPS(showFormSoftPS));
  };
};

export const setShowFormAwsPS = (showFormAwsPS: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowFormAwsPS(showFormAwsPS));
  };
};
export const setShowFormTechPS = (showFormTechPS: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowFormTechPS(showFormTechPS));
  };
};
export const setShowEditFormTechPS = (showEditFormTechPS: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowEditFormTechPS(showEditFormTechPS));
  };
};

export const setwSkillToEditId = (wSkillToEditId: number | null) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setwSkillToEditId(wSkillToEditId));
  };
};

export const changeWskillLevel = (weightedSkillId: number, newLevel: any) => {
  return async () => {
    return await weightedSkillsService.updateWskillLevel(weightedSkillId, newLevel);
  };
};

export const setShowFormToolsPS = (showFormToolsPS: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowFormToolsPS(showFormToolsPS));
  };
};
export const cancelAddSkill = () => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setDescription(''));
    dispatch(skillsSlice.actions.setName(''));
    dispatch(skillsSlice.actions.setShowForm(false));
    dispatch(skillsSlice.actions.setShowPagination(true));
  };
};

export const setShowButtonsForm = (showButtonsForm: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(skillsSlice.actions.setShowButtonsForm(showButtonsForm));
  };
};
