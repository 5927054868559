import { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import { MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { RootState } from '../../store/root-reducer';
import { ThunkDispatch } from 'redux-thunk';
import '../../index.css';
import './sideMenu.scss';
import { useNavigate } from 'react-router-dom';
import { setPathsToShow, setShowSideMenu } from '../../store/routes/routes.actions';
import { bindActionCreators } from 'redux';
import {
  clearInputs,
  setShowAddBtn,
  setShowForm,
} from '../../store/PreviousExperience/prevExperience.actions';

type SideMenuProps = LinkStateProps & LinkDispatchProps;

const SideMenu: FC<SideMenuProps> = (props: SideMenuProps) => {
  const { setPathsToShow, pathsToShow } = props;

  const history: any = useNavigate();

  return (
    <div>
      <Drawer
        variant={'persistent'}
        open={props.showSideMenu}
        onClose={() => props.setShowSideMenu(false)}
        sx={{
          minWidth: '200px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            minWidth: '200px',
            background: '#5F6467',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List sx={{ pt: '11px', paddingTop: '20px' }}>
            {pathsToShow.length > 0
              ? pathsToShow.map((item: any) => (
                  <MenuItem
                    key={item.description}
                    selected={item.isActive}
                    onClick={() => {
                      history(`/${item.path}`);
                      setPathsToShow({ pathname: `/${item.path}` });
                    }}
                    sx={{
                      mb: '7px',
                      paddingRight: '20px',
                      '&& .Mui-selected': {
                        backgroundColor: 'rgba(57, 62, 66, 0.3)',
                      },
                    }}
                    className={item.isActive && 'menuItem_selected'}
                  >
                    <ListItemIcon style={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
                    <Typography
                      sx={{
                        fontFamily: 'Source Sans Pro',
                        color: 'var(--DinoNeutral07)',
                        fontSize: '16px',
                        lineHeight: '18px',
                        '&:focus': {
                          fontWeight: '600',
                        },
                      }}
                    >
                      {item.description}
                    </Typography>
                  </MenuItem>
                ))
              : null}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

interface LinkStateProps {
  pathsToShow: [];
  entitiesIsLoading: boolean;
  showSideMenu: boolean;
}

interface LinkDispatchProps {
  setPathsToShow: (location: any, defaultItems?: any[]) => void;
  setShowForm: (showForm: boolean) => void;
  clearInputs: () => void;
  setShowAddBtn: (showAddBtn: boolean) => void;
  setShowSideMenu: (showSideMenu: boolean) => void;
}

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { pathsToShow, entitiesIsLoading, showSideMenu } = state.routes;
  return {
    pathsToShow,
    entitiesIsLoading,
    showSideMenu,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  setPathsToShow: bindActionCreators(setPathsToShow, dispatch),
  setShowForm: bindActionCreators(setShowForm, dispatch),
  clearInputs: bindActionCreators(clearInputs, dispatch),
  setShowAddBtn: bindActionCreators(setShowAddBtn, dispatch),
  setShowSideMenu: bindActionCreators(setShowSideMenu, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
