import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import './KnowledgeContainer.scss';

interface Props {
  children: ReactNode;
}

interface LinkStateProps {
  showSideMenu: boolean;
}

type KnowledgeContainerProps = LinkStateProps & Props;

const KnowledgeContainer: FC<KnowledgeContainerProps> = (
  props: KnowledgeContainerProps
) => {
  const { children, showSideMenu } = props;
  return (
    <div
      className={
        showSideMenu
          ? 'knowledgePageContainer__container-openedMenu'
          : 'knowledgePageContainer__container-closedMenu'
      }
    >
      {children}
    </div>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { showSideMenu } = state.routes;

  return {
    showSideMenu,
  };
};

export default connect(mapStateToProps)(KnowledgeContainer);
