import { Dispatch } from 'redux';
import { RootState } from '..';
import routesSlice from './routesSlice';

export const setShowSideMenu = (showSideMenu: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(routesSlice.actions.setShowSideMenu(showSideMenu));
  };
};

export const setCurrentPath = (path: string) => {
  return (dispatch: Dispatch) => {
    dispatch(routesSlice.actions.setCurrentPath(path));
  };
};

export const setDefaultItems = (defaultItems: any[]) => {
  return (dispatch: Dispatch) => {
    dispatch(routesSlice.actions.setDefaultItems(defaultItems));
  };
};

export const setPathsToShow = (location: any, defItems?: any[]) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    let defaultItems: any[] = [];
    if (defItems) {
      defaultItems = defItems;
    } else {
      defaultItems = getState().routes.defaultItems;
    }
    let currentPath: string = getState().routes.currentPath;
    let newPathsItemToShow: any[] = [];
    let newDefaultItems: any[] = [];
    defaultItems.forEach((di: any) => {
      if (di.patternPath === currentPath) {
        di.children.forEach((p: any) => {
          if (location.pathname.startsWith('/' + p.path)) {
            let diAux: any = {
              ...p,
              isActive: true,
            };
            newPathsItemToShow.push(diAux);
          } else {
            let diAux: any = {
              ...p,
              isActive: false,
            };
            newPathsItemToShow.push(diAux);
          }
        });
        dispatch(routesSlice.actions.setActionButton(di.actionButton));
        newDefaultItems.push({
          ...di,
          children: newPathsItemToShow,
        });
      } else {
        newDefaultItems.push(di);
      }
    });
    dispatch(routesSlice.actions.setPathsToShow(newPathsItemToShow));
    dispatch(routesSlice.actions.setDefaultItems(newDefaultItems));
  };
};
