import { Grid } from '@mui/material';
import { FC } from 'react';
import {
  uppercaseFirstLetterEveryWord,
  uppercaseFirstLetterFirstWord,
} from '../../../../../../../../helpers/formattingFunctions';
import { Collaborator1 } from '../../../../../../../../store/Collaborators/collaborators.type';
import '../../ProfileTabStyle.scss';
// import './InfoSection.scss';

interface PersonalInfoProps {
  collaborator: Collaborator1;
  appTranslation?: any;
}

const PersonalInfo: FC<PersonalInfoProps> = (props: PersonalInfoProps) => {
  const fullname = props.collaborator
    ? `${props.collaborator!.firstName} ${props.collaborator!.lastName}`
    : '';

  return (
    <Grid
      container
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <Grid item md={6}>
        <h2>{props.appTranslation ? props.appTranslation.FULL_NAME + ':' : 'Name:'}</h2>
        <h3>
          {/* {!!props.collaborator?.fullname
            ? uppercaseFirstLetterEveryWord(props.collaborator.fullname)
            : '-'} */}
          {fullname}
        </h3>
        <h2>Last Name:</h2>
        <h3>
          {!!props.collaborator?.lastName
            ? uppercaseFirstLetterEveryWord(props.collaborator.lastName)
            : '-'}
        </h3>
        <h2>Date of Birth</h2>
        <h3>
          {!!props.collaborator?.birthDate
            ? props.collaborator.birthDate.slice(0, 10)
            : '-'}
        </h3>
        <h2> Nationality:</h2>
        <h3>
          {!!props.collaborator?.nationality
            ? uppercaseFirstLetterEveryWord(props.collaborator.nationality)
            : '-'}
        </h3>
        <h2>Province</h2>
        <h3>
          {!!props.collaborator?.province
            ? uppercaseFirstLetterEveryWord(props.collaborator.province)
            : '-'}
        </h3>
        <h2>Personal Email</h2>
        <h3>
          {!!props.collaborator?.personalEmail ? props.collaborator.personalEmail : '-'}
        </h3>
      </Grid>
      <Grid item md={6}>
        <h2>Phone Number:</h2>
        <h3>{!!props.collaborator?.phone ? props.collaborator.phone : '-'}</h3>
        <h2>Adress</h2>
        <h3 style={{ whiteSpace: 'initial' }}>
          {!!props.collaborator?.address
            ? uppercaseFirstLetterFirstWord(props.collaborator.address)
            : '-'}
        </h3>
        <h2> Emergency Contact:</h2>
        <h3>
          {!!props.collaborator?.emergencyContact
            ? props.collaborator.emergencyContact
            : '-'}
        </h3>
        <h2>Marital Status</h2>
        <h3>
          {!!props.collaborator?.civilStatus ? props.collaborator?.civilStatus : '-'}
        </h3>
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
