import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_PROJECTS_PAGE } from '../../helpers/constants';
import { ClientState } from './clients.type';

const initialState: ClientState = {
  clients: [],
  industry: '',
  name: '',
  entityIsLoading: false,
  showForm: false,
  message: undefined,
  snackBar: false,
  snackBarError: false,
  entitiesAreFetching: false,
  deleteModal: false,
  pageCount: INITIAL_PROJECTS_PAGE,
  showPagination: true,
  showEditingForm: false,
  filtersActive: false,
};

export default createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClients: (state, { payload }) => {
      const { data, page, count, pageCount, filters } = payload;
      return {
        ...state,
        clients: data,
        page,
        filters,
        pageCount,
        count,
      };
    },

    setName: (state, { payload }) => ({
      ...state,
      name: payload,
    }),
    setIndustry: (state, { payload }) => ({
      ...state,
      industry: payload,
    }),

    setLoading: (state, { payload }) => ({
      ...state,
      entityIsLoading: payload,
    }),
    requestClients: (state, { payload }) => {
      return {
        ...state,
        entitiesAreFetching: payload,
      };
    },
    setMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setSnackBar: (state, { payload }) => {
      return {
        ...state,
        snackBar: payload,
      };
    },
    setSnackBarError: (state, { payload }) => {
      return {
        ...state,
        snackBarError: payload,
      };
    },
    setShowForm: (state, { payload }) => {
      return {
        ...state,
        showForm: payload,
      };
    },
    setDeleteModal: (state, { payload }) => {
      return {
        ...state,
        deleteModal: payload,
      };
    },
    setShowPagination: (state, { payload }) => {
      return {
        ...state,
        showPagination: payload,
      };
    },
    clearInputs: (state) => ({
      ...state,
      name: '',
      industry: '',
    }),
    setClient: (state, { payload }) => {
      return {
        ...state,
        client: payload,
      };
    },
    setShowEditingForm: (state, { payload }) => ({
      ...state,
      showEditingForm: payload,
    }),
    setClientToEdit: (state, { payload }) => {
      return {
        ...state,
        clientToEdit: payload,
      };
    },
    setFiltersActive: (state: any, { payload }: any) => {
      return {
        ...state,
        filtersActive: payload,
      };
    },
  },
});
