import { Dispatch } from 'redux';
import { RootState } from '..';
import prevExperienceService from '../../services/prevExperiences.service';
import prevExperienceSlice from './prevExperienceSlice';
import moment from 'moment';
import { PreviousExperience } from './prevExperience.type';
import collaboratorsSlice from '../Collaborators/collaboratorsSlice';
import collaboratorService from '../../services/collaborator.service';
import { uppercaseFirstLetterFirstWord } from '../../helpers/formattingFunctions';
import { handleErrors } from '../Auth/auth.actions';

export const addPrevExperience = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(prevExperienceSlice.actions.setLoading(true));
    const rol: string = getState().prevExperiences.rol;
    const company: string = getState().prevExperiences.company;
    const industry: string | undefined = getState().prevExperiences.industry;
    const type: string = getState().prevExperiences.type;
    const description: string | undefined = getState().prevExperiences.description;
    const seniority: string = getState().prevExperiences.seniority;
    const startDate: Date | null = getState().prevExperiences.startDate;
    const finishDate: Date | null = getState().prevExperiences.finishDate;
    const collaborator: number = getState().collaborators.collaborator?.id!;
    const appTranslation: any = getState().translations.appTranslation;

    const removeExtraSpace = (text: string) => {
      let textWithoutSpaces = text.trim().split(/ +/).join(' ');
      return textWithoutSpaces;
    };

    const dateFormat = 'DD-MM-YYYY';
    const cleanedRol = uppercaseFirstLetterFirstWord(removeExtraSpace(rol));
    const cleanedCompany = uppercaseFirstLetterFirstWord(removeExtraSpace(company));
    const cleanedStartDate = moment(startDate).format(dateFormat);
    const cleanedFinishDate = moment(finishDate).format(dateFormat);
    let startDateToCompare = new Date(startDate!);
    let finishDateToCompare = new Date(finishDate!);

    if (finishDateToCompare < startDateToCompare) {
      dispatch(prevExperienceSlice.actions.setShowSnackBar(true));
      dispatch(
        prevExperienceSlice.actions.setMessage({
          type: 'error',
          text: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages.error
                .dates.START_END + '.'
            : 'la fecha de finalización no puede ser anterior a la fecha de inicio.',
          header: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .headers.ACTION_DENIED
            : 'Acción denegada',
        })
      );
      dispatch(prevExperienceSlice.actions.setLoading(false));
    } else {
      const preExpResponse = await prevExperienceService
        .addPrevExperience({
          rol: cleanedRol,
          company: cleanedCompany,
          type,
          seniority,
          description,
          industry,
          startDate: cleanedStartDate,
          finishDate: cleanedFinishDate,
          collaborator,
        })
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!preExpResponse) return;
      handleErrors(preExpResponse.statusCode, dispatch);
      dispatch(prevExperienceSlice.actions.setShowSnackBar(true));
      dispatch(
        prevExperienceSlice.actions.setMessage({
          type: 'success',
          text: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .successful.confirm + '.'
            : 'se ha cargado nueva información.',
          header: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      dispatch(prevExperienceSlice.actions.clearInputs());
      dispatch(prevExperienceSlice.actions.setShowForm(false));
      dispatch(prevExperienceSlice.actions.setShowAddBtn(true));
      const getResponse = await collaboratorService
        .getCollaboratorsById(getState().collaborators.collaborator?.id)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(collaboratorsSlice.actions.setCollaborator(getResponse));
      dispatch(prevExperienceSlice.actions.setLoading(false));
    }
  };
};

export const deletePrevExperience = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const prevExperience: PreviousExperience | undefined =
      getState().prevExperiences.prevExperience;
    dispatch(prevExperienceSlice.actions.setLoading(true));

    const appTranslation: any = getState().translations.appTranslation;

    if (prevExperience) {
      const preExpResponse = await prevExperienceService
        .deletePrevExperience(prevExperience)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!preExpResponse) return;
      handleErrors(preExpResponse.statusCode, dispatch);
      dispatch(prevExperienceSlice.actions.setShowSnackBar(true));
      dispatch(
        prevExperienceSlice.actions.setMessage({
          type: 'success',
          text: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .successful.delete + '.'
            : 'se ha eliminado información.',
          header: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      const getResponse = await collaboratorService
        .getCollaboratorsById(getState().collaborators.collaborator?.id)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(collaboratorsSlice.actions.setCollaborator(getResponse));
      dispatch(prevExperienceSlice.actions.setDeleteModal(false));
      dispatch(prevExperienceSlice.actions.setPrevExperience(null));
    }
    dispatch(prevExperienceSlice.actions.setLoading(false));
  };
};

export const editPrevExperiences = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const id: number | undefined = getState().prevExperiences.prevExperienceToEdit?.id;
    dispatch(prevExperienceSlice.actions.setLoading(true));
    const rol: string = getState().prevExperiences.rol;
    const company: string = getState().prevExperiences.company;
    const description: string | undefined = getState().prevExperiences.description;
    const industry: string | undefined = getState().prevExperiences.industry;
    const type: string = getState().prevExperiences.type;
    const seniority: string = getState().prevExperiences.seniority;
    const startDate: Date | null = getState().prevExperiences.startDate;
    const finishDate: Date | null = getState().prevExperiences.finishDate;
    const collaborator: number = getState().collaborators.collaborator?.id!;
    const appTranslation: any = getState().translations.appTranslation;

    const removeExtraSpace = (text: string) => {
      let textWithoutSpaces = text.trim().split(/ +/).join(' ');
      return textWithoutSpaces;
    };

    const dateFormat = 'DD-MM-YYYY';
    const cleanedRol = uppercaseFirstLetterFirstWord(removeExtraSpace(rol));
    const cleanedCompany = uppercaseFirstLetterFirstWord(removeExtraSpace(company));
    const cleanedStartDate = moment(startDate).format(dateFormat);
    const cleanedFinishDate = moment(finishDate).format(dateFormat);
    let startDateToCompare = new Date(startDate!);
    let finishDateToCompare = new Date(finishDate!);

    if (finishDateToCompare < startDateToCompare) {
      dispatch(prevExperienceSlice.actions.setShowSnackBar(true));
      dispatch(
        prevExperienceSlice.actions.setMessage({
          type: 'error',
          text: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages.error
                .dates.START_END + '.'
            : 'la fecha de finalización no puede ser anterior a la fecha de inicio.',
          header: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .headers.ACTION_DENIED
            : 'Acción denegada',
        })
      );
      dispatch(prevExperienceSlice.actions.setLoading(false));
    } else {
      const preExpResponse = await prevExperienceService
        .editPrevExperiences({
          id,
          rol: cleanedRol,
          company: cleanedCompany,
          type,
          seniority,
          description,
          industry,
          startDate: cleanedStartDate,
          finishDate: cleanedFinishDate,
          collaborator,
        })
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!preExpResponse) return;
      handleErrors(preExpResponse.statusCode, dispatch);
      dispatch(prevExperienceSlice.actions.setShowSnackBar(true));
      dispatch(
        prevExperienceSlice.actions.setMessage({
          type: 'success',
          text: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .successful.edit + '.'
            : 'se ha editado la información.',
          header: !!appTranslation
            ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
                .headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      dispatch(prevExperienceSlice.actions.clearInputs());
      dispatch(prevExperienceSlice.actions.setPrevExperienceToEdit(null));
      dispatch(prevExperienceSlice.actions.setShowForm(false));
      dispatch(prevExperienceSlice.actions.setShowAddBtn(true));
      const getResponse = await collaboratorService
        .getCollaboratorsById(getState().collaborators.collaborator?.id)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(collaboratorsSlice.actions.setCollaborator(getResponse));
      dispatch(prevExperienceSlice.actions.setLoading(false));
    }
  };
};

export const setSnackbar = (showSnackBar: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setShowSnackBar(showSnackBar));
  };
};

export const setSnackBarError = (open: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setSnackBarError(open));
  };
};

export const setShowForm = (showForm: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setShowForm(showForm));
    dispatch(prevExperienceSlice.actions.setShowAddBtn(false));
  };
};

export const setRol = (rol: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setRol(rol));
  };
};

export const setCompany = (company: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setCompany(company));
  };
};
export const setType = (type: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setType(type));
  };
};

export const setSeniority = (seniority: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setSeniority(seniority));
  };
};

export const setStartDate = (startDate: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setStartDate(startDate));
  };
};
export const setDescription = (description: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setDescription(description));
  };
};
export const setIndustry = (industry: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setIndustry(industry));
  };
};

export const setFinishDate = (finishDate: string) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setFinishDate(finishDate));
  };
};

export const cancelAddPrevExperience = () => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.clearInputs());
    dispatch(prevExperienceSlice.actions.setShowForm(false));
    dispatch(prevExperienceSlice.actions.setShowAddBtn(true));
    dispatch(prevExperienceSlice.actions.setPrevExperience(null));
    dispatch(prevExperienceSlice.actions.setPrevExperienceToEdit(null));
  };
};

export const handleDeleteModal = (open: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setDeleteModal(open));
  };
};

export const setPrevExperience = (prevExperience: PreviousExperience) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setPrevExperience(prevExperience));
  };
};
export const setPrevExperienceToEdit = (prevExperience: PreviousExperience) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setPrevExperienceToEdit(prevExperience));
  };
};
export const setEntityIsLoading = () => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setLoading(true));
  };
};

export const clearInputs = () => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.clearInputs());
  };
};
export const setShowAddBtn = (showAddBtn: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(prevExperienceSlice.actions.setShowAddBtn(showAddBtn));
  };
};
