const AppRoutes = {
  login: {
    path: 'login',
  },
  collaborators: {
    path: 'collaborators',
    professionalServices: {
      fullPath: 'collaborators/professional-services',
      path: 'professional-services',
      id: ':id',
      create: 'create',
    },
    staffingService: {
      path: 'staffing-service',
    },
  },
  skills: {
    path: 'skills',
    techSkills: {
      fullPath: 'skills/tech-skills',
      path: 'tech-skills',
    },
    softSkills: 'soft-skills',
    AWSSkills: 'AWS-skills',
    tools: 'tools',
  },
  projects: {
    fullPath: 'projects/projects',
    path: 'projects',
    projects: 'projects',
  },
  clients: {
    fullPath: 'clients/clients',
    path: 'clients',
    clients: 'clients',
  },
  settings: {
    fullPath: 'settings/settings',
    path: 'settings',
    settings: 'settings',
  },
  common: {
    goBack: -1,
  },
};

export default AppRoutes;
