import InfoSection from './components/InfoSection/InfoSection';
import SkillsSlider from './components/SkillsSlider/SkillsSlider';
import WorkHistory from './components/WorkHistory/WorkHistory';
import './ProfileTabStyle.scss';
import { useEffect } from 'react';

const ProfileTab = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SkillsSlider />
      <InfoSection />
      <WorkHistory />
    </>
  );
};

export default ProfileTab;
