/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch } from 'redux';
import { RootState } from '..';
import { INITIAL_CLIENTS_COUNT, INITIAL_CLIENTS_PAGE } from '../../helpers/constants';
import clientsService from '../../services/clients.service';
import { handleErrors } from '../Auth/auth.actions';
import clientsSlice from './clients.Slice';
import { Client } from './clients.type';

const setupArgs = (lastObject?: any, newObject?: any) => {
  return {
    page:
      (newObject && newObject.page) ||
      (lastObject && lastObject.page) ||
      INITIAL_CLIENTS_PAGE,
    count:
      (newObject && newObject.count) ||
      (lastObject && lastObject.count) ||
      INITIAL_CLIENTS_COUNT,
    filters: (newObject && newObject.filters) || (lastObject && lastObject.filters) || [],
  };
};

export const addClient = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(clientsSlice.actions.requestClients(true));
    dispatch(clientsSlice.actions.setShowPagination(false));
    const name: string = getState().clients.name;
    const industry: string = getState().clients.industry;
    const page = INITIAL_CLIENTS_PAGE;
    const count = INITIAL_CLIENTS_COUNT;
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.PROJECTS.clients.alertMessages;

    const response = await clientsService
      .addClient({
        name: name ? name : '',
        industry: industry ? industry : '',
      })
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!response) return;
    handleErrors(response.statusCode, dispatch);
    if (response.statusCode === 500) {
      dispatch(clientsSlice.actions.setSnackBar(true));
      dispatch(
        clientsSlice.actions.setMessage({
          type: 'error',
          text: appTranslation
            ? appTranslation.error + '.'
            : 'Ya existe una cliente con el nombre ingresado.',
          header: appTranslation
            ? appTranslation.headers.ACTION_DENIED
            : 'Acción denegada',
        })
      );
    } else {
      const filtersObj = setupArgs({ page, count });
      const getResponse = await clientsService
        .getClients(getState().clients.filters, filtersObj.page, filtersObj.count)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(clientsSlice.actions.setSnackBar(true));
      dispatch(
        clientsSlice.actions.setMessage({
          type: 'success',
          text: appTranslation
            ? appTranslation.successfulAdd + '.'
            : 'Se ha cargado un nuevo cliente.',
          header: appTranslation
            ? appTranslation.headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      dispatch(
        clientsSlice.actions.getClients({
          data: getResponse.data,
          page: getResponse.page,
          count: getResponse.count,
          pageCount: getResponse.pageCount,
          filters: filtersObj.filters,
        })
      );
      dispatch(clientsSlice.actions.clearInputs());
      dispatch(clientsSlice.actions.setShowForm(false));
      dispatch(clientsSlice.actions.setName(''));
      dispatch(clientsSlice.actions.setIndustry(''));
      dispatch(clientsSlice.actions.setShowPagination(true));
    }
    dispatch(clientsSlice.actions.requestClients(false));
  };
};

export const getClients = (
  filters?: any[],
  page: number = INITIAL_CLIENTS_PAGE,
  count: number = INITIAL_CLIENTS_COUNT
) => {
  return async (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.requestClients(true));
    const filtersObj = setupArgs({ page, count, filters });
    const getResponse = await clientsService
      .getClients(filtersObj.filters, filtersObj.page, filtersObj.count)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(
      clientsSlice.actions.getClients({
        data: getResponse.data,
        filters: filtersObj.filters,
        page: getResponse.page,
        count: getResponse.count,
        pageCount: getResponse.pageCount,
      })
    );
    dispatch(clientsSlice.actions.requestClients(false));
    dispatch(clientsSlice.actions.setName(''));
    dispatch(clientsSlice.actions.setIndustry(''));
  };
};

export const deleteClient = (client: Client) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const client: Client | undefined = getState().clients.client;
    const page = INITIAL_CLIENTS_PAGE;
    const count = INITIAL_CLIENTS_COUNT;
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.PROJECTS.clients.alertMessages;

    dispatch(clientsSlice.actions.requestClients(true));
    if (client) {
      await clientsService.deleteClient(client).catch(() => {});
      dispatch(clientsSlice.actions.setSnackBar(true));
      dispatch(
        clientsSlice.actions.setMessage({
          type: 'success',
          text: appTranslation
            ? appTranslation.successfulDelete + '.'
            : 'Se ha eliminado el cliente.',
          header: appTranslation
            ? appTranslation.headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      const filtersObj = setupArgs({ page, count, filters: getState().projects.filters });
      const getResponse = await clientsService.getClients(
        filtersObj.filters,
        filtersObj.page,
        filtersObj.count
      );
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(clientsSlice.actions.setDeleteModal(false));
      dispatch(
        clientsSlice.actions.getClients({
          data: getResponse.data,
          page: getResponse.page,
          count: getResponse.count,
          pageCount: getResponse.pageCount,
          filters: filtersObj.filters,
        })
      );
    }
    dispatch(clientsSlice.actions.requestClients(false));
  };
};

export const editClient = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(clientsSlice.actions.requestClients(true));
    const id: number | undefined = getState().clients.clientToEdit?.id;
    const name: string = getState().clients.name;
    const industry: string = getState().clients.industry;
    const appTranslation: any =
      getState().translations.appTranslation &&
      getState().translations.appTranslation.pages?.PROJECTS.clients.alertMessages;

    const response = await clientsService
      .editClient({
        id,
        name: name,
        industry: industry,
      })
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!response) return;
    handleErrors(response.statusCode, dispatch);
    if (response.statusCode === 500) {
      dispatch(clientsSlice.actions.setSnackBar(true));
      dispatch(
        clientsSlice.actions.setMessage({
          type: 'error',
          text: appTranslation
            ? appTranslation.error + '.'
            : 'Ya existe una cliente con el nombre ingresado.',
          header: appTranslation
            ? appTranslation.headers.ACTION_DENIED
            : 'Acción denegada',
        })
      );
    } else {
      const filtersObj = setupArgs();
      const getResponse = await clientsService
        .getClients(filtersObj.filters, filtersObj.page, filtersObj.count)
        .catch(() => {
          handleErrors(500, dispatch);
        });
      if (!getResponse) return;
      handleErrors(getResponse.statusCode, dispatch);
      dispatch(clientsSlice.actions.setSnackBar(true));
      dispatch(
        clientsSlice.actions.setMessage({
          type: 'success',
          text: appTranslation
            ? appTranslation.successfulEdit + '.'
            : 'Se ha actualizado informacion en el cliente.',
          header: appTranslation
            ? appTranslation.headers.SUCCESSFULL_ACTION
            : 'Acción exitosa',
        })
      );
      dispatch(
        clientsSlice.actions.getClients({
          data: getResponse.data,
          page: getResponse.page,
          count: getResponse.count,
          pageCount: getResponse.pageCount,
          filters: filtersObj.filters,
        })
      );
      dispatch(clientsSlice.actions.setShowForm(false));
      dispatch(clientsSlice.actions.setShowEditingForm(false));
      dispatch(clientsSlice.actions.clearInputs());
      dispatch(clientsSlice.actions.setName(''));
      dispatch(clientsSlice.actions.setIndustry(''));
    }
    dispatch(clientsSlice.actions.requestClients(false));
  };
};

export const setName = (name: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setName(name));
  };
};

export const setIndustry = (industry?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setIndustry(industry));
  };
};
export const setShowEditingForm = (showEditingForm: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setShowEditingForm(showEditingForm));
  };
};
export const cancelAddClient = () => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.clearInputs());
    dispatch(clientsSlice.actions.setShowForm(false));
    dispatch(clientsSlice.actions.setShowEditingForm(false));
  };
};
export const setFiltersActive = (filtersActive: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setFiltersActive(filtersActive));
  };
};

export const clearInputs = () => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.clearInputs());
  };
};
export const setClientToEdit = (client: Client | undefined) => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setClientToEdit(client));
  };
};

export const setClient = (client: Client) => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setClient(client));
  };
};

export const setShowPagination = (showPagination: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setShowPagination(showPagination));
  };
};

export const setSnackBar = (open: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setSnackBar(open));
  };
};

export const setShowForm = (showForm: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setShowPagination(!showForm));
    dispatch(clientsSlice.actions.setShowForm(showForm));
  };
};

export const handleDeleteModal = (open: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(clientsSlice.actions.setDeleteModal(open));
  };
};
