import { FC } from 'react';
import NotFound404 from '../../assets/NotFound404.png';
import './404Page.scss';

const NotFoundPage: FC = () => {
  return (
    <div className="notFound__container">
      <div>
        <img src={NotFound404} alt="Not found" className="NotFound_img " />
        <p className="description">Lo sentimos, página no encontrada</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
