import { FC } from 'react';
import Button from '@mui/material/Button';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Client } from '../../../store/Clients/clients.type';
import Tooltip from '@mui/material/Tooltip';

interface DeleteProjectModalProps {
  handleOpen: (open: boolean) => void;
  setClient: (client: Client) => void;
  client: Client;
}

const DeleteButtonClient: FC<DeleteProjectModalProps> = ({
  handleOpen,
  setClient,
  client,
}) => {
  return (
    <Tooltip title="Eliminar" placement="top">
      <div>
        <Button
          onClick={() => {
            handleOpen(true);
            setClient(client);
          }}
        >
          <DeleteRoundedIcon
            sx={{ color: 'var(--DinoNeutral01)', width: '18px', height: '18px' }}
          />
        </Button>
      </div>
    </Tooltip>
  );
};

export default DeleteButtonClient;
