import { Grid } from '@mui/material';
import { FC } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import NotFound from '../../assets/NotFound.png';
import { RootState } from '../../store';
import './NotFoundFilters.scss';

interface Props {
  msg?: string;
}
interface LinkStateProps {
  appTranslation?: any;
}

interface LinkDispatchProps {}

type NotFoundFiltersProps = Props & LinkStateProps;

const NotFoundFilters: FC<NotFoundFiltersProps> = ({ msg, appTranslation }) => {
  return (
    <div className="collaboratorsPage__withoutCollaboratorsContainer">
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <img className="NotFound_img" src={NotFound} alt="notFound" />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <p>
            {msg
              ? msg
              : appTranslation
              ? appTranslation.pages?.COLLABORATORS.collaboratorsGrid.noMessage
                  .withoutMatch + '.'
              : 'We have not found matches for the selected filters.'}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { appTranslation } = state.translations;
  return {
    appTranslation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundFilters);
