import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function KnowledgeBackdrop({ loading }) {
  return (
    <div>
        <Backdrop
        sx={{ color: '#00530c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  );
};

export default KnowledgeBackdrop;