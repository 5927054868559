export const INITIAL_SKILLS_PAGE: number = 1;
export const INITIAL_SKILLS_COUNT: number = 5;
export const INITIAL_COLLABORATORS_PAGE: number = 1;
export const INITIAL_COLLABORATORS_COUNT: number = 9;
export const INITIAL_PROJECTS_PAGE: number = 1;
export const INITIAL_PROJECTS_COUNT: number = 3;
export const INITIAL_CLIENTS_PAGE: number = 1;
export const INITIAL_CLIENTS_COUNT: number = 5;

export const ENGLISH_LEVELS = [
  {
    value: 10,
    label: 'No',
    name: 'NO_NONE',
  },
  {
    value: 20,
    label: 'A1',
    name: 'A1_BEGGINER',
  },
  {
    value: 30,
    label: 'A2',
    name: 'A2_ELEMENTARY',
  },
  {
    value: 40,
    label: 'B1',
    name: 'B1_INTERMEDIATE',
  },
  {
    value: 50,
    label: 'B2',
    name: 'B2_UPPER_INTERMEDIATE',
  },
  {
    value: 60,
    label: 'C1',
    name: 'C1_ADVANCED',
  },
  {
    value: 70,
    label: 'C2',
    name: 'C2_PROFICIENT',
  },
];
