import { Grid } from '@mui/material';
import { FC } from 'react';
import { RootState } from '../../../../../../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AreaType,
  Collaborator1,
  JobType,
} from '../../../../../../../../store/Collaborators/collaborators.type';
import {
  clearInputs,
  setAddress,
  setCity,
  setCollabArea,
  setCollabPosition,
  setCountry,
  setDateOfBirth,
  setEmail,
  setFullname,
  setPhone,
  setSeniority,
  setShowEditCollabForm,
  setShowSnackBar,
  setState,
  editCollabInfo,
} from '../../../../../../../../store/Collaborators/collaborators.actions';
import KnowledgeAlert from '../../../../../../../../commons/KnowledgeAlert/KnowledgeAlert';
import PersonalInfo from './PersonalInfo';
import '../../ProfileTabStyle.scss';
import './InfoSection.scss';
import LaboralInfo from './LaboralInfo';
import {
  formatDBText,
  splitTheFirstWord,
} from '../../../../../../../../helpers/formattingFunctions';
import { EnglishLevel } from '../../../../../../../../store/EnglishLevels/englishLevels.type';
import CakeIcon from '@mui/icons-material/Cake';

interface LinkDispatchProps {
  setFullname: (fullname: string) => void;
  setDateOfBirth: (dateOfbirth: Date | null) => void;
  setCountry: (country: string | undefined) => void;
  setState: (state: string | undefined) => void;
  setCity: (city: string | undefined) => void;
  setAddress: (address: string) => void;
  setPhone: (phone: string) => void;
  setEmail: (email: string) => void;
  setCollabPosition: (collabPosition: string) => void;
  setCollabArea: (area: AreaType | string) => void;
  setSeniority: (seniority: string) => void;
  clearInputs: () => void;
  setShowSnackBar: (show: boolean) => void;
  setShowEditCollabForm: (showEditCollabForm: boolean) => void;
  editCollabInfo: () => void;
}
interface LinkStateProps {
  collaborator1?: Collaborator1;
  fullname: string;
  dateOfBirth: string | null;
  country: string;
  city: string;
  state: string;
  address: string;
  phone: string;
  email: string;
  job: JobType | string;
  area: AreaType | string;
  seniority: string;
  message?: any;
  showSnackbar: boolean;
  showEditCollabForm: boolean;
  showAddBtn: boolean;
  appTranslation?: any;
  englishLevels: EnglishLevel[];
}

type InfoSectionProps = LinkDispatchProps & LinkStateProps;

const InfoSection: FC<InfoSectionProps> = (props: InfoSectionProps) => {
  let collabPageTranslation =
    props.appTranslation && props.appTranslation.pages?.COLLABORATORS.collaboratorProfile;

  const englishLvlInfo =
    props.collaborator1!.collaboratorEnglishLevels.length > 0 &&
    props.englishLevels.filter(
      (el) => el.id === props.collaborator1!.collaboratorEnglishLevels[0].englishLevelId
    );

  return (
    <div className="infoSection__container ">
      {/* SNACKBAR */}
      {props.message && (
        <KnowledgeAlert
          header={props.message.header}
          severity={props.message.type}
          openSnackBar={props.showSnackbar}
          closeSnackBar={() => props.setShowSnackBar(false)}
          message={props.message.text}
        />
      )}
      <div className="personalInfo__container">
        <Grid container>
          <Grid item md={12}>
            <h1>
              {/* {props.appTranslation
                ? collabPageTranslation.profileTab.sections[0].title
                : 'Información del colaborador'} */}
              Personal information
            </h1>
          </Grid>
          <PersonalInfo
            collaborator={props.collaborator1!}
            appTranslation={
              props.appTranslation && collabPageTranslation.profileTab.sections[0].props
            }
          />
        </Grid>
      </div>
      <div className="laboralInfo__container">
        <Grid container>
          <Grid item md={12}>
            <h1>Work information</h1>
          </Grid>
          <LaboralInfo
            collaborator={props.collaborator1!}
            appTranslation={
              props.appTranslation && collabPageTranslation.profileTab.sections[0].props
            }
          />
        </Grid>
      </div>
      <div className="englishBirthday__container">
        <div className="englishBirthday__section">
          <h1>English Level</h1>

          {englishLvlInfo ? (
            <h2 className="englishBirthday__section-description">
              {formatDBText(englishLvlInfo[0].name.split('_')[0])} {' - '}
              {formatDBText(splitTheFirstWord(englishLvlInfo[0].name))}
            </h2>
          ) : (
            <h2 className="englishBirthday__section-description">-</h2>
          )}
        </div>
        <div className="englishBirthday__section">
          <h1>Birthday</h1>
          <div className="englishBirthday__section-description">
            <CakeIcon
              sx={{ height: '32px', width: '32px', color: 'var(--DinoNeutral02)' }}
            />
            <h2 style={{ fontWeight: '600' }}>
              {!!props.collaborator1?.birthDate
                ? props.collaborator1.birthDate.slice(8, 10)
                : '-'}
              /
              {!!props.collaborator1?.birthDate
                ? props.collaborator1.birthDate.slice(5, 7)
                : '-'}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const {
    collaborator1,
    fullname,
    dateOfBirth,
    country,
    city,
    state: collabState,
    address,
    phone,
    email,
    job,
    area,
    seniority,
    showSnackbar,
    showEditCollabForm,
    showAddBtn,
  } = state.collaborators;
  const { appTranslation } = state.translations;
  const { englishLevels } = state.englishLevels;

  return {
    collaborator1,
    fullname,
    dateOfBirth,
    country,
    city,
    state: collabState,
    address,
    phone,
    email,
    job,
    area,
    seniority,
    showSnackbar,
    showEditCollabForm,
    showAddBtn,
    appTranslation,
    englishLevels,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  setShowSnackBar: bindActionCreators(setShowSnackBar, dispatch),
  setFullname: bindActionCreators(setFullname, dispatch),
  setDateOfBirth: bindActionCreators(setDateOfBirth, dispatch),
  setCountry: bindActionCreators(setCountry, dispatch),
  setState: bindActionCreators(setState, dispatch),
  setCity: bindActionCreators(setCity, dispatch),
  setAddress: bindActionCreators(setAddress, dispatch),
  setPhone: bindActionCreators(setPhone, dispatch),
  setEmail: bindActionCreators(setEmail, dispatch),
  setCollabPosition: bindActionCreators(setCollabPosition, dispatch),
  setCollabArea: bindActionCreators(setCollabArea, dispatch),
  setSeniority: bindActionCreators(setSeniority, dispatch),
  setShowEditCollabForm: bindActionCreators(setShowEditCollabForm, dispatch),
  clearInputs: bindActionCreators(clearInputs, dispatch),
  editCollabInfo: bindActionCreators(editCollabInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoSection);
