import { PreviousExperience } from '../store/PreviousExperience/prevExperience.type';
import { getDefaultHeaders } from './utils.service';

class PreviousExperienceService {
  async addPrevExperience(prevExperience: PreviousExperience): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/previousExperiences`,
      {
        method: 'POST',
        body: JSON.stringify(prevExperience),
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  async deletePrevExperience(prevExperience: PreviousExperience): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/previousExperiences/${prevExperience.id}`,
      {
        method: 'DELETE',
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  async editPrevExperiences(prevExperience: PreviousExperience): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/previousExperiences/${prevExperience.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(prevExperience),
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }
}

const prevExperienceService = new PreviousExperienceService();
export default prevExperienceService;
