import { FC } from 'react';
import { RootState } from '../../../../../../../../store';
import { connect } from 'react-redux';
import { Collaborator1 } from '../../../../../../../../store/Collaborators/collaborators.type';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import WorkItem from './WorkItem';
import { Box, Typography } from '@mui/material';

interface LinkStateProps {
  collaborator1?: Collaborator1;
  seniorities: { id: number; seniorityName: string }[];
  areas: { id: number; areaName: string }[];
  teams: { id: number; teamName: string }[];
  conditions: { id: number; conditionName: string }[];
  roles: { id: number; roleName: string }[];
}

type Props = LinkStateProps;

const WorkHistory: FC<Props> = ({
  collaborator1,
  seniorities,
  roles,
  areas,
  teams,
  conditions,
}) => {
  const orderedPositions = [...collaborator1?.collaboratorPositions!].sort(function (
    a,
    b
  ) {
    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
  });

  return (
    <div
      className="profileTabContainer"
      style={{ display: orderedPositions.length < 1 ? 'none' : 'auto' }}
    >
      <h1>Work History</h1>
      <Timeline
        sx={{
          mt: '50px',
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        {orderedPositions.map((position, idx) => {
          const area = areas.filter((a) => a.id === position.areaId)[0].areaName;
          const team = teams.filter((t) => t.id === position.teamId)[0].teamName;
          const seniority =
            position.seniorityId &&
            seniorities.filter((s) => s.id === position.seniorityId)[0].seniorityName;
          const rol = roles.filter((r) => r.id === position.roleId)[0].roleName;
          const condition = conditions.filter((c) => c.id === position.conditionId)[0]
            .conditionName;

          return (
            <TimelineItem key={idx}>
              <TimelineOppositeContent
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  pt: 0,
                  maxWidth: '200px',
                }}
              >
                <Box
                  sx={{
                    background: 'var(--DinoSuccess05)',
                    p: '6px',
                    borderRadius: '4px',
                  }}
                >
                  <Typography>
                    {`${position.startDate.slice(0, 10)} - ${
                      !!position.endDate ? position.endDate!.slice(0, 10) : 'to present'
                    }`}
                  </Typography>
                </Box>
              </TimelineOppositeContent>
              <TimelineSeparator sx={{ color: 'var(--DinoNeutral03)' }}>
                <TimelineDot
                  sx={{
                    background: 'transparent',
                    border: '3px solid var(--DinoPrimary04)',
                    width: '6px',
                    height: '6px',
                    margin: 0,
                  }}
                />
                {idx !== collaborator1!.collaboratorPositions?.length - 1 && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent sx={{ pt: '0px' }}>
                <WorkItem
                  area={area}
                  team={team}
                  seniority={seniority}
                  role={rol}
                  billable={position.billable}
                  condition={condition}
                  monthlyHours={position.monthlyHours}
                />
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { collaborator1, seniorities, roles, areas, teams, conditions } =
    state.collaborators;
  return {
    collaborator1,
    seniorities,
    roles,
    areas,
    teams,
    conditions,
  };
};

export default connect(mapStateToProps)(WorkHistory);
