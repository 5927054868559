//Database text: Replace "_" for " " and uppercase first leter and lowercase the rest.
export function formatDBText(str: string) {
  const arrWithoutUnderscore = str.split('_');
  for (var i = 0; i < arrWithoutUnderscore.length; i++) {
    arrWithoutUnderscore[i] =
      arrWithoutUnderscore[i].charAt(0).toUpperCase() +
      arrWithoutUnderscore[i].slice(1).toLocaleLowerCase();
  }
  const textToShow = arrWithoutUnderscore.join(' ');
  return textToShow;
}

//Uppercase first letter of every word
export function uppercaseFirstLetterEveryWord(wordsToCapitalize: string) {
  const arrOfWords = wordsToCapitalize.split(' ');
  for (var i = 0; i < arrOfWords.length; i++) {
    arrOfWords[i] = arrOfWords[i].charAt(0).toUpperCase() + arrOfWords[i].slice(1);
  }
  const capitalizedWords = arrOfWords.join(' ');
  return capitalizedWords;
}

//Uperrcase only first letter of first word
export function uppercaseFirstLetterFirstWord(stringToCapitalize: string) {
  const capitalizedString =
    stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
  return capitalizedString;
}

//Splits the first word of a string.
export function splitTheFirstWord(str: string) {
  const arrWithoutUnderscore = str.split('_');
  const arrWithoutFirstWord = [];
  for (var i = 1; i < arrWithoutUnderscore.length; i++) {
    arrWithoutFirstWord.push(arrWithoutUnderscore[i]);
  }
  const textWithoutFirstWord = arrWithoutFirstWord.join(' ');
  return textWithoutFirstWord;
}

//It joins the first word with the rest of the sentence with a "-"
export function joinFirstWordWithRestWithUnderscore(str: any) {
  return ` ${formatDBText(str.name.split('_')[0])} - ${formatDBText(
    splitTheFirstWord(str.name)
  )}`;
}
//Function to format database dates to YYYY-MM-DD
export function formatDate(date: any) {
  const splitedDate = date.toString().split('-');
  const formatedDate = new Date(
    parseInt(splitedDate[2]),
    parseInt(splitedDate[1]) - 1,
    parseInt(splitedDate[0])
  );
  return formatedDate;
}
// Remove extra spaces of the text
export function removeExtraSpace(text: string) {
  let textWithoutSpaces = text.trim().split(/ +/).join(' ');
  return textWithoutSpaces;
}

//ResumeLinks formating functions (adapts data structure to HistoryModal format )
export const formatResumeLinks = (data: any[]) => {
  let resumes: any[] = [];
  if (!!data.length) {
    resumes = data.map((res) => {
      return {
        userEmail: res.user,
        createdAt: res.createdAt,
        link: res.link,
        id: res.id,
        createdBy: res.createdBy,
        languageSelected: res.languageSelected,
      };
    });
  }

  return resumes;
};
