/* eslint-disable no-unused-vars */
import { Certification } from '../Certifications/certifications.type';
import { DinoExperience } from '../DinoExperience/dinoExperience.type';
import { EnglishLevel } from '../EnglishLevels/englishLevels.type';
import { PreviousExperience } from '../PreviousExperience/prevExperience.type';
import { LevelDescription, WeightedSkill } from '../Skills/skills.type';

export enum SkillType {
  TECH = 'TECH',
  AWS = 'AWS',
  SOFT = 'SOFT',
  TOOLS = 'TOOLS',
}
export enum CollaboratorType {
  PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
  STAFFING_SERVICES = 'STAFFING_SERVICES',
}

export const SkillTypeColor: any = {
  TECH: {
    background: 'var(--DinoAccent04)',
    border: 'var(--DinoAccent01)',
    color: 'var(--DinoAccent01)',
  },
  AWS: {
    background: 'var(--DinoSecondary04)',
    border: 'var(--DinoSecondary01)',
    color: 'var(--DinoSecondary01)',
  },
  SOFT: {
    background: 'var(--DinoTertiary04)',
    border: 'var(--DinoTertiary01)',
    color: 'var(--DinoTertiary01)',
  },
  TOOLS: {
    background: 'var(--DinoPrimary07)',
    border: 'var(--DinoPrimary01)',
    color: 'var(--DinoPrimary01)',
  },
};

export interface Skill {
  skills: any;
  id?: number;
  name: string;
  description: string;
  type: SkillType | string;
  collaborator?: number | Collaborator;
  levelsModel?: number;
  levelsDescription: LevelDescription[];
}

export interface Collaborator {
  id?: number;
  type: CollaboratorType;
  fullname: string;
  name?: string;
  lastName?: string;
  dateOfBirth: string;
  country: string;
  city: string;
  state: string;
  address: string;
  phone: string;
  email: string;
  job: JobType | string;
  area: AreaType;
  seniority: string;
  image?: string;
  skills?: Skill[];
  previousExperiences?: PreviousExperience[];
  certifications?: Certification[];
  dinoExperiences?: DinoExperience[];
  englishLevel?: EnglishLevel;
  weightedSkills?: WeightedSkill[];
}

export interface ColabEnglishLevel {
  id: number;
  englishLevelId: number;
  startDate: string;
  endDate?: string;
}
export interface CollabTopSkill {
  id: number;
  skillId: number;
  startDate: string;
  endDate?: string;
  isCoreSkill: boolean;
}

export interface CollabSkillLevel {
  id: number;
  skillId: number;
  level: number;
  createdDate?: string;
  comment?: string;
  commenterId?: number;
}

export interface CollabSkillHistory {
  skillId: number;
  collaboratorSkillLevelsHistory: CollabSkillLevel[];
  topSkillHistory: CollabTopSkill[];
}
export interface CollabPosition {
  id: number;
  seniorityId: number;
  areaId: number;
  teamId: number;
  conditionId: number;
  roleId: number;
  collaboratorId: number;
  billable: boolean;
  monthlyHours: number;
  startDate: string;
  endDate?: string;
}

export interface CollabLearningTrack {
  id: number;
  learningTrackId: number;
  startDate: string;
  endDate?: string;
}

export interface Collaborator1 {
  id: number;
  firstName: string;
  lastName: string;
  address: string;
  addressInID: string;
  birthDate: string;
  civilStatus: string;
  companyEmail: string;
  emergencyContact: string;
  isActive: boolean;
  leavingReason: string;
  nationalID: string;
  nationality: string;
  personalEmail: string;
  phone: string;
  province: string;
  reportsTo: number;
  sex: string;
  startDate: string;
  taxID: string;
  vendorId: number;
  collaboratorEnglishLevels: ColabEnglishLevel[];
  collaboratorSkillHistory: {
    skillId: number;
    collaboratorSkillLevelsHistory: CollabSkillLevel[];
    topSkillHistory: CollabTopSkill[];
  }[];
  collaboratorPositions: CollabPosition[];
  collaboratorLearningTracks: CollabLearningTrack[];
}

export interface CollaboratorState {
  //TODO REMOVE OLD VERSION OF COLLABORATOR/S AND LEAVE THE NEWEST (COLLABORATOR/S1 -change name after removing the old ones)

  collaborators: Collaborator[];
  collaborators1: Collaborator1[];
  collaborator?: Collaborator;
  collaborator1?: Collaborator1;
  seniorities: { id: number; seniorityName: string }[];
  areas: { id: number; areaName: string }[];
  teams: { id: number; teamName: string }[];
  conditions: { id: number; conditionName: string }[];
  roles: { id: number; roleName: string }[];
  showEditLvlModal: boolean;

  //TODO CHECK WHAT IS NOT USED ANYMORE
  hasError: boolean;
  entityIsLoading: boolean;
  errorMessage: string;
  filters?: any[];
  page?: number;
  count?: number;
  pageCount: number | 1;
  collaboratorFullnameSelected?: string;
  areaSelected?: { label: string; type: string };
  positionSelected?: { label: string; type: string };
  skills: Skill[];
  fullname: string;
  dateOfBirth: string | null;
  country: string;
  city: string;
  state: string;
  address: string;
  phone: string;
  email: string;
  job: JobType | string;
  area: AreaType | string;
  seniority: string;
  englishLevel?: EnglishLevel;
  techSkillsCollab?: Skill[];
  softSkillsCollab?: Skill[];
  awsSkillsCollab?: Skill[];
  toolsCollab?: Skill[];
  skillsSelected?: any[];
  englishLevelsSelected: string[];
  message?: any;
  showSnackbar: boolean;
  snackBarError: boolean;
  showEditCollabForm: boolean;
  showAddBtn: boolean;
  showEnglishLevelForm: boolean;
  showEnglishLevelAddBtn: boolean;
  deleteModalEnglishLevel: boolean;
  showHistoryModal: boolean;
  openDeleteModal: boolean;
  confirmFilter: boolean;
  filtersActive: boolean;
  curriculums?: any[];
  loading: boolean;
}

export enum AreaType {
  ADMINISTRATION = 'ADMINISTRATION',
  FINANCE = 'FINANCE',
  HUMAN_TALENT = 'HUMAN_TALENT',
  PRODUCT_OPERATIONS_TEAM = 'PRODUCT_OPERATIONS_TEAM',
  TALENT_ACQUISITION = 'TALENT_ACQUISITION',
  DSUPPORT = 'DSUPPORT',
  CLOUD_ENGINEERING = 'CLOUD_ENGINEERING',
  SOFTWARE_ENGINEERING = 'SOFTWARE_ENGINEERING',
  SALES = 'SALES',
  BRAND = 'BRAND',
  DEMAND_GENERATION = 'DEMAND_GENERATION',
  IT_OPERATIONS = 'IT_OPERATIONS',
}

export const areaTypeLabels = [
  {
    label: 'Administration',
    type: AreaType.ADMINISTRATION,
  },
  {
    label: 'Brand',
    type: AreaType.BRAND,
  },
  {
    label: 'Cloud Engineering',
    type: AreaType.CLOUD_ENGINEERING,
  },
  {
    label: 'Demand Generation',
    type: AreaType.DEMAND_GENERATION,
  },
  {
    label: 'Dsupport',
    type: AreaType.DSUPPORT,
  },
  {
    label: 'Finance',
    type: AreaType.FINANCE,
  },
  {
    label: 'Human Talent',
    type: AreaType.HUMAN_TALENT,
  },
  {
    label: 'IT operations',
    type: AreaType.IT_OPERATIONS,
  },
  {
    label: 'Product Operations Team',
    type: AreaType.PRODUCT_OPERATIONS_TEAM,
  },
  {
    label: 'Sales',
    type: AreaType.SALES,
  },
  {
    label: 'Software Engineering',
    type: AreaType.SOFTWARE_ENGINEERING,
  },

  {
    label: 'Talent Acquisition',
    type: AreaType.TALENT_ACQUISITION,
  },
];

export enum JobType {
  SOFTWARE_ANALYST = 'SOFTWARE_ANALYST',
  ACCOUNTING_LEAD = 'ACCOUNTING_LEAD',
  ACCOUNTING_ASSISTANCE = 'ACCOUNTING_ASSISTANCE',
  BUSINESS_ADMINISTRATIONS_MANAGER = 'BUSINESS_ADMINISTRATIONS_MANAGER',
  BUSINESS_DEVELOPMENT_MANAGER = 'BUSINESS_DEVELOPMENT_MANAGER',
  CLOUD_ENGINEER = 'CLOUD_ENGINEER',
  COMMUNICATIONS_MANAGER = 'COMMUNICATIONS_MANAGER',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  ACCOUNT_MANAGER_US = 'ACCOUNT_MANAGER_US',
  FUNCTIONAL_ANALYST = 'FUNCTIONAL_ANALYST',
  GRAPHIC_DESIGNER = 'GRAPHIC_DESIGNER',
  HR_FACILITIES_MANAGER = 'HR_FACILITIES_MANAGER',
  IT_SUPPORT = 'IT_SUPPORT',
  SOFTWARE_ENGINEER = 'SOFTWARE_ENGINEER',
  KEY_ACCOUNT_MANAGER_LATAM = 'KEY_ACCOUNT_MANAGER_LATAM',
  MARKETING_CAMPAIGN_MANAGER_USA = 'MARKETING_CAMPAIGN_MANAGER_USA',
  LEAD_PROJECT_MANAGER = 'LEAD_PROJECT_MANAGER',
  LEAD_SOLUTIONS_ARCHITECT = 'LEAD_SOLUTIONS_ARCHITECT',
  MARKETING_CAMPAIGN_MANAGER_LATAM = 'MARKETING_CAMPAIGN_MANAGER_LATAM',
  BRAND_DIRECTOR = 'BRAND_DIRECTOR',
  ORGANIZATIONAL_PSYCHOLOGIST_AND_TALENT_MANAGEMENT_LEAD = 'ORGANIZATIONAL_PSYCHOLOGIST_AND_TALENT_MANAGEMENT_LEAD',
  ACCOUNT_MANAGER_LATAM = 'ACCOUNT_MANAGER_LATAM',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  PROJECT_MANAGER_CLOUD = 'PROJECT_MANAGER_CLOUD',
  QA = 'QA',
  PEOPLE_CARE_AND_COMUNICATIONS_MANAGER = 'PEOPLE_CARE_AND_COMUNICATIONS_MANAGER',
  SALES_MANAGER = 'SALES_MANAGER',
  SOLUTIONS_ARCHITECT = 'SOLUTIONS_ARCHITECT',
  SOLUTIONS_ARCHITECT_LEADER = 'SOLUTIONS_ARCHITECT_LEADER',
  TALENT_ACQUISITION_ANALYST = 'TALENT_ACQUISITION_ANALYST',
  TALENT_ACQUISITION_MANAGER = 'TALENT_ACQUISITION_MANAGER',
  UX_UI_SOLUTIONS_DESIGNER = 'UX_UI_SOLUTIONS_DESIGNER',
  VP_OF_CLOUD_ENGINEERING = 'VP_OF_CLOUD_ENGINEERING',
}

export const jobTypeLabels = [
  {
    label: 'Accounting Assistance',
    type: JobType.ACCOUNTING_ASSISTANCE,
  },
  {
    label: 'Account Manager LATAM',
    type: JobType.ACCOUNT_MANAGER_LATAM,
  },
  {
    label: 'Accounting Lead',
    type: JobType.ACCOUNTING_LEAD,
  },
  {
    label: 'Account Manager US',
    type: JobType.ACCOUNT_MANAGER_US,
  },
  {
    label: 'Brand Director',
    type: JobType.BRAND_DIRECTOR,
  },
  {
    label: 'Business Administrations Manager',
    type: JobType.BUSINESS_ADMINISTRATIONS_MANAGER,
  },
  {
    label: 'Business Development Manager',
    type: JobType.BUSINESS_DEVELOPMENT_MANAGER,
  },
  {
    label: 'Cloud Engineer',
    type: JobType.CLOUD_ENGINEER,
  },
  {
    label: 'Communications Manager',
    type: JobType.COMMUNICATIONS_MANAGER,
  },
  {
    label: 'Content Manager',
    type: JobType.CONTENT_MANAGER,
  },
  {
    label: 'Functional Analyst',
    type: JobType.FUNCTIONAL_ANALYST,
  },
  {
    label: 'Graphic Designer',
    type: JobType.GRAPHIC_DESIGNER,
  },
  {
    label: 'HR Facilities Manager',
    type: JobType.HR_FACILITIES_MANAGER,
  },
  {
    label: 'IT Support',
    type: JobType.IT_SUPPORT,
  },
  {
    label: 'Key Account Manager LATAM',
    type: JobType.KEY_ACCOUNT_MANAGER_LATAM,
  },
  {
    label: 'Lead Project Manager',
    type: JobType.LEAD_PROJECT_MANAGER,
  },
  {
    label: 'Lead Solutions Architect',
    type: JobType.LEAD_SOLUTIONS_ARCHITECT,
  },
  {
    label: 'Marketing Campaign Manager LATAM',
    type: JobType.MARKETING_CAMPAIGN_MANAGER_LATAM,
  },
  {
    label: 'Marketing Campaign Manager USA',
    type: JobType.MARKETING_CAMPAIGN_MANAGER_USA,
  },
  {
    label: 'Organizational Psychologist & Talent Management Lead',
    type: JobType.ORGANIZATIONAL_PSYCHOLOGIST_AND_TALENT_MANAGEMENT_LEAD,
  },
  {
    label: 'People Care & Comunications Manager',
    type: JobType.PEOPLE_CARE_AND_COMUNICATIONS_MANAGER,
  },
  {
    label: 'Project Manager',
    type: JobType.PROJECT_MANAGER,
  },
  {
    label: 'Project Manager Cloud',
    type: JobType.PROJECT_MANAGER_CLOUD,
  },
  {
    label: 'QA',
    type: JobType.QA,
  },
  {
    label: 'Sales Manager',
    type: JobType.SALES_MANAGER,
  },
  {
    label: 'Software Analist',
    type: JobType.SOFTWARE_ANALYST,
  },
  {
    label: 'Software Engineer',
    type: JobType.SOFTWARE_ENGINEER,
  },
  {
    label: 'Solutions Architect',
    type: JobType.SOLUTIONS_ARCHITECT,
  },
  {
    label: 'Solutions Architect Leader',
    type: JobType.SOLUTIONS_ARCHITECT_LEADER,
  },
  {
    label: 'Talent Acquisition Analyst',
    type: JobType.TALENT_ACQUISITION_ANALYST,
  },
  {
    label: 'UX/UI Solutions Designer',
    type: JobType.UX_UI_SOLUTIONS_DESIGNER,
  },
  {
    label: 'VP of Cloud Engineering',
    type: JobType.VP_OF_CLOUD_ENGINEERING,
  },
];

export enum EnglishLevelType {
  NO_NONE = 'NO_NONE',
  A1_BEGGINER = 'A1_BEGGINER',
  A2_ELEMENTARY = 'A2_ELEMENTARY',
  B1_INTERMEDIATE = 'B2_INTERMEDIATE',
  B2_UPPER_INTERMEDIATE = 'B2_UPPER_INTERMEDIATE',
  C1_ADVANCED = 'C1_ADVANCED',
  C2_PROFICIENT = 'C2_PROFICIENT',
}

export const englishLevelTypeLabels = [
  {
    label: 'NO - None',
    type: EnglishLevelType.NO_NONE,
  },
  {
    label: 'A1 - Begginer',
    type: EnglishLevelType.A1_BEGGINER,
  },
  {
    label: 'A2 - Elementary',
    type: EnglishLevelType.A2_ELEMENTARY,
  },
  {
    label: 'B1 - Intermediate',
    type: EnglishLevelType.B1_INTERMEDIATE,
  },
  {
    label: 'B2 - Upper intermediate',
    type: EnglishLevelType.B2_UPPER_INTERMEDIATE,
  },
  {
    label: 'C1 - Advanced',
    type: EnglishLevelType.C1_ADVANCED,
  },
  {
    label: 'C2 - Proficient',

    type: EnglishLevelType.C2_PROFICIENT,
  },
];
