import { getDefaultHeaders } from './utils.service';

class GeneratePDFService {
  async generatePDF(generatePDF: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/resumes`, {
      method: 'POST',
      body: JSON.stringify(generatePDF),
      headers: getDefaultHeaders(),
    });

    return response.json();
  }
}
const generatePDFService = new GeneratePDFService();
export default generatePDFService;
