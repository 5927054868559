function getDefaultHeaders() {
  const localStore: any = localStorage.getItem('access_token');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${JSON.parse(localStore)}`,
  };
}

function getDefaultHeadersWithoutCredentials() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

function getCollaboratorId() {
  return window.sessionStorage.getItem('collaborator_id');
}

export { getDefaultHeaders, getCollaboratorId, getDefaultHeadersWithoutCredentials };
