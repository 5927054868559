import { FC } from 'react';
import Carousel from 'react-elastic-carousel';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { RootState } from '../../../../../../../../store';
import { connect } from 'react-redux';
import { Skill1 } from '../../../../../../../../store/Skills/skills.type';
import SkillsSliderCard from './SkillsSliderCard';
import {
  Collaborator1,
  CollabSkillHistory,
  CollabTopSkill,
} from '../../../../../../../../store/Collaborators/collaborators.type';

interface LinkStateProps {
  collaborator1?: Collaborator1;
  skills1: Skill1[];
}

type SliderProps = LinkStateProps;

const SkillsSlider: FC<SliderProps> = ({ collaborator1, skills1 }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 850, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1450, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1750, itemsToShow: 5, itemsToScroll: 5 },
  ];

  const type = (skill: CollabSkillHistory) => {
    let skillToShow: Skill1 = skills1.filter((s) => s.id === skill.skillId)[0];

    if (skill.topSkillHistory.length > 0) {
      let orderedTopSkill: CollabTopSkill[] = skill.topSkillHistory.sort(function (a, b) {
        return new Date(b.endDate!).getTime() - new Date(a.endDate!).getTime();
      });
      const nowDate = new Date();
      const startDate = new Date(orderedTopSkill[0].startDate);
      const endDate = new Date(orderedTopSkill[0].endDate!);
      const comparison = nowDate <= endDate && nowDate >= startDate;

      if (comparison) {
        const type = orderedTopSkill[0].isCoreSkill ? 'CORE' : 'TOP';
        return type;
      }
    } else {
      if (skillToShow.skillKindId === 6) {
        return 'SOFT';
      } else {
        return 'OTHER';
      }
    }
  };

  const percentage = (sk: CollabSkillHistory) => {
    const sortedLevels = [...sk.collaboratorSkillLevelsHistory].sort(
      (a, b) => b.level - a.level
    );

    if (type(sk) === 'SOFT') {
      return (sortedLevels[0].level * 100) / 6;
    } else {
      return (sortedLevels[0].level * 100) / 4;
    }
  };

  const orderedSkills = [...collaborator1!.collaboratorSkillHistory].sort(
    (a, b) => percentage(b) - percentage(a)
  );

  return (
    <Carousel
      isRTL={false}
      breakPoints={breakPoints}
      focusOnSelect={true}
      renderArrow={({ type, onClick }) => {
        const pointer =
          type === 'PREV' ? (
            <ArrowBackIosNewRoundedIcon sx={{ height: '16px', width: '16px' }} />
          ) : (
            <ArrowForwardIosRoundedIcon sx={{ height: '16px', width: '16px' }} />
          );
        return (
          <Box
            sx={{
              display:
                collaborator1!.collaboratorSkillHistory.length < 1 ? 'none' : 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={onClick}
              sx={{
                color: 'var(--DinoPrimary04)',
                border: '3px solid var(--DinoPrimary04)',
                borderRadius: '8px',
                filter: 'drop-shadow(0px 2px 4px rgba(38, 43, 47, 0.4));',
              }}
            >
              {pointer}
            </IconButton>
          </Box>
        );
      }}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <Box
            sx={{
              display:
                collaborator1!.collaboratorSkillHistory.length < 1 ? 'none' : 'flex',
              my: '20px',
            }}
          >
            {pages.map((page) => {
              const isActivePage = activePage === page;
              return (
                <Box
                  key={page}
                  onClick={() => onClick(page.toString())}
                  sx={{
                    height: '10px',
                    width: '10px',
                    mx: '5px',
                    borderRadius: '50%',
                    background: isActivePage
                      ? 'var(--DinoNeutral03)'
                      : 'var(--DinoNeutral05)',
                  }}
                />
              );
            })}
          </Box>
        );
      }}
    >
      {orderedSkills.slice(0, 10).map((skill, idx) => {
        const types = type(skill);
        return (
          <SkillsSliderCard
            key={idx + skill.skillId}
            skills={skills1}
            skill={skill}
            idx={idx}
            type={types}
          />
        );
      })}
    </Carousel>
  );
};
const mapStateToProps = (state: RootState): LinkStateProps => {
  const { collaborator1 } = state.collaborators;
  const { skills1 } = state.skills;
  return {
    collaborator1,
    skills1,
  };
};

export default connect(mapStateToProps)(SkillsSlider);
