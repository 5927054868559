import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Tooltip from '@mui/material/Tooltip';
import { Client } from '../../../store/Clients/clients.type';
import DeleteButtonClient from './DeleteButtonClient';

interface ItemProps {
  handleOpen: (open: boolean) => void;
  client: Client;
  setClient: (client: Client) => void;
  setClientToEdit: (client: Client) => void;
  deleteModal: boolean;
  setShowEditingForm: (showEditingForm: boolean) => void;
  appTranslation?: any;
}

const ProjectsListItem: FC<ItemProps> = ({
  client,
  appTranslation,
  handleOpen,
  setClient,
  setShowEditingForm,
  setClientToEdit,
}) => {
  return (
    <Box display="flex" justifyContent="center" sx={{ mb: '22px' }}>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={10} sx={{ mt: '16px' }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontFamily: 'Source Sans Pro',
              letterSpacing: '0.02em',
              color: '#00884F',
              fontSize: '22px',
              mb: '8px',
            }}
          >
            {client.name}
          </Typography>

          <Typography
            sx={{
              letterSpacing: '0.005em',
              display: 'inline',
              color: 'var(--DinoNeutral01)',
              fontWeight: 400,
              fontFamily: 'Source Sans Pro',
              fontSize: '16px',
              mb: '18px',
            }}
          >
            {appTranslation ? appTranslation.item.industry + ':' : 'Industria:'}
            <b className="proyect-info__text"> {client.industry} </b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            pl: '8%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Editar" placement="top">
            <Button
              variant="text"
              onClick={() => {
                setShowEditingForm(true);
                setClientToEdit(client);
                setClient(client);
              }}
            >
              <EditRoundedIcon
                sx={{
                  width: '18px',
                  height: '18px',
                  justifyContent: 'flex-end',
                }}
              />
            </Button>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            pl: '4.7%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DeleteButtonClient
            handleOpen={handleOpen}
            setClient={setClient}
            client={client}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectsListItem;
