import { createSlice } from '@reduxjs/toolkit';
import { TranslationState } from './translations.type';

const initialState: TranslationState = {
  entityToTranslate: {},
  translatedEntity: {},
  entitiesAreFetching: false,
};

export default createSlice({
  name: 'translations',
  initialState,
  reducers: {
    saveEntityToTranslate: (state, { payload }) => {
      return {
        ...state,
        entityToTranslate: payload,
      };
    },
    saveTranslatedEntity: (state, { payload }) => {
      return {
        ...state,
        translatedEntity: payload,
      };
    },
    clearTranslations: (state) => ({
      ...state,
      entityToTranslate: {},
      translatedEntity: {},
      entitiesAreFetching: false,
    }),
    requestTranslation: (state, { payload }) => {
      return {
        ...state,
        entitiesAreFetching: payload,
      };
    },
    setAppTranslation: (state, { payload }) => {
      return {
        ...state,
        appTranslation: payload,
      };
    },
  },
});
