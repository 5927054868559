import { createSlice } from '@reduxjs/toolkit';
import { newCollaborator } from './newCollaborator.type';

const initialState: newCollaborator = {
  name: '',
  lastname: '',
  dateOfBirth: null,
  country: '',
  city: '',
  province: '',
  address: '',
  phone: '',
  email: '',
  position: '',
  area: '',
  seniority: '',
  entityIsLoading: false,
  showSnackbar: false,
};

export default createSlice({
  name: 'newCollaborator',
  initialState,
  reducers: {
    setName: (state, { payload }) => ({
      ...state,
      name: payload,
    }),
    setLastname: (state, { payload }) => ({
      ...state,
      lastname: payload,
    }),
    setDateOfBirth: (state, { payload }) => ({
      ...state,
      dateOfBirth: payload,
    }),

    setCountry: (state, { payload }) => ({
      ...state,
      country: payload,
    }),
    setProvince: (state, { payload }) => ({
      ...state,
      province: payload,
    }),
    setCity: (state, { payload }) => ({
      ...state,
      city: payload,
    }),
    setAddress: (state, { payload }) => ({
      ...state,
      address: payload,
    }),
    setPhone: (state, { payload }) => ({
      ...state,
      phone: payload,
    }),
    setEmail: (state, { payload }) => ({
      ...state,
      email: payload,
    }),
    setPosition: (state, { payload }) => ({
      ...state,
      position: payload,
    }),
    setArea: (state, { payload }) => ({
      ...state,
      area: payload,
    }),
    setSeniority: (state, { payload }) => ({
      ...state,
      seniority: payload,
    }),
    setEnglishLevel: (state: any, { payload }: any) => ({
      ...state,
      englishLevel: payload,
    }),
    clearInputs: (state) => ({
      ...state,
      name: '',
      lastname: '',
      dateOfBirth: null,
      country: '',
      province: '',
      city: '',
      address: '',
      phone: '',
      email: '',
      position: '',
      area: '',
      seniority: '',
      englishLevel: undefined,
    }),
    setLoading: (state, { payload }) => ({
      ...state,
      entityIsLoading: payload,
    }),
    setShowSnackBar: (state, { payload }) => ({
      ...state,
      showSnackbar: payload,
    }),
    setMessage: (state, { payload }) => ({
      ...state,
      message: payload,
    }),
  },
});
