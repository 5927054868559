import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './root-reducer';
import { RootState } from '.';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools || compose;

function configureStore(initialState?: {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<RootState>))
  );
}

const store = configureStore();

export default store;
