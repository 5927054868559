import React, { FC } from 'react';
import '../KnowledgeTopNavBar.scss';

interface OptionsTopNavBarProps {
  options: { description: string; patternPath: string; redirectPath: string }[];
  optionSelected: string;
  handleOption: (option: string, redirectPath: string) => void;
}

const OptionsTopNavBar: FC<OptionsTopNavBarProps> = (props: OptionsTopNavBarProps) => {
  const { options, optionSelected, handleOption } = props;
  return (
    <div className="optionsComponent__container">
      {options.length > 0 &&
        options.map((op, idx) => (
          <div
            key={idx}
            onClick={() => handleOption(op.patternPath, op.redirectPath)}
            className={`optionsComponent__item ${
              optionSelected === op.patternPath && 'optionComponent__itemIsActive'
            }`}
          >
            {op.description}
          </div>
        ))}
    </div>
  );
};

export default OptionsTopNavBar;
