import { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/root-reducer';
import '../../index.css';
import './KnowledgePageContainer.scss';

interface Props {
  children: ReactNode;
}
interface LinkStateProps {
  showSideMenu: boolean;
}

type KnowledgePageContainerProps = LinkStateProps & Props;

const KnowledgePageContainer: FC<KnowledgePageContainerProps> = ({
  children,
  showSideMenu,
}) => {
  return (
    <div
      className={
        showSideMenu
          ? 'knowledgePageContainer__container-openedMenu'
          : 'knowledgePageContainer__container-closedMenu'
      }
    >
      {children}
    </div>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { showSideMenu } = state.routes;

  return {
    showSideMenu,
  };
};

export default connect(mapStateToProps)(KnowledgePageContainer);
