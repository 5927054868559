import { combineReducers } from 'redux';
import newCollaboratorSlice from './NewCollaborator/newCollaboratorSlice';
import collaboratorsSlice from './Collaborators/collaboratorsSlice';
import routesSlice from './routes/routesSlice';
import skillsSlice from './Skills/skillsSlice';
import prevExperienceSlice from './PreviousExperience/prevExperienceSlice';
import certificationsSlice from './Certifications/certificationsSlice';
import projectsSlice from './Projects/projects.Slice';
import clientsSlice from './Clients/clients.Slice';
import dinoExperienceSlice from './DinoExperience/dinoExperienceSlice';
import englishLevelsSlice from './EnglishLevels/englishLevelsSlice';
import generatePDFSlice from './GeneratePDF/generatePDFSlice';
import translationsSlice from './Translations/translationsSlice';
import authSlice from './Auth/authSlice';

const appReducer = combineReducers({
  routes: routesSlice.reducer,
  collaborators: collaboratorsSlice.reducer,
  newCollaborator: newCollaboratorSlice.reducer,
  skills: skillsSlice.reducer,
  prevExperiences: prevExperienceSlice.reducer,
  certifications: certificationsSlice.reducer,
  projects: projectsSlice.reducer,
  clients: clientsSlice.reducer,
  generatePDF: generatePDFSlice.reducer,
  translations: translationsSlice.reducer,
  dinoExperiences: dinoExperienceSlice.reducer,
  englishLevels: englishLevelsSlice.reducer,
  auth: authSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
