import { getDefaultHeaders } from './utils.service';

class TranslationsService {
  async translateEntity(entityToTranslate: Object): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/translations`, {
      method: 'PUT',
      body: JSON.stringify(entityToTranslate),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async getTranslationByLang(lang: string): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/translations/provider?lang=${lang}`,
      {
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }
}

const translationsService = new TranslationsService();
export default translationsService;
