import { getDefaultHeaders, getDefaultHeadersWithoutCredentials } from './utils.service';

class AuthService {
  async settingSession(credential: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/auth`, {
      method: 'POST',
      body: JSON.stringify({ token: credential }),
      headers: getDefaultHeadersWithoutCredentials(),
    });
    return response.json();
  }

  //This AUTH endpoint IS protected so it requires a bearer token
  async getAllUsers(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/auth`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  //This AUTH endpoint IS protected so it requires a bearer token
  async createNewUser(body: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/auth/createOne`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  //This AUTH endpoint IS protected so it requires a bearer token
  async deleteOneUser(body: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/auth/deleteOne`, {
      method: 'DELETE',
      body: JSON.stringify({ googleId: body }),
      headers: getDefaultHeaders(),
    });

    return response.json();
  }
}

const authService = new AuthService();
export default authService;
