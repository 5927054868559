import { createSlice } from '@reduxjs/toolkit';
import { RoutesState } from './routes.type';

const initialState: RoutesState = {
  currentPath: 'collaborators',
  defaultItems: [],
  pathsToShow: [],
  entitiesIsLoading: false,
  showSideMenu: false,
};

export default createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setShowSideMenu: (state, { payload }) => {
      return {
        ...state,
        showSideMenu: payload,
      };
    },
    setCurrentPath: (state, { payload }) => {
      return {
        ...state,
        currentPath: payload,
      };
    },
    setDefaultItems: (state, { payload }) => {
      return {
        ...state,
        defaultItems: payload,
      };
    },
    setActionButton: (state, { payload }) => {
      return {
        ...state,
        actionButton: payload,
      };
    },
    setPathsToShow: (state, { payload }) => {
      return {
        ...state,
        pathsToShow: payload,
      };
    },
    setEntitiesIsLoading: (state, { payload }) => {
      return {
        ...state,
        entitiesIsLoading: payload,
      };
    },
  },
});
