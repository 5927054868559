/* eslint-disable no-lone-blocks */
import { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Collaborator1 } from '../../../../../../store/Collaborators/collaborators.type';
import Avatar from '@mui/material/Avatar';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../../store';
import { getCollaboratorsById } from '../../../../../../store/Collaborators/collaborators.actions';
import { CardActionArea } from '@mui/material';
import { setPathsToShow } from '../../../../../../store/routes/routes.actions';
//Styles
import './KnowledgeCard.scss';

interface LinkDispatchProps {
  getCollaboratorsById: (id: number, collaborator?: Collaborator1) => void;
  setPathsToShow: (location: any, defaultItems?: any[]) => void;
}
interface LinkStateProps {
  entityIsLoading: boolean;
  seniorities: { id: number; seniorityName: string }[];
  roles: { id: number; roleName: string }[];
}
interface CardPropss {
  collaborator: Collaborator1;
}

type CardProps = LinkDispatchProps & LinkStateProps & CardPropss;

const KnowledgeCard: FC<CardProps> = ({
  collaborator,
  entityIsLoading,
  getCollaboratorsById,
  seniorities,
  roles,
}) => {
  const history: any = useNavigate();

  const orderedPositions = [...collaborator.collaboratorPositions!].sort(function (a, b) {
    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
  });
  const seniority =
    orderedPositions.length > 0 &&
    !!orderedPositions[0].seniorityId &&
    seniorities.filter((s) => s.id === orderedPositions[0].seniorityId)[0];

  const rol =
    orderedPositions.length > 0 &&
    orderedPositions[0].roleId &&
    roles.filter((s) => s.id === orderedPositions[0].roleId)[0];

  return (
    <div className="mainContainer">
      {/* ENGLISH LEVEL */}
      {/* {!!collaborator.englishLevel && (
        <Box className="englishLevelBox">
          <p className="englishLevelText">{collaborator.englishLevel.name}</p>
        </Box>
      )} */}
      <Box display="flex" justifyContent="center">
        <Card sx={styles.card}>
          <CardActionArea
            onClick={async () => {
              await getCollaboratorsById(collaborator.id!, collaborator);
              history(`${collaborator.id!}`);
              setPathsToShow({
                pathname: `${collaborator.firstName} ${collaborator.lastName}`,
              });
            }}
          >
            <Grid container>
              {/* IMAGE */}
              <Grid item xs={2}>
                <Box display="flex" sx={{ pt: '2px' }}>
                  <Avatar src={''} sx={styles.avatar} />
                </Box>
              </Grid>

              {/* COLLABORATORS DATA*/}
              <Grid item xs={10} sx={styles.nameContainer}>
                <Typography noWrap sx={styles.h1}>
                  {collaborator.firstName} {collaborator.lastName}
                </Typography>
              </Grid>
              <Box sx={{ display: 'flex', mt: '12px', flexDirection: 'column' }}>
                <Typography sx={styles.h2}>
                  {seniority && seniority.seniorityName + ' -'} {rol && rol.roleName}
                </Typography>
                <Typography sx={styles.h3}>
                  {orderedPositions.length > 0 &&
                    `${orderedPositions[0].startDate.slice(0, 10)} | ${
                      !!orderedPositions[0].endDate
                        ? orderedPositions[0].endDate!.slice(0, 10)
                        : 'to present'
                    }`}
                </Typography>
              </Box>
            </Grid>
          </CardActionArea>
        </Card>
      </Box>
    </div>
  );
};

const styles = {
  card: {
    width: '92%',
    minHeight: '180px',
    maxWidth: '400px',
    borderRadius: '8px',
    p: '21px 16px',
    boxSizing: 'border-box',
  },
  avatar: {
    width: '64px',
    height: '64px',
    border: '2px solid #006E14 ',
  },
  nameContainer: {
    pl: '22px',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  h1: {
    fontSize: '18px',
    fontWeight: 600,
    color: 'var(--DinoNeutral01)',
  },
  h2: {
    color: 'var(--DinoNeutral01)',
  },
  h3: {
    color: 'var(--DinoNeutral03)',
    mt: '12px',
  },
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { entityIsLoading, seniorities, roles } = state.collaborators;
  return {
    entityIsLoading,
    seniorities,
    roles,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  getCollaboratorsById: bindActionCreators(getCollaboratorsById, dispatch),
  setPathsToShow: bindActionCreators(setPathsToShow, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeCard);
