import { FC, ReactNode } from 'react';
import Button from '@mui/material/Button';
import '../../index.css';

interface ButtonProps {
  children?: ReactNode;
  disabled?: boolean;
  type: any;
  variant: any;
  onClick: (value?: any) => any;
}

const KnowledgeButton: FC<ButtonProps> = ({
  children,
  type,
  variant,
  onClick,
  disabled,
}) => {
  const border = variant === 'outlined' ? 'solid' : undefined;
  return (
    <>
      <Button
        onClick={onClick}
        variant={variant}
        color={type}
        disabled={disabled}
        sx={{
          borderWidth: 2.2,
          borderRadius: 2,
          height: '44px',
          fontWeight: '600',
          minWidth: '50px',
          lineHeight: '2px',
          pl: '16px',
          pr: '16px',
          border: border,
          boxShadow: '0px 0px 2px rgba(38, 43, 47, 0.4)',
          ':hover': {
            borderWidth: 2.2,
            border: border,
          },
        }}
      >
        {children}
      </Button>
    </>
  );
};

export default KnowledgeButton;
