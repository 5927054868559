import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_PROJECTS_PAGE } from '../../helpers/constants';
import { ProjectState } from './projects.type';

const initialState: ProjectState = {
  projects: [],
  showForm: false,
  snackBar: false,
  snackBarError: false,
  entitiesAreFetching: false,
  deleteModal: false,
  collaborators: [],
  pageCount: INITIAL_PROJECTS_PAGE,
  showPagination: true,
  projectName: '',
  description: '',
  message: undefined,
  showEditingForm: false,
  filtersActive: false,
};

export default createSlice({
  name: 'projects',
  initialState,
  reducers: {
    getProjects: (state, { payload }) => {
      const { data, page, count, pageCount, filters } = payload;
      return {
        ...state,
        projects: data,
        page,
        filters,
        pageCount,
        count,
      };
    },
    requestProjects: (state, { payload }) => {
      return {
        ...state,
        entitiesAreFetching: payload,
      };
    },
    setProjectName: (state, { payload }) => {
      return {
        ...state,
        projectName: payload,
      };
    },
    setShowPagination: (state, { payload }) => {
      return {
        ...state,
        showPagination: payload,
      };
    },
    setDescription: (state, { payload }) => {
      return {
        ...state,
        description: payload,
      };
    },
    setProjectLead: (state, { payload }) => {
      return {
        ...state,
        projectLead: payload,
      };
    },
    setClient: (state, { payload }) => {
      return {
        ...state,
        client: payload,
      };
    },
    setCollaborators: (state, { payload }) => {
      return {
        ...state,
        collaborators: payload,
      };
    },
    setMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setSnackBar: (state, { payload }) => {
      return {
        ...state,
        snackBar: payload,
      };
    },
    setSnackBarError: (state, { payload }) => {
      return {
        ...state,
        snackBarError: payload,
      };
    },
    setShowForm: (state, { payload }) => {
      return {
        ...state,
        showForm: payload,
      };
    },
    setDeleteModal: (state, { payload }) => {
      return {
        ...state,
        deleteModal: payload,
      };
    },
    setProject: (state, { payload }) => {
      return {
        ...state,
        project: payload,
      };
    },
    clearInputs: (state) => ({
      ...state,
      projectName: '',
      description: '',
      client: undefined,
      collaborators: [],
      industry: undefined,
      projectLead: undefined,
    }),
    setShowEditingForm: (state, { payload }) => ({
      ...state,
      showEditingForm: payload,
    }),
    setProjectToEdit: (state, { payload }) => {
      return {
        ...state,
        projectToEdit: payload,
      };
    },
    setFiltersActive: (state: any, { payload }: any) => {
      return {
        ...state,
        filtersActive: payload,
      };
    },
  },
});
