import { Box, Button, TextField } from '@mui/material';
import { FC, useEffect } from 'react';
import { Client } from '../../../store/Clients/clients.type';
import KnowledgeButton from '../../../commons/KnowledgeButton/KnowledgeButton';

interface ProjectsFormProps {
  addClient: () => void;
  editClient: () => void;
  industry: string;
  setIndustry: (e: string) => void;
  clear: () => void;
  setName: (name: string) => void;
  name: string;
  setShowForm: (showForm: boolean) => void;
  positionLeft?: boolean;
  buttonText: string;
  clientToEdit?: Client;
  isEdit?: boolean;
  cancelAddClient: () => void;
  appTranslation?: any;
}

const ProjectsForm: FC<ProjectsFormProps> = ({
  addClient,
  clear,
  name,
  setName,
  industry,
  setIndustry,
  setShowForm,
  positionLeft,
  buttonText,
  clientToEdit,
  editClient,
  isEdit,
  cancelAddClient,
  appTranslation,
}) => {
  useEffect(() => {
    if (!!clientToEdit) {
      setName(clientToEdit.name);
      setIndustry(clientToEdit.industry ? clientToEdit.industry : '');
    }
  }, [clientToEdit]);

  return (
    <>
      <Box
        sx={
          positionLeft
            ? {
                display: 'flex',
                justifyContent: 'flex-start',
                mt: '30px',
              }
            : {
                display: 'flex',
                justifyContent: 'center',
              }
        }
      >
        <Box
          sx={{
            width: '912px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
              }}
              sx={{ width: '441px', mb: '24px' }}
              value={name}
              label={appTranslation ? appTranslation.form.NAME : 'Nombre'}
              variant="outlined"
            />
            <TextField
              onChange={(e) => {
                setIndustry(e.target.value);
              }}
              sx={{ width: '441px', mb: '24px' }}
              value={industry}
              label={appTranslation ? appTranslation.form.INDUSTRY : 'Industria'}
              variant="outlined"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '230px',
            }}
          >
            <KnowledgeButton
              type="primary"
              variant="outlined"
              onClick={() => {
                cancelAddClient();
              }}
            >
              {appTranslation ? appTranslation.actionButtons.cancel : 'CANCELAR'}
            </KnowledgeButton>

            {name && industry ? (
              <Button
                sx={{
                  borderRadius: 2,
                  height: '44px',
                  fontWeight: '600',
                  minWidth: '85px',
                  lineHeight: '2px',
                }}
                variant="contained"
                onClick={
                  isEdit
                    ? () => {
                        editClient();
                        clear();
                      }
                    : () => {
                        addClient();
                      }
                }
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled
                sx={{
                  borderRadius: 2,
                  height: '44px',
                  fontWeight: '600',
                  minWidth: '85px',
                  lineHeight: '2px',
                }}
              >
                {buttonText}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProjectsForm;
