import React from 'react';
//MUI
import Box from '@mui/system/Box';
import { Divider, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
//Styles
import '../styles.scss';
//Components
import SimpleModal from '../../../commons/SimpleModal/SimpleModal';

interface User {
  id: number;
  googleId: string;
  createdAt: string;
  createdBy: string;
}

const UserItem = (props: any) => {
  const { deleteOneUser } = props;
  let user: User = props.user;

  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);

  const textBoldStyle = {
    fontWeight: 600,
    color: 'var(--DinoNeutral01)',
  };

  return (
    <>
      <SimpleModal
        show={deleteModal}
        handleClose={setDeleteModal}
        callBack={deleteOneUser}
        data={user.googleId}
        deleteType
        title="Eliminar"
        body="¿Está seguro de que desea eliminar el usuario?"
        confirmText="ELIMINAR"
      />
      <Box className="AuthUserList_UserItem">
        <Box className="authUserList_UserItemTextContainer">
          <Typography sx={textBoldStyle}>
            Google ID: <Typography display={'inline'}>{user.googleId}</Typography>
          </Typography>
          <Typography sx={textBoldStyle}>
            Creado por: <Typography display={'inline'}> {user.createdBy}</Typography>
          </Typography>
        </Box>

        <IconButton
          sx={{ marginLeft: 'auto' }}
          onClick={async () => {
            setDeleteModal(!deleteModal);
            //await deleteOneUser(user.googleId);
          }}
        >
          <DeleteIcon sx={{ color: 'var(--DinoNeutral01)' }} />
        </IconButton>
      </Box>
      <Divider variant="fullWidth" />
    </>
  );
};

const AuthUserList: React.FC<any> = (data: any) => {
  const { users, deleteOneUser } = data;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0px auto',
      }}
    >
      {users.map((user: User) => {
        return <UserItem key={user.id} user={user} deleteOneUser={deleteOneUser} />;
      })}
    </Box>
  );
};

export default AuthUserList;
