import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
// import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import HowToRegIcon from '@mui/icons-material/HowToReg';

export default function defaultItems(appTranslation?: any) {
  const defItems: any[] = [
    {
      patternPath: 'collaborators',
      description: 'Colaboradores',
      isActive: true,
      children: [
        {
          path: 'collaborators/professional-services',
          description: appTranslation
            ? appTranslation.COLLABORATORS.professional_services
            : 'Servicios Profesionales',
          isActive: true,
          icon: <PersonRoundedIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
        // {
        //   path: 'collaborators/staffing-service',
        //   description: 'Servicio de Staffing',
        //   isActive: false,
        //   icon: <GroupsRoundedIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        // },
      ],
      actionButton: {
        description: appTranslation ? appTranslation.button : 'colaborador',
        icon: '',
        actionMethod: 'collaborators/professional-services/create',
      },
    },
    {
      patternPath: 'skills',
      description: 'Habilidades',
      isActive: true,
      children: [
        {
          path: 'skills/tech-skills',
          description: appTranslation
            ? appTranslation.SKILLS.tech_skills
            : 'Habilidades Tech',
          isActive: true,
          icon: <CodeRoundedIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
        {
          path: 'skills/soft-skills',
          description: appTranslation
            ? appTranslation.SKILLS.soft_skills
            : 'Habilidades Soft',
          isActive: false,
          icon: <VolunteerActivismRoundedIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
        {
          path: 'skills/AWS-skills',
          description: appTranslation
            ? appTranslation.SKILLS.aws_skills
            : 'Habilidades AWS',
          isActive: false,
          icon: <CloudRoundedIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
        {
          path: 'skills/tools',
          description: appTranslation ? appTranslation.SKILLS.tools : 'Herramientas',
          isActive: false,
          icon: <ConstructionRoundedIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
      ],
    },
    {
      patternPath: 'projects',
      description: 'Proyectos',
      isActive: true,
      children: [
        {
          path: 'projects/projects',
          description: appTranslation ? appTranslation.PROJECTS.projects : 'Proyectos',
          isActive: true,
          icon: <AutoAwesomeIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
      ],
    },
    {
      patternPath: 'clients',
      description: 'Clientes',
      isActive: true,
      children: [
        {
          path: 'clients/clients',
          description: appTranslation ? appTranslation.CLIENTS.clients : 'Clientes',
          isActive: true,
          icon: <AutoAwesomeIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
      ],
    },
    {
      patternPath: 'settings',
      description: 'Configuraciones',
      isActive: true,
      children: [
        {
          path: 'settings/settings',
          description:
            appTranslation && appTranslation.SETTINGS
              ? appTranslation.SETTINGS.sing_up_users
              : 'Alta de usuarios',
          isActive: true,
          icon: <HowToRegIcon style={{ fill: 'var(--DinoNeutral07)' }} />,
        },
      ],
    },
  ];
  return defItems;
}
