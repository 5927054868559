import { Dispatch } from 'redux';
import englishLevelsService from '../../services/englishLevels.service';
import { handleErrors } from '../Auth/auth.actions';
import englishLevelsSlice from './englishLevelsSlice';

export const getEnglishLevels = () => {
  return async (dispatch: Dispatch) => {
    dispatch(englishLevelsSlice.actions.requestEnglishLevel(true));
    const getResponse = await englishLevelsService.getEnglishLevels().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(englishLevelsSlice.actions.getEnglishLevel(getResponse));
    dispatch(englishLevelsSlice.actions.requestEnglishLevel(false));
  };
};
