import React from 'react';
//MUI
import { Box, Modal, Typography } from '@mui/material';
//Styles
import './styles.scss';
import KnowledgeButton from '../KnowledgeButton/KnowledgeButton';

//Feel free to add whatever new props you need here
interface SimpleModalProps {
  show: boolean;
  handleClose: (v: any) => void;
  title?: string;
  body?: string;
  callBack?: (data: any) => void;
  data?: any;
  deleteType?: boolean;
  cancelText?: string;
  confirmText?: string;
  children?: React.FC | React.ReactChild | React.ReactFragment | React.ReactPortal;
  width?: number | string;
  height?: number | string;
}

const SimpleModal: React.FC<SimpleModalProps> = (props: SimpleModalProps) => {
  const {
    show,
    handleClose,
    callBack,
    data,
    deleteType,
    title,
    body,
    cancelText,
    confirmText,
    children,
    width,
    height,
  } = props;

  const handleCallBack = async (cbTarget: any) => {
    if (!!callBack) {
      await callBack(cbTarget);
    }
  };

  const handleConfirm = () => {
    handleCallBack(data);
    handleClose(false);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: !!width ? width : '354px',
    height: !!height ? height : '150px',
    borderRadius: '20px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  return (
    <Box>
      <Modal open={show} onClose={() => handleClose(false)}>
        <Box sx={{ ...style }}>
          <Typography
            className="titleText"
            sx={{
              color: !!deleteType ? '#B42318' : 'var(--DinoPrimary01)',
              fontSize: '18px',
              fontWeight: '600',
            }}
          >
            {!!title && title}
          </Typography>
          <Typography
            className="bodyText"
            sx={{ fontWeight: '400', display: body ? 'auto' : 'none' }}
          >
            {!!body && body}
          </Typography>
          {!!children ? (
            children
          ) : (
            <Box
              sx={{
                width: '300px',
                display: 'flex',
                justifyContent: 'space-around',
                mx: '28px',
              }}
            >
              <KnowledgeButton
                type={!!deleteType ? 'error' : 'primary'}
                variant="outlined"
                onClick={() => handleClose(false)}
              >
                {!!cancelText ? cancelText : 'CANCELAR'}
              </KnowledgeButton>
              <KnowledgeButton
                type={!!deleteType ? 'error' : 'primary'}
                variant="contained"
                onClick={() => handleConfirm()}
              >
                {!!confirmText ? confirmText : 'CONFIRMAR'}
              </KnowledgeButton>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default SimpleModal;
