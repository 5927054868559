import { createSlice } from '@reduxjs/toolkit';
import { CertificationsState } from './certifications.type';

const initialState: CertificationsState = {
  certifications: [],
  name: '',
  company: '',
  obtainedDate: null,
  expirationDate: null,
  showForm: false,
  showAddBtn: true,
  message: '',
  showSnackbar: false,
  snackBarError: false,
  entityIsLoading: false,
  entitiesAreFetching: false,
  deleteModal: false,
  checkedDate: false,
  description: '',
};

export default createSlice({
  name: 'certification',
  initialState,
  reducers: {
    deletePrevExperiences: (state, { payload }) => {
      return {
        ...state,
        certification: payload,
      };
    },
    setName: (state, { payload }) => {
      return {
        ...state,
        name: payload,
      };
    },
    setCompany: (state, { payload }) => {
      return {
        ...state,
        company: payload,
      };
    },
    setDescription: (state, { payload }) => {
      return {
        ...state,
        description: payload,
      };
    },
    setObtainedDate: (state, { payload }) => {
      return {
        ...state,
        obtainedDate: payload,
      };
    },
    setExpirationDate: (state, { payload }) => {
      return {
        ...state,
        expirationDate: payload,
      };
    },
    setUrl: (state, { payload }) => {
      return {
        ...state,
        url: payload,
      };
    },
    setMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setShowSnackBar: (state, { payload }) => ({
      ...state,
      showSnackbar: payload,
    }),
    setSnackBarError: (state, { payload }) => {
      return {
        ...state,
        snackBarError: payload,
      };
    },
    setShowForm: (state, { payload }) => {
      return {
        ...state,
        showForm: payload,
      };
    },
    setShowAddBtn: (state, { payload }) => {
      return {
        ...state,
        showAddBtn: payload,
      };
    },
    setDeleteModal: (state, { payload }) => {
      return {
        ...state,
        deleteModal: payload,
      };
    },
    setCertification: (state, { payload }) => {
      return {
        ...state,
        certification: payload,
      };
    },
    setCertificationToEdit: (state, { payload }) => {
      return {
        ...state,
        certificationToEdit: payload,
      };
    },
    setCheckedDate: (state, { payload }) => {
      return {
        ...state,
        checkedDate: payload,
      };
    },
    clearInputs: (state) => ({
      ...state,
      name: '',
      company: '',
      obtainedDate: null,
      expirationDate: null,
      url: '',
      checkedDate: false,
      description: '',
    }),
    setLoading: (state, { payload }) => ({
      ...state,
      entityIsLoading: payload,
    }),
  },
});
