import React, { useEffect } from 'react';
//Redux
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//RRD
import { useLocation } from 'react-router-dom';
//Store
import {
  createNewUser,
  deleteOneUser,
  getAuthorizedUsers,
} from '../../store/Auth/auth.actions';
import { RootState } from '../../store';
//Components
import KnowledgePageContainer from '../../commons/KnowledgePageContainer/KnowledgePageContainer';
import AuthUserList from './components/AuthUsersList';
import NewUser from './components/NewUser';
//MUI
import { Grid, Tooltip, Typography } from '@mui/material';
import Box from '@mui/system/Box';
//Icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
//Styles
import './styles.scss';
import KnowledgeButton from '../../commons/KnowledgeButton/KnowledgeButton';

interface LinkDispatchProps {
  getAuthorizedUsers: () => void;
  createNewUser: (data: any) => void;
  deleteOneUser: (googleId: string) => void;
}

interface LinkStateProps {
  authorizedUsers: any[] | undefined;
  user: any;
}

const SettingsPage: React.FC<any> = (props: any) => {
  const location: any = useLocation();
  const { deleteOneUser } = props;
  const [signNew, setSignNew] = React.useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await props.getAuthorizedUsers();
    })();
  }, []);

  useEffect(() => {}, [location]);

  const RenderBody = () => {
    if (signNew) {
      return (
        <NewUser
          show={setSignNew}
          email={props.user.email}
          createUser={props.createNewUser}
        />
      );
    } else {
      if (props.authorizedUsers.length) {
        return (
          <AuthUserList users={props.authorizedUsers} deleteOneUser={deleteOneUser} />
        );
      } else {
        return (
          <Typography className="noUsersText">
            Aún no se han agregado usuarios.
          </Typography>
        );
      }
    }
  };

  return (
    <KnowledgePageContainer>
      <Box sx={{ height: '52px' }}></Box>
      <div className="users__container ">
        <Grid container sx={{ mt: '20px', mb: '40px' }}>
          <Grid
            item
            xs={11.5}
            sx={{ display: 'flex', justifyContent: 'center', pl: '45px' }}
          >
            <Typography sx={{ fontSize: '30px' }}>Alta de usuarios</Typography>
          </Grid>
          <Tooltip title="Agregar" placement="top-end">
            <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!signNew && (
                <KnowledgeButton
                  type="primary"
                  variant={signNew ? 'disabled' : 'contained'}
                  onClick={() => setSignNew(!signNew)}
                >
                  <AddRoundedIcon sx={{ width: '20px' }} />
                </KnowledgeButton>
              )}
            </Grid>
          </Tooltip>
        </Grid>
        <RenderBody />
      </div>
    </KnowledgePageContainer>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { authorizedUsers, user } = state.auth;
  return { authorizedUsers, user };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  getAuthorizedUsers: bindActionCreators(getAuthorizedUsers, dispatch),
  createNewUser: bindActionCreators(createNewUser, dispatch),
  deleteOneUser: bindActionCreators(deleteOneUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
