import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

interface Props {
  area: string;
  team: string;
  seniority?: string | number;
  role: string;
  billable: boolean;
  condition: string;
  monthlyHours: number;
}

const WorkItem: FC<Props> = ({
  area,
  team,
  seniority,
  role,
  billable,
  condition,
  monthlyHours,
}) => {
  return (
    <>
      <Typography sx={styles.title}>{`${role} ${
        !!seniority ? seniority : ''
      }`}</Typography>
      <Grid container sx={styles.container}>
        <Grid item sm={6} lg={4}>
          <h2>Area:</h2>
          <h3>{area}</h3>
        </Grid>
        <Grid item sm={6} lg={4}>
          <h2>Billable:</h2>
          <h3>{billable ? 'Yes' : 'No'}</h3>
        </Grid>
        <Grid item sm={6} lg={4}>
          <h2>MonthlyHours:</h2>
          <h3>{monthlyHours}</h3>
        </Grid>
        <Grid item sm={6} lg={4}>
          <h2>Team:</h2>
          <h3>{team}</h3>
        </Grid>
        <Grid item sm={6} lg={4}>
          <h2>Condition:</h2>
          <h3>{condition}</h3>
        </Grid>
      </Grid>
    </>
  );
};

const styles = {
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: 'var(--DinoNeutral01)',
  },
  container: {
    background: 'var(--DinoNeutral07)',
    border: '1px solid var(--DinoNeutral05)',
    borderRadius: '4px',
    padding: '20px',
    mb: '20px',
    mt: '24px',
  },
};

export default WorkItem;
