import { FC, useEffect } from 'react';
import '../../index.css';
import './LoginPage.scss';
import LoginImg from '../../assets/LoginImg.png';
// import googleLogo from '../../assets/googleLogo.png';
import KnowledgeBackdrop from '../../commons/KnowledgeBackdrop/KnowledgeBackdrop';
import { useNavigate } from 'react-router-dom';
// import { GoogleLogin } from 'react-google-login';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { setAppTranslation } from '../../store/Translations/translations.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import engLanguage from '../../assets/engLanguage.png';
import spaLanguage from '../../assets/spaLanguage.png';
import { saveSession, setShowSnackBar } from '../../store/Auth/auth.actions';
import KnowledgeAlert from '../../commons/KnowledgeAlert/KnowledgeAlert';
import { GoogleLogin } from '@react-oauth/google';

interface LinkStateProps {
  appTranslation?: any;
  user?: any;
  message?: any;
  showSnackBar: boolean;
  loading: boolean;
}

interface LinkDispatchProps {
  setAppTranslation: (lang: string) => void;
  saveSession: (data: any) => void;
  setShowSnackBar: (show: boolean) => void;
}

type LoginPageProps = LinkDispatchProps & LinkStateProps;

const LoginPage: FC<LoginPageProps> = ({
  appTranslation,
  setAppTranslation,
  saveSession,
  user,
  message,
  showSnackBar,
  setShowSnackBar,
  loading,
}) => {
  const history: any = useNavigate();

  useEffect(() => {
    setAppTranslation('ES');
  }, []);

  useEffect(() => {
    if (user) {
      history('/collaborators/professional-services');
    }
  }, [user]);

  const handleLogin = (googleData: any) => {
    if (googleData) {
      saveSession(googleData);
    }
  };

  const FALSE_PROP: boolean = false;

  return (
    <>
      <KnowledgeBackdrop loading={loading} />
      {message && (
        <KnowledgeAlert
          header={message.header}
          severity={message.type}
          openSnackBar={showSnackBar}
          closeSnackBar={() => setShowSnackBar(FALSE_PROP)}
          message={message.text}
        />
      )}
      <meta
        name="google-signin-client_id"
        content="977082590618-7vaeold2htsc1mfcvf2qtp08770o4nbv.apps.googleusercontent.com"
      ></meta>
      <section className="login__page--main-container">
        <div className="login__page--container">
          <div className="login__text">
            <p className="medium-letter">
              {appTranslation ? appTranslation.pages.login.title.partOne : 'Bienvenido a'}
            </p>
            <h1 className="title">Knowledge Catalog</h1>
            <p className="medium-letter">
              {appTranslation ? appTranslation.pages.login.title.partTwo : 'de DinoCloud'}
            </p>
          </div>
          {/* <GoogleLogin
            clientId="463585176519-uigeueu6cc4kh9oh3re233os97stkfd9.apps.googleusercontent.com"
            buttonText="Continue with google"
            onSuccess={handleLogin}
            onFailure={handleLogin}
            cookiePolicy={'single_host_origin'}
            render={(renderProps) => ( */}
          <Box sx={{ mt: '30px' }}>
            <GoogleLogin
              onSuccess={handleLogin}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </Box>
          {/* <Button
            onClick={() => {
              history('/collaborators/professional-services');
              //renderProps.onClick();
            }}
            variant="outlined"
            startIcon=""
            sx={{
              backgroundColor: 'transparent',
              borderColor: 'var(--DinoPrimary04)',
              borderWidth: '2px',
              borderRadius: '8px',
              color: 'var(--DinoPrimary04)',
              height: '44px',
              width: '228px',
              textTransform: 'initial',
              fontFamily: 'Source Sans Pro',
              fontWeight: '600',
              fontSize: '16px',
              padding: '0 20px',
              boxShadow: '0px 0px 2px rgba(38, 43, 47, 0.4)',
              ':hover': {
                backgroundColor: 'transparent',
                borderColor: 'var(--DinoPrimary05)',
                color: 'var(--DinoPrimary05)',
                borderWidth: '2px',
              },
              magin: '0px',
              marginTop: '64px',
            }}
          >
            <img
              className="imgGoogleLogo__styleProps"
              src={googleLogo}
              alt="google-logo"
            />
            {appTranslation
              ? appTranslation.pages.login.actionButtons.google
              : 'Continuar con Google'}
          </Button> */}
          {/* )}
          /> */}
          <div className="login__button--wrapper">
            <Typography sx={{ mt: '0px', mb: '16px', textAlign: 'center' }}>
              {appTranslation
                ? appTranslation.pages.login.language.label
                : ' Selecciona un idioma'}
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="ES"
              name="radio-buttons-group"
              onChange={(event: any) => {
                setAppTranslation(event.target.value);
              }}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="ES"
                control={<Radio />}
                label={
                  <img
                    src={spaLanguage}
                    style={{ marginRight: '7px', marginTop: '7px' }}
                    alt="spanish-icon"
                  />
                }
                sx={{ width: '50px' }}
              />
              <Typography sx={{ mt: '10px' }}>
                {appTranslation ? appTranslation.pages.login.language.spanish : 'Español'}
              </Typography>
              <FormControlLabel
                value="EN"
                control={<Radio />}
                label={
                  <img
                    src={engLanguage}
                    style={{ marginRight: '7px', marginTop: '7px' }}
                    alt="spanish-icon"
                  />
                }
                sx={{ width: '50px', ml: '20px' }}
              />
              <Typography sx={{ mt: '10px' }}>
                {appTranslation ? appTranslation.pages.login.language.english : 'Inglés'}
              </Typography>
            </RadioGroup>
          </div>
        </div>
        <div className="loginPageImg__container">
          <img src={LoginImg} alt="Knowledge catalog login" />
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { appTranslation } = state.translations;

  const { user, message, showSnackBar, loading } = state.auth;

  return { appTranslation, user, message, showSnackBar, loading };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  setAppTranslation: bindActionCreators(setAppTranslation, dispatch),
  saveSession: bindActionCreators(saveSession, dispatch),
  setShowSnackBar: bindActionCreators(setShowSnackBar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
