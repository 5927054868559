import { getDefaultHeaders } from './utils.service';

class CollaboratorService {
  //TODO REVIEW PARAM FILTER
  private createParamFilter(filters: any[] = []) {
    let paramFilter: string = '';
    if (filters.length) {
      paramFilter = '';
      const skilsIdsToFilter: any[] = [];
      filters.forEach((filterData) => {
        let value = filterData.value;
        if (filterData.value !== -1) {
          let filterName = filterData.columnName;
          let condition = '$eq';
          if (filterName === 'id') {
            value = isNaN(parseInt(filterData.value, 10)) ? -1 : filterData.value;
          } else if (filterName === 'firstName' || filterName === 'seniority') {
            condition = '$contL';
          } else if (filterName === 'skills.id') {
            skilsIdsToFilter.push(value);
          } else if (filterName === 'englishLevel.name') {
            condition = '$in';
          } else {
            condition = '$eq';
          }
          if (filterName !== 'skills.id') {
            paramFilter = `${paramFilter}&filter=${filterName}||${condition}||${value.toString()}`;
          }
        }
      });
      if (skilsIdsToFilter.length) {
        paramFilter = `${paramFilter}&filter=skills.id||$in||${skilsIdsToFilter.toString()}`;
      }
    }
    return paramFilter;
  }
  async createCollaborator(collaborator: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/collaborators`, {
      method: 'POST',
      body: JSON.stringify(collaborator),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async editCollaborator(collaborator: any): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/collaborators/${collaborator.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(collaborator),
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  //NEW METHODS

  async getCollaborators(
    page: number = 1,
    count: number = 9,
    filters?: any[]
  ): Promise<any> {
    const urlParams = encodeURI(
      `?page=${page}&limit=${count}${this.createParamFilter(filters)}`
    );
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/collaborator${urlParams}`,
      {
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  async getCollaboratorsById(id: any, lang: string | null = 'es'): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/collaborators/${id}?lang=${lang}`,
      {
        method: 'GET',
        headers: getDefaultHeaders(),
      }
    );

    return response.json();
  }

  async getAllCollaborators(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/collaborator`, {
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async getCollaboratorById(id: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/collaborator/${id}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });

    return response.json();
  }

  async getSeniorities(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/seniority`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });

    return response.json();
  }

  async getAreas(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/area`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });

    return response.json();
  }

  async getTeams(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/team`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });

    return response.json();
  }

  async getConditions(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/condition`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });

    return response.json();
  }

  async getRoles(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/role`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });

    return response.json();
  }

  async editSkillLevel(info: {
    level: number;
    skillId: number;
    collaboratorId: number;
    commenterId: number;
    comment: string;
  }): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/skills/change-collaborator-skill-level`,
      {
        method: 'PUT',
        body: JSON.stringify(info),
        headers: getDefaultHeaders(),
      }
    );

    return response.json();
  }
}

const collaboratorService = new CollaboratorService();
export default collaboratorService;
