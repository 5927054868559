import { FC } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../../../store';

import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { Typography } from '@mui/material';

import ProfileTab from './ProfileTab/ProfileTab';
import TechSkillsTab from './TechSkillsTab/TechSkillsTab';
import '../../styles.scss';
import { Collaborator1 } from '../../../../../store/Collaborators/collaborators.type';
import { Skill1 } from '../../../../../store/Skills/skills.type';
import { type } from '../../../../../utils/skillsType';

interface LinkStateProps {
  appTranslation?: any;
  collaborator1?: Collaborator1;
  skills1: Skill1[];
}

interface LinkDispatchProps {}

type OptionsComponentProps = LinkDispatchProps & LinkStateProps;

const OptionsProfileCollaboratorProps: FC<OptionsComponentProps> = ({
  appTranslation,
  collaborator1,
  skills1,
}) => {
  const options: string[] = [
    'COLLABORATOR INFO',
    'TECH SKILLS',
    'SOFT SKILLS',
    'LEARNING TRACK',
  ];

  const options2: string[] = ['COLLABORATOR INFO', 'SOFT SKILLS', 'LEARNING TRACK'];

  const techSkills = [...collaborator1!.collaboratorSkillHistory].filter(
    (s) => type(s, skills1) !== 'SOFT'
  );


  return (
    <TabsUnstyled defaultValue={0}>
      {techSkills.length > 0 ? (
        <>
          <TabsList>
            {options.map((option: any, idx) => (
              <Tab key={idx} sx={{ mt: '40px' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                  {option}
                </Typography>
              </Tab>
            ))}
          </TabsList>
          <TabPanel value={0}>
            <ProfileTab />
          </TabPanel>
          <TabPanel value={1}>
            <TechSkillsTab />
          </TabPanel>
        </>
      ) : (
        <>
          <TabsList>
            {options2.map((option: any, idx) => (
              <Tab key={idx} sx={{ mt: '40px' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                  {option}
                </Typography>
              </Tab>
            ))}
          </TabsList>
          <TabPanel value={0}>
            <ProfileTab />
          </TabPanel>
        </>
      )}
    </TabsUnstyled>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { appTranslation } = state.translations;
  const { collaborator1 } = state.collaborators;
  const { skills1 } = state.skills;

  return {
    appTranslation,
    collaborator1,
    skills1,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionsProfileCollaboratorProps);

const Tab = styled(TabUnstyled)`
  display: flex;
  justify-content: center;
  color: var(--DinoNeutral01);
  cursor: pointer;
  background-color: transparent;
  padding: 12px 16px;
  margin: 30px 6px;
  border: none;
  border-radius: 6px;

  &:hover {
    background-color: var(--DinoPrimary07);
  }

  &:focus {
    border-radius: 6px;
  }

  &.${tabUnstyledClasses.selected} {
    border-bottom: 2px solid var(--DinoPrimary04);
    border-radius: 0;
    color: var(--DinoPrimary04);
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: '50px';
`;

const TabsList = styled(TabsListUnstyled)`
  width: 100%;
  min-width: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
