import { getDefaultHeaders } from './utils.service';

class EnglishLevelsService {
  async getEnglishLevels(): Promise<any> {
    let queryParams: any = `${process.env.REACT_APP_API_HOST}/englishLevels`;
    const response = await fetch(`${queryParams}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }
}

const englishLevelsService = new EnglishLevelsService();
export default englishLevelsService;
