import { FC } from 'react';
import KnowledgeCard from './KnowledgeCard/KnowledgeCard';
import './KnowledgeGrid.scss';
import { Collaborator1 } from '../../../../../store/Collaborators/collaborators.type';
import { Grid } from '@mui/material';
import NotFoundFilters from '../../../../../commons/NotFoundFilters/NotFoundFilters';
interface GridProps {
  collaborators: Collaborator1[];
  emptyList?: any;
  filtersActive: boolean;
}

const KnowledgeGrid: FC<GridProps> = ({ collaborators, emptyList, filtersActive }) => {
  return (
    <Grid
      style={{ width: '100%', marginTop: 25 }}
      container
      spacing={3}
      className="knowledgeCatalogGrid__container"
    >
      <div className="grid_separated_rows">
        {collaborators &&
          collaborators.length > 0 &&
          collaborators.map((collaborator, idx) => (
            <Grid item key={idx} xs={12} sm={12} md={6} lg={4} xl={4} sx={{ mb: '28px' }}>
              <KnowledgeCard key={idx} collaborator={collaborator} />
            </Grid>
          ))}
      </div>
      {!!emptyList && !collaborators.length && !filtersActive && (
        <div className="emptyListContainer">
          <p className="p_none_collabs">{emptyList}</p>
        </div>
      )}
      {!!emptyList && !collaborators.length && filtersActive && (
        <div className={filtersActive ? 'img_notFound' : ''}>
          <NotFoundFilters />
        </div>
      )}
    </Grid>
  );
};

export default KnowledgeGrid;
